import React  from 'react';   
export default function Ordenpago(props){
    const styles = `
        #div-ordenpago{
            display: flex;
            flex-direction: column;
            width: auto;
            padding: 2vw;
            background-color: white;
            border: 2px solid black;
            border-radius: 3px;
        }
        .underlined{
            border: none;
            border-bottom: 1px solid black;
        }
        #header-op{
            display: flex;
            margin-bottom: 1.5vmax;
        }
        #header-logo{
            width: 5vmax;
        }
        #header-labels{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #tbl-ordenpago{
            width: match-parent;
        }
        #firstcolumn{
            width: 15%;
        }
        #secondcolumn{
            width: 35%;
        }
        #thirdcolumn{
            width: 14%;
        }
        #fourthcolumn{
            width: 35%;
        }
        #footer-op{
            display: flex;
            margin-top: 1.5vmax;
            justify-content: space-between;
        }
        #footer-solicito{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-reviso{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-reviso1{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-autorizo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-op label{
            font-weight: bold;
        }
        #tbl-ordenpago input{
            width: 100%;
            border: none;
        }
        #tbl-ordenpago label{
            font-weight: bold;
        }
        .labeltoright{
            float: right;
        }
        #header-empresa{
            font-weight: bold;
            font-size: 20px;
        }#nueva-op-header-empresa{
            font-weight: bold;
            font-size: 20px;
        }#header-rzonsocial{
            font-weight: bold;
            font-size: 25px;
        }
    `;

    return(
        <div id="div-ordenpago">
            <style>
                {styles}
            </style>
            <button class="btn btn-outline-success btn-sm" style={{display:'block',width:'150px', height:'30px'}} onClick={() => document.getElementById("cargaXML").click()}>Cargar factura (XML)</button>
            <input style={{display:'none'}} type='file' id="cargaXML" onChange={() => props.XML("1")} accept=".xml"></input>
                
            <div id="header-op">
                
                <img src={props.logoOP} alt="LOGO" style={{width: '160px', height: 'auto', paddingLeft:'25px'}}></img>
                <div id="header-labels">
                    <span id="nueva-op-header-empresa">{props.rzonsocialEmpresa}</span>
                    {/* <span id="header-empresa">{props.departamento}</span>*/}
                    <span>ORDEN DE PAGO</span>
                </div>
            </div>

            <table id="tbl-ordenpago">
            <tbody>
                

                <tr>
                    <td style={{width:'15%'}}>
                        <label>BANCO:</label>
                    </td>
                    <td  style={{width:'35%'}} className="underlined">
                    <select id="nop-bancoretiro" class="slc-tarjeta-op">
											{ props.bancos.map(item => ( 
											<option value={item.folio}> {item.nombrebanco}</option>    
											))}  
										</select>   
                    </td>
                
  
                    <td>
                        <label className="labeltoright">CANTIDAD &nbsp; $</label>
                    </td>
                    <td className="underlined">
                        <span><input type='number' id="nop-cantidad" ></input></span>
                    </td> 
                </tr>
                <tr>
                    <td>
                        <label>PROVEEDOR:</label>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="nop-anombre" onKeyUp={() => props.filtrarPorProveedor("nop-anombre-sel", "nop-anombre")} style={{width:'97.5%', height:'auto', cursor:'pointer'}}></input>
                        <select  id="nop-anombre-sel" class="slc-b" onChange={() => props.proveedorSelected("nop-anombre-sel", "nop-anombre")}> 
                                      {props.proveedor.map(item => ( 
                                        ((item.proveedor != item.nombre) && (item.nombre != ""))
                                        ?
                                        <option  value={item.nombre}>{item.proveedor}</option>
                                        :
                                      <option  value={item.proveedor}>{item.proveedor}</option>
                                      ))
                                      }   
                                  </select>
                    </td>
                </tr>
                

                <tr>
                    <td>
                        <label>CONCEPTO:</label>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="nop-concepto"></input>
                    </td>
                </tr>
                </tbody>
            </table>

            <div id="footer-op">
                <div id="footer-solicito">
                <select id="nfirmas-solicito"  class="slc-op-firmas">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                    <label>SOLICITÓ</label>
                </div>
                <div id="footer-reviso">
                <select id="nfirmas-reviso" class="slc-op-firmas">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   <label>REVISÓ</label>
                </div>
                <div id="footer-reviso1">
                <select id="nfirmas-reviso1" class="slc-op-firmas">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                     <label>REVISÓ</label>
                </div>
                <div id="footer-autorizo">
                <select id="nfirmas-autorizo" class="slc-op-firmas">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   <label>AUTORIZÓ</label>
                </div>
            </div>
        </div>
    )
}