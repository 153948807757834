import add from './resources/add.svg';
import remove from './resources/remove.svg';
import React,{useState, useEffect} from 'react'; 
import  {FaCheckCircle, FaTimesCircle} from 'react-icons/fa'
import axios from '../node_modules/axios'; 
import {Nabvar} from './component/Navbar'; 
import { ModalCarga, Toast} from './component/modalCarga'; 
import './App.css'; 
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";   

function Nvasolicitudpago(props) {

	  
	const [proveedor, setProveedor] =  useState(props.proveedores); 
	const [defProveedor, setDefProveedor] =  useState("COMERCIALIZADORA PETROMAR SA DE CV"); 
	const [defConcepto, setDefConcepto] =  useState(""); 


	
	//const [listaProveedores1, setListaProveedores1] = useState([]); 
	useEffect(()=> {
		document.getElementById('totalPrice0').value = 0;
		//fet current fecha
		var event = new Date();
		var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			
		setDefConcepto("COMPRA PIPAS " + (event.toLocaleDateString('es-ES', options)).toUpperCase().replace(",",""));
		//set def concepto
	  
	}, []) 

	const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);
	 
	function notify(message){
        toast(message);
    }

	
	function filtrarPorProveedor(id){
		var selProveedores = document.getElementById("pronva"+id);
		var currentInput = document.getElementById("proveedornva"+id);
		var result = props.proveedores1.filter(item => item.proveedor.toLowerCase().includes(currentInput.value.toLowerCase()))
		//console.log(result);
		 setProveedor(result);
		 
		if(currentInput.value.toLowerCase() == "" || result.length < 1){
		 selProveedores.style.display = "none";
		}else{
		 selProveedores.style.display = "block";
		 selProveedores.selectedIndex = -1;
		 selProveedores.size = 5;
		 var my_index = 100;
		 selProveedores.style.zIndex = my_index++;
		}
	 }
	 function proveedorSelected(id){
		document.getElementById("proveedornva"+id).value = document.getElementById("pronva"+id).value; 
		document.getElementById("pronva"+id).style.display = "none"; 
	}
 

    function openModalLoad() { 
    setIsOpenLoad(true); 
     }  
   
     function closeModalLoad() { 
    setIsOpenLoad(false); 
     }


	 
	 

    async function addSolicitud(e) {
        e.preventDefault(); 
        var userid = props.userid;
        var descripcion = document.getElementById("identificadornvasol").value;
        var proveedor = document.getElementById("proveedornvasol").value; 
		descripcion = descripcion.replaceAll('"', '').replaceAll('"', '');
		
		var solicita = document.getElementById("nvaslc-solicita-solicitud").value;
		var recibe = document.getElementById("nvaslc-recibe-solicitud").value;

		if(descripcion == ""){
			alert("Capture el identificador de la solicitud de pago para continuar");
		}else{
			openModalLoad(); 
			let fd = new FormData()
			fd.append("id", "InsertSolicitud") 
			fd.append("userid", userid)
			fd.append("descripcion", descripcion)  
			fd.append("empresaid", props.dptoid)
			fd.append("proveedor", proveedor)
			fd.append("solicita", solicita)
			fd.append("recibe", recibe)
	
			for(let i=0;i<document.getElementsByName("descripcionnvasol").length;i++){
				fd.append("descripcionproducto[]", document.getElementsByName("descripcionnvasol")[i].value.replaceAll('"', '´´').replaceAll("'", "´"))  
				fd.append("cantidad[]", document.getElementsByName("cantidadnvasol")[i].value)  
				let totalPriceInput = document.getElementById(`checknva3_${i}`);
				if (totalPriceInput) {
					fd.append("precio[]", totalPriceInput.value);
				} else if (i === 0) {
					// If it's the first row, append the value of input with ID "totalPrice0"
					let totalPriceInput0 = document.getElementById("totalPrice0");
					if (totalPriceInput0) {
						fd.append("precio[]", totalPriceInput0.value);
					}
				}
			}
	
			 
	
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			closeModalLoad();
			if(res.data == "1"){
				notify("✔ Datos guardados correctamente");

				var table = document.getElementById('tableproductosnva');
				var rowCount = table.rows.length;
				while(rowCount > 2) {
					table.deleteRow(rowCount - 1);
					rowCount--;
				}
					
				for(let i=0;i<document.getElementsByName("descripcionnvasol").length;i++){
					document.getElementsByName("descripcionnvasol")[i].value = "";
					document.getElementsByName("cantidadnvasol")[i].value = 1;
					document.getElementsByName("preciouninvasol")[i].value = 0;
					document.getElementById("totalPrice0").value = 0;
					document.getElementsByName("totalnvasol")[i].value = 0;
				}
	
			 
				document.getElementById("identificadornvasol").value = ""; 
				document.getElementById("proveedornvasol").value = ""; 

				//refrescar listado de actividades

				props.nuevaRequisicionGuardada();
			}
			 
		}
	
	}

	function addPersona(folio){

	}
	//const [value, setValue] = useState([]); 

	function Change(e) {
		
		let rowId;
		const inputId = e.target.id;
		
		if (inputId.startsWith("inputnva")) {
			rowId = 0;
		} else if (inputId.startsWith("checknva")) {
			rowId = parseInt(inputId.split("_")[1]);
		}
	
		const quantityInput = document.getElementsByName("cantidadnvasol")[rowId];
		const quantity = parseFloat(quantityInput.value);
		
		const priceInput = document.getElementsByName("preciouninvasol")[rowId];
		const price = parseFloat(priceInput.value);
	
		if (!isNaN(quantity) && !isNaN(price)) {
			const totalPrice = (quantity * price);
	
			let totalPriceElement;
			if (rowId === 0) {
				totalPriceElement = document.getElementById("totalPrice0");
			} else {
				totalPriceElement = document.getElementById(`checknva3_${rowId}`);
			}
	
			if (totalPriceElement) {
				totalPriceElement.value = totalPrice;
			}
		}
	}
	
	
	
 
	
	
	function Agregar() {
		var table = document.getElementById('tableproductosnva');
		var newrow = table.lastChild.cloneNode(true);
		const rowCount = table.rows.length - 1;
		newrow.firstChild.innerHTML = rowCount + 1;
	
		for (var i = 0; i < newrow.getElementsByTagName("input").length; i++) {
			var a = newrow.getElementsByTagName("input")[i];
			a.setAttribute("id", "checknva" + (rowCount + i));
			if (a.name === "cantidadnvasol") {
				a.value = 1;
			} else if (a.name === "preciouninvasol") {
				a.value = 0;
			} else if (a.name === "totalnvasol") {
				a.value = 0;  
			} else {
				a.value = ""; 
			}
		}
	
		table.appendChild(newrow);
	
		const newRowQuantityInput = document.getElementsByName("cantidadnvasol")[rowCount];
		newRowQuantityInput.addEventListener("change", (e) => Change(e));
		newRowQuantityInput.addEventListener("keyup", (e) => Change(e)); 

		const newRowPriceInput = document.getElementsByName("preciouninvasol")[rowCount];
    	newRowPriceInput.addEventListener("change", (e) => Change(e));
		newRowPriceInput.addEventListener("keyup", (e) => Change(e)); 
	
		const inputs = newrow.querySelectorAll('input');
		inputs.forEach((input, index) => {
			input.id = "checknva" + index + "_" + rowCount;
		});
	}
	
	

	function CancelarAutoriza(){

	}

	function quitar(){ 
		var table = document.getElementById('tableproductosnva');
		//let tbl = document.getElementById("tableproductos");
            let rows = table.getElementsByTagName('tr');
            rows = Array.from(rows);
            rows.shift();
			var cont = 0;
            for(let element of rows){
                //element.remove();
				cont ++;
            } 
			if(cont > 1){
				table.lastChild.remove();
			}
		   
	}

 
  	// Dynamically create select list
//	let options = [];



	const today = new Date().toISOString().slice(0, 10);

	return (
		<div className="container "  id="nvarequisicion" hidden >
			<Nabvar  defaultColor={props.defaultColor} idd="navnuevarequisicion" titulo="Nueva Solicitud Orden de Pago" dptoid={props.dptoid} departamento={props.rzonsocial} />    
			 
			<div className="row p-3">
				<div class="margin-auto"> 
					<br></br> 
					<div style={{backgroundColor:'white', border:'none', boxShadow: 'rgb(0 0 0 / 45%) 0px 5px 15px', borderRadius:'5px', width:'70%', margin:'auto', padding:'12px'}}> {/* width:'600px' */}
					<br></br> 
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div class="div-section2">
                                <img id="logoOrdennva" src={"data:image/png;base64,"+props.datose.logoblob}  alt="LOGO" style={{width: '160px', height: 'auto', paddingLeft:'25px'}}></img>
                                <span><b class="font-25">NUEVA SOLICITUD DE PAGO&nbsp;&nbsp;&nbsp;&nbsp;</b></span>
                            </div>
            
                            <div class="div-section2">
                                <span><b class="font-30" id="rzonsocialnva">{props.datose.name}</b></span>
                                <span><b id="direccionnva">{props.datose.direccion}</b></span> 
                                <span><b>Tel. </b><b id="telempresanva">{props.datose.telefono}</b></span>
                            </div>

                            <div class="div-section2">
                                <table class="border-line" >
                                    <tr align="center" hidden="hidden">
                                        <th class="cell-1">Folio</th> 
                                    </tr>
                                    <tr>
                                        <td  hidden="hidden">
                                            <input id="folio" readOnly="readonly" class="input-1"></input>
                                            <input id="idrequisicion" readOnly="readonly" class="input-1"hidden></input>
                                        </td> 
                                    </tr>
                                </table> 
                                <table class="border-line">
                                    <tr align="center">
                                        <th class="cell-1">Fecha</th> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <input id="fechacaptura" readOnly='readonly' class="input-1" value={(today).split("-").reverse().join("/")}></input> 
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
						<table class="width-100"> 
							 
							<tr> 
							</tr>
							
							<tr>
								 
								<td>
									<label >Identificador:</label>
									<br/>
									<input  defaultValue={defConcepto} class="inpt-nva-req" id="identificadornvasol"/>
								</td>  
								<td>
									<label >Proveedor:</label>
									<br/>
									<input  defaultValue={defProveedor}  class="inpt-nva-req" id="proveedornvasol"/>
								</td>  
								<td>
									<input  value={props.dptoid} hidden="hidden"/>
								</td>	
								
 	
							</tr>
							<tr> 
							</tr>  
						</table>	
						<br/>
		
						<table id="tableproductosnva">
							<tr>
								<th></th>   
								<td class="cell-productos"><label>Cantidad</label></td>
								<td class="cell-productos"><label>Producto</label></td>  
								<td class="cell-productos"><label>Precio Unitario</label></td> 
								<td class="cell-productos"><label>Precio Total</label></td> 
							</tr>

							<tr class="width-100">
								<td class="width-2">1</td> 
								<td class="width-10"> <input name="cantidadnvasol" type="number" id="inputnva0" min="1" step="any" class="inpt-nva-req" defaultValue={1} onChange={(e) => Change(e)} /></td>
								<td style={{width:'63%'}}> <input name="descripcionnvasol" class="width-100" style={{height:'auto'}}/></td>
								<td class="width-10"> <input name="preciouninvasol" type="number" id="inputnva0" min="1" step="any" class="inpt-nva-req" defaultValue={0} onChange={(e) => Change(e)}/></td>
								<td class="width-20"><input style={{width:'100%'}} name="totalnvasol" id="totalPrice0" readonly /></td>
							</tr>
						</table>	 
						<div class="div-section-row jcend mtop5"  >
						<img onClick={() => Agregar()} alt="Remover" src={add} className='btn btn-outline btn-sm padding2' style={{ width: '30px', height: '30px',backgroundColor:'green', marginLeft: '1.6%'}}></img>
						<img onClick={() => quitar()} alt="Remover" src={remove} className='btn btn-outline btn-sm padding2' style={{ width: '30px', height: '30px',backgroundColor:'#ff0000', marginLeft: '1.6%'}}></img>
						</div>
						<br/>
						
						 
						<br/>

						<div id="footer-op">
							<div id="footer-solicito">
							<select id="nvaslc-solicita-solicitud" class="select-orden-pago">
							<option value={props.name}>{props.name}</option> 
														{ props.usuarios.map(item => ( 
														<option value={item.name}> {item.name}</option>    
														))}  
													</select> 
								<label>SOLICITA</label>
							</div>
						
							<div id="footer-autorizo">
							<select id="nvaslc-recibe-solicitud" class="select-orden-pago">
							<option value={"L.C.P. DALIA FRANCISCA PACHECO JUÁREZ"}>{"L.C.P. DALIA FRANCISCA PACHECO JUÁREZ"}</option> 
														{ props.usuarios.map(item => ( 
														<option value={item.name}> {item.name}</option>    
														))}  
													</select> 
								<label>RECIBE</label>
							</div>
           			 </div >
						<table class="width-100" hidden>

			    				<tr>
										<th></th>
										<td>Solicita:</td> 
										<td>Autoriza:</td>
								</tr>

								<tr>
										<td></td>
										<td> 
										<select id="slc-solicita" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 	
										
										</td>	
										<td> 
										<select id="slc-revisa" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 
										
										
										</td>	
										 
								</tr>
								</table>
						
								<br/>
						
						 
						<br/>
						<table class="width-100" style={{ marginRight:'0'}}>
							 
							<tr>
								<td>
									<button  className="btn btn-outline-success btn-sm" onClick={(e) => addSolicitud(e)} >Guardar <FaCheckCircle /></button> 
								</td>
							</tr>
						</table>	
					</div>
				</div>
			</div>
			 
			<ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    
			<Toast/>    
		</div>
	);   
}

export default Nvasolicitudpago;