import logo from './logo.png';
import React,{useState, useEffect, useRef} from 'react'; 
import { FaPrint, FaSyncAlt, FaExternalLinkSquareAlt, FaFileExcel, FaBell} from 'react-icons/fa'
import { useDownloadExcel } from 'react-export-table-to-excel';
import ProgressBar from 'react-bootstrap/ProgressBar';
import axios from '../node_modules/axios'; 
import {Nabvar} from './component/Navbar';  
import {ModalCarga, Toast} from './component/modalCarga'; 
import { FaTrash } from 'react-icons/fa';
import Modal  from 'react-modal';
import html2canvas from 'html2canvas'; 
import formatNumber from './commonFunctions';
import NvaOrdenpago1 from './component/NvaOrdenpago1'; 
import NvaOrdenpago from './component/NvaOrdenpago';
import {jsPDF} from "jspdf";
import './App.css'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";   
import customStyles from './component/modalCustomStyles';
import tableCustomStyles from './tableCustomStyles';
import formatDate1 from './formatDate1';
import { Discuss } from 'react-loader-spinner';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

function Ordenes(props){ 
    const [vehiculoDef, setVehiculoDef] =  useState([]);  
    const [odometroDef, setOdometroDef] =  useState([]);  
    const [tipoFlotilla, setTipoFlotilla] =  useState([]);  
    //const [proveedor, setProveedor] =  useState([]);  
    const [proveedor, setProveedor] =  useState(props.proveedores); 
    const [lista, setLista] =  useState([]);    
    const [impreso, setImpreso] = useState([]); 
    const [proveedores, setProveedores] = useState([]); 
    const [listaProveedores, setListaProveedores] = useState([]); 
    const [valueIva, setValueIva] = useState(0.16); 
  //  const [listaProveedores1, setListaProveedores1] = useState([]); 



    const [requisicionSeleccionada, setRequisicionSeleccionada] = useState([]); 
    
   
    
  
let subtitle;
const [modalIsOpenInventario, setIsOpenInventario] = React.useState(false);
const [modalIsOpenAbono, setIsOpenAbono] = React.useState(false);
const [modalIsOpenMotivo, setIsOpenMotivo] = React.useState(false);
const [modalIsOpenMotivoRI, setIsOpenMotivoRi] = React.useState(false);
const [modalIsOpenPagada, setIsOpenPagada] = React.useState(false);
const [modalIsOpen, setIsOpen] = React.useState(false);
const [modalIsOpenRes, setIsOpenRes] = React.useState(false);
const [modalIsOpenGenerar, setIsOpenGenerar] = React.useState(false);
const [modalIsOpenGenerar1, setIsOpenGenerar1] = React.useState(false);
const [ordenn, setorden] = React.useState(false);
const [folioempresa, setfolioEmpresa] = React.useState(false);
const [ordenn2, setorden2] = React.useState(false);
const [ordennP, setordenP] = React.useState(false);
const [modalIsOpen1, setIsOpen1] = React.useState(false);
const [modalIsOpen3, setProveedorINFO] = React.useState(false);
const [infoproveedores, setInfoProveedores] = React.useState([]);

const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);

const [dptos2, setDeptos2] = useState(props.dptos2);  

const [inventarioProducto, setInventarioProducto] = React.useState([]);
const [inventarioProveedor, setInventarioProveedor] = React.useState([]);
const [inventarioFecha, setInventarioFecha] = React.useState([]);
const [inventarioDescripcion, setInventarioDescripcion] = React.useState([]);
const [inventarioFolioProducto, setInventarioFolioProducto] = React.useState([]);
const [inventarioPrecio, setInventarioPrecio] = React.useState([]);
const [folioProductoR, setFolioProductoR] = React.useState([]);

//estados
const [generada, setGenerada] = React.useState([]);
const [autorizada, setAutorizada] = React.useState([]);
const [conorden, setConOrden] = React.useState([]);
const [conanticipo, setConAnticipo] = React.useState([]);
const [pagada, setPagada] = React.useState([]);
const [surtida, setSurtida] = React.useState([]); 
const [importeTotal, setImporteTotal] = React.useState([]); 
//cancelada
const [estadocancelada, setEstadoCancelada] = React.useState([]); 
const [importeAcumulado, setImporteAcumulado] = React.useState([]);
const [proveedorSugerido, setProveedorSugerido] = React.useState([]);
const [conceptoSugerido, setConceptoSugerido] = React.useState([]);
const [listav, setListaV] = React.useState([]);
//logo para la orden de pago
const [logoOP, setLogoOP] = React.useState([]); 
//no impresiones
const [impresiones, setImpresiones] = React.useState([]); 

//mostrar observaciones
const [mostrarObservaciones, setMostrarObservaciones] = React.useState(false); 
const [historialImpresiones, setHistorialImpresiones] = React.useState([]); 
const [historialRestablecer, setHistorialRestablecer] = React.useState([]); 

    function openModalLoad() { 
    setIsOpenLoad(true); 
     }  
   
     function closeModalLoad() { 
    setIsOpenLoad(false); 
     }

     function notify(message){
        toast(message);
    }

function openModal() {
setIsOpen(true);
}
function openModalRes() {
    setIsOpenRes(true);
    }

function openModalMotivo() {
    setIsOpenMotivo(true);
    }

    function openModalMotivoRi() {
        setIsOpenMotivoRi(true);
        }
 
function openModalAbono(orden) {
    setIsOpenAbono(true);
    setorden2(orden);
    }

    function openModalPagada(orden) {
        setIsOpenPagada(true);
        setordenP(orden);
        }
    
function openModal1(a) {
    setIsOpen1(true);
}
function openModalGenerar(orden, folioempresa, importe, proveedor, concepto) {
        setIsOpenGenerar(true);
        setfolioEmpresa(folioempresa);
        setImporteTotal(importe);
        setProveedorSugerido(proveedor);
        setConceptoSugerido(concepto);
        setorden(orden);
}

function formatEstado(estado){
    var estatus = estado;
    estatus = estatus.substring(1, estatus.length);
    return estatus;
}
function openModal3() {
    setProveedorINFO(true);
    }
function afterOpenModal() {
// references are now sync'd and can be accessed.
subtitle.style.color = '#f00';
}
function afterOpenModal1() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
    }
function afterOpenModalGenerar() {
        // references are now sync'd and can be accessed.
       // subtitle.style.color = '#f00';
}
function afterOpenModal3() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
}
function afterOpenModalAbono() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
    }

function closeModal() {
setIsOpen(false);
}
function closeModalRes() {
    setIsOpenRes(false);
    }
function closeModal1() {
    setIsOpen1(false);
    }
function closeModalGenerar() {
        setIsOpenGenerar(false);
    }
    function closeModalGenerar1() {
        setIsOpenGenerar1(false);
    }
function closeModal3() {
    setProveedorINFO(false);
}
function closeModalAbono() {
    setIsOpenAbono(false);
    }
    function closeModalMotivo() {
        setIsOpenMotivo(false);
        }
        function closeModalMotivoRI() {
            setIsOpenMotivoRi(false);
            }
    function closeModalPagada() {
        setIsOpenPagada(false);
        }
        function closeModalInventario() {
            setIsOpenInventario(false);
            }
function openModala(idusuario){

    if( document.getElementById("orden-estacion").value != "" ){ 
        if(document.getElementById("proveedor1oc").value != ""){
            //si es vista previar soliciar contraseña (?) o brincar directo a funcion pdf
            if(document.getElementById("chbxVistaPrevia").checked){
                Pdf("");
            }else{
                openModal();   
            }
            
        }else{ 
            notify("Seleccione un proveedor");
        }
    } else {
        notify("No es posible imprimir la orden de compra - seleccione un departamento");
    }
  
  
}

async function verinfoProv(){
    let prov = document.getElementById("proveedor1oc").value;
    if(prov != null||prov!=""){
        let id = "provedor";

    var rese = await  axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&prov='+prov);
    //console.log(rese.data);
    setProveedorINFO(rese.data); 
    setInfoProveedores(rese.data); 

    openModal3(); 



    }

}
 
 

async function abonar(orden){
    let importe = document.getElementById("abono").value;
    closeModalAbono();
    let fd1 = new FormData() 
    fd1.append("id","Abonar") 
    fd1.append("importe",importe) 
    fd1.append("orden",orden) 
          const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
  
    if(res1.data==1){
        
        notify("Anticipo Realizado");
        ActualizarStatus(orden);
    }else{notify("Error al realizar el anticipo")}

}

async function guardarFechaDePago(orden){ 
    if(!document.getElementById("fechadepago").value == ""){
    closeModalPagada();
    let fd1 = new FormData() 
    fd1.append("id","guardarFechaDePago") 
    fd1.append("fecha",document.getElementById("fechadepago").value) 
    fd1.append("orden",orden) 
          const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
          notify(res1.data);
    
    }else{
        notify("Seleccione una fecha");
    }
}


async function openModala1(id){
    setRequisicionSeleccionada(id);
   // let select = document.getElementById("slc-cotizacion");
    let a = "GetCotizaciones";
    let sin = [];
    sin['proveedor']= "Saabwews";
    var rese = await  axios.get(process.env.REACT_APP_API_URL+'?id='+a+'&requisicion='+id);
    if(rese.data!=null){
        //console.log(rese.data); 
        setProveedores(rese.data);
    }else{
        setProveedores(sin);
    }
    openModal1(); 
    
  }


    useEffect(()=> {
        if(props.cargarDefaultR != "" && props.cargarDefaultO != ""){
			verOrden(props.cargarDefaultR, props.cargarDefaultO);
		}
       
        getOrdenes();
       // getProveedores();
        getDatosEmpresa(); 
      //  getAllProveedores();
       // getDepartamentos2(); 
        getVehiculos();
    }, [])
/*
    async function getAllProveedores(){
         
		var id = "getAllProveedores";
		//openModalLoad();
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&dptoid='+props.dptoid);  
		//closeModalLoad();
		//console.log(rese.data);
		setListaProveedores(rese.data);  
		setListaProveedores1(rese.data);  
	}

*/
    function onChange(e){
        
        Change();

    }
    function getArray(cadena, foliof){  
        if(cadena != null){
        var stringArray = cadena.split(" ");   
        return stringArray.map(item => <><label >{item} {(props.superus == "1" || props.userdptoid == "9") ? <> <FaTrash class="color-red"  onClick={() => quitarOrdenPagoUnica(item)} /> <FaExternalLinkSquareAlt style={{color:props.defaultColor}} onClick={() => props.verOrdenPago(item, "")}/> </> : <></>}</label> </>);
        }
    }
    
    function onChange1(e){
        
        Change(); 
    }
 

     function mostrarObservacionesf(){
        if(mostrarObservaciones){
            setMostrarObservaciones(false);
        }else{
            setMostrarObservaciones(true);
        }
     }

    async function actualizarEstadoImpresion(){
        let vistaPreviaChBox = document.getElementById("chbxVistaPrevia");
    if(vistaPreviaChBox.checked){
        
        Pdf("");
    }else{
      
    
        if( document.getElementById("orden-estacion").value != "" ){ 
            if(document.getElementById("proveedor1oc").value != ""){ 
                
                var id = "actualizarimprimir";  
                var folio = document.getElementById("idrequisicion").value;
                var rzonsocial = props.rzonsocial;
                const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&orden='+folio+'&rzonsocial='+rzonsocial+'&userid='+props.userid); 
                if(rese.data != "null"){

                    //setImpresiones(rese.data + " impresión");
                    
                    Pdf(rese.data);
                    
                }else{
                    notify("Error");
                } 
            }else{
                notify("Seleccione un proveedor");
            }
        } else {
            notify("No es posible imprimir la orden de compra - seleccione un departamento");
        }
     }
    } 
    async function CEUrestablecer(a){
    
        
        let contra = document.getElementById("artnoc").value;
        
        
        let fd1 = new FormData() 
        fd1.append("id","C1") 
        fd1.append("CT",contra) 
        fd1.append("userid",props.userid) 
              const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
      
        if(res1.data==1){
            //abrir modal para motivo reimpresion 
            openModalMotivo();
           
           // Pdf();
    
         //  closeModal();
        }else{
          //closeModal();
          return notify("CONTRASEÑA INCORRECTA");
          
        }
    }
  async function CEU(a){
    
    let vistaPreviaChBox = document.getElementById("chbxVistaPrevia");
    if(vistaPreviaChBox.checked){
        setIsOpen(false);
        Pdf("");
    }else{
    
    let contra = document.getElementById("artnoc").value;
    
    
    let fd1 = new FormData() 
    fd1.append("id","C1") 
    fd1.append("CT",contra) 
    fd1.append("userid",props.userid) 
          const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
  
    if(res1.data==1){
        //abrir modal para motivo reimpresion 
        openModalMotivoRi();
       
       // Pdf();

       closeModal();
    }else{
      closeModal();
      return notify("CONTRASEÑA INCORRECTA");
      
    }
}
}   

async function reimpresion(){
 //motivo de la reimpresion 
if(document.getElementById("inputmotivoreimpresion").value == ""){
    notify("Ingrese el motivo de reimpresión");
    return; 
}
 var id = "actualizarimprimir";  
 var folio = document.getElementById("idrequisicion").value;
 let motivori = document.getElementById("inputmotivoreimpresion").value;
 var rzonsocial = props.rzonsocial;
 const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&orden='+folio+'&rzonsocial='+rzonsocial+'&userid='+props.userid+'&motivo='+motivori); 
 if(rese.data != "null"){

     //setImpresiones(rese.data + " impresión");
    closeModalMotivoRI();
     Pdf(rese.data);
     
 }else{
     notify("Error");
 } 
}

function vistaPrevia(){
    let vistaPreviaChBox = document.getElementById("chbxVistaPrevia");
    if(vistaPreviaChBox.checked){
        document.getElementById("pdf").classList.add("vistaPrevia");
        document.getElementById("pdf").classList.remove("no-after");
    }else{
        document.getElementById("pdf").classList.add("no-after");
        document.getElementById("pdf").classList.remove("vistaPrevia");
    }

    
}
 
    function Pdf(impresiones){
        try{
            var cboxdol = document.getElementById("checkboxdolares");
            var labeldol = document.getElementById("divdolares");
            if(cboxdol.checked){
                let importe = document.getElementById("t-total").value;
                let valorDolar = document.getElementById("checkdlls-valor").value;
                //console.log(importe.replace(",", ""));
                //console.log(valorDolar.replace(",", ""));
                importe = importe.replace("$", "")
                let importeDlls = parseFloat(importe.replace(",", ""))*parseFloat(valorDolar.replace(",", "")); 
                labeldol.innerHTML = "Importe en pesos mexicanos: " + formatNumber(importeDlls)  + "   -Dolar: $" + Number(valorDolar.replace(",", ""));
            } else {
                labeldol.innerHTML = "";
            }
            let fechahora = new Date().toLocaleString();
            if(impresiones != ""){   
            document.getElementById("noReimpresiones").innerHTML = impresiones + "ª impresión ("+props.name+") " + fechahora;
        }
            let revocarb1 = document.getElementById("revocarb1");
            let revocarb2 = document.getElementById("revocarb2");
            let revocarb3 = document.getElementById("revocarb3");
            let revocarb4 = document.getElementById("revocarb4");

            revocarb1.setAttribute("hidden", true);
            revocarb2.setAttribute("hidden", true);
            revocarb3.setAttribute("hidden", true);
            revocarb4.setAttribute("hidden", true);
            

            let notificationIcon1 = document.getElementById("notificar-icon-1");
            let notificationIcon2 = document.getElementById("notificar-icon-2");
            let notificationIcon3 = document.getElementById("notificar-icon-3");
            let notificationIcon4 = document.getElementById("notificar-icon-4");
            notificationIcon1.style.display = "none";
            notificationIcon2.style.display = "none";
            notificationIcon3.style.display = "none";
            notificationIcon4.style.display = "none";

            let checkDllsValor = document.getElementById("checkdlls-valor");
            let botonRestablecer = document.getElementById("botonRestablecer");
            botonRestablecer.setAttribute("hidden", true);
            checkDllsValor.style.display = "none";
            cboxdol.style.display = "none";
            var observacionesT = document.getElementById("observaciones");
            var observacionesL = document.getElementById("obs1");
            var facturacionT = document.getElementById("facturacion");
            var facturacionL = document.getElementById("fac1");
            observacionesL.innerHTML = observacionesT.value;
            observacionesT.style.display = "none";
            observacionesL.style.display = "unset";
            facturacionT.style.display = "none";
            facturacionL.style.display = "unset";
            var iva = document.getElementById("ivath");
            document.getElementsByName("checkbox").forEach((input) => {
                input.style.display = 'none';
            });

            iva.innerHTML = "" ; 

            const input = document.getElementById('pdf');
             html2canvas(input).then((canvas) => {


                var imgData = canvas.toDataURL('image/png');
 
                var imgWidth = 190; 
                var pageHeight = 295;  
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;
                var heightLeft1 = imgHeight;
          
                var doc = new jsPDF('p', 'mm');
                var position = 0;
                heightLeft1 -= pageHeight - 5; 
              //      alert(heightLeft1);

                if(heightLeft1 > 0){
                    doc.addImage(imgData, 'PNG', 10, position + 5, imgWidth, imgHeight - heightLeft1);
                    heightLeft -= pageHeight;
          
                    while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                  //  doc.addPage();
                   // doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                    }

                }else{
                    doc.addImage(imgData, 'PNG', 10, position + 5, imgWidth, imgHeight);
                }
                
                window.open(doc.output('bloburl'), '_blank') 
               // doc.save( 'file.pdf');
              /*  let imgWidth = 190;
                let imgHeight = canvas.height * imgWidth / canvas.width;
                const imgData = canvas.toDataURL('img/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
             //    var folio = document.getElementById("folio").value;
             //   var fecha  = document.getElementById("fecha").value; 
             //   var estacion = document.getElementById("orden-estacion"); 
            //    var e = document.getElementById("orden-estacion").value;    
                 //pdf.output('dataurlnewwindow');
               // pdf.autoPrint(folio +" " + e + " " + fecha + ".pdf");
                //window.open(pdf);
                window.open(pdf.output('bloburl'), '_blank')*/
            })
            ; 
            //
            notificationIcon1.style.display = "unset";
            notificationIcon2.style.display = "unset";
            notificationIcon3.style.display = "unset";
            notificationIcon4.style.display = "unset";
            observacionesT.style.display = "unset";
            observacionesL.style.display = "none";
            document.getElementsByName("checkbox").forEach((input) => {
                input.style.display = 'unset';
            });

            iva.innerHTML = "IVA" ;

            checkDllsValor.style.display = "unset";
            cboxdol.style.display = 'unset'
            labeldol.innerHTML = "Precio en dolares  &nbsp";
            var id = document.getElementById("idrequisicion").value;  
            let foliof = document.getElementById("folio").value;
            document.getElementById("noReimpresiones").innerHTML = "";
           // notify(id + " folio: "  + foliof);
           //descomenta
            verOrden(id, foliof);
            //set unchecked
            document.getElementById("chbxVistaPrevia").checked = false;
       

        } catch(Exception){
            alert (Exception)
            //console.log(Exception)
        }
    }

    async function getDatosEmpresa(){
        var id = "getDatosEmpresa";
        var rzonsocial = props.rzonsocial;

      /*  const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&rzonsocial='+rzonsocial);  
        //console.log(process.env.REACT_APP_API_URL+'?id='+id+'&rzonsocial='+rzonsocial);
        */var razonsocial = document.getElementById("rzonsocial");
        var direccion = document.getElementById("direccion");
        var facturacion = document.getElementById("facturacion");
        var facturacionL = document.getElementById("fac1");
        var telempresa = document.getElementById("telempresa");
        var rfcempresa = document.getElementById("rfcempresa");
        var logoOrden = document.getElementById("logoOrden"); 
        try{
            razonsocial.innerHTML = props.datose.name;
            direccion.innerHTML = props.datose.direccion;
            telempresa.innerHTML = props.datose.telefono;
            telempresa.innerHTML = props.datose.telefono;
            rfcempresa.innerHTML = props.datose.rfc;
          
            logoOrden.src = "data:image/png;base64,"+props.datose.logoblob; 
 
           setLogoOP("data:image/png;base64,"+props.datose.logoblob);
           facturacion.innerHTML = "Datos de facturación: " + props.datose.rfc + " " + props.datose.direccion + "\n" + document.getElementById("rzonsocial").innerHTML;
            facturacionL.innerHTML = "Datos de facturación: " + props.datose.rfc + " " + props.datose.direccion + "\n" + document.getElementById("rzonsocial").innerHTML;
        } catch(Exception ){console.log("error al obtener datos de empresa - getDatosEmpresa()")}
    }

    async function removerCotizacion(idrequisicion){
        if(window.confirm('¿Quitar cotización?')){
        //    var folioorden = document.getElementById("folio").value; 
            let fd = new FormData()  
            fd.append("id", "removerCotizacion")
            fd.append("idrequisicion", idrequisicion) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            notify(res.data.trim());
            getOrdenes();
        }
    }
/*
    async function getProveedores(){
        var id = "getProveedores";
        const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
        setProveedor(rese.data);  
        setListaProveedores1(rese.data);
        // console.log(rese.data);
      //  var Data = JSON.stringify(rese.data);
        // console.log(Data[0]); 
    }
*/
    async function generarOrdenPago(folioempresa,ordenCompra,tipo){
      
        if(tipo == 0){
            return;
        }
         //, cantidad, proveedor, concepto tipo
         let Banco = "";
         let Cantidad = "";
         let Receptor = "";
         let Concepto = "";
         let Solicita = "";
         let Revisa = "";
         let Revisa1 = "";
         let Autoriza = "";
         let Tarjeta = "";
         if(tipo == "1"){ 
             Banco = document.getElementById("nop-bancoretiro").value ;
             Cantidad = document.getElementById("nop-cantidad").value;
             Receptor = document.getElementById("nop-anombre").value;
             Concepto = document.getElementById("nop-concepto").value;
             Solicita = document.getElementById("nfirmas-solicito").value;
             Revisa = document.getElementById("nfirmas-reviso").value;
             Revisa1 = document.getElementById("nfirmas-reviso1").value;
             Autoriza = document.getElementById("nfirmas-autorizo").value;
             Tarjeta = ""; 
         }else if(tipo == "2"){
             Tarjeta = document.getElementById("op-tarjeta").value;
             Banco = document.getElementById("nop-bancoretiroN").value;
             Cantidad = document.getElementById("nop-cantidadN").value;
             Receptor = document.getElementById("nop-anombreN").value;
             Concepto = document.getElementById("nop-conceptoN").value;
             Solicita = document.getElementById("nfirmas-solicitoN").value;
             Revisa = document.getElementById("nfirmas-revisoN").value;
             Revisa1 = document.getElementById("nfirmas-revisoN1").value;
             Autoriza = document.getElementById("nfirmas-autorizoN").value;
         }
         //generarOrdenPagoMultiple
         //console.log(Tarjeta);
         let fd = new FormData()  
         fd.append("id", "generarOrdenPagoUnica")
         fd.append("idordencompra", ordenCompra); //
         fd.append("idempresa", props.dptoid); //
         fd.append("folioempresa", folioempresa); //
         fd.append("tipo", tipo); //
         fd.append("cantidad", Cantidad); //
         fd.append("banco", Banco); 
         fd.append("receptor", Receptor);
         fd.append("concepto", Concepto);
         fd.append("solicita", Solicita);
         fd.append("tarjeta", Tarjeta); 
         fd.append("revisa", Revisa);
         fd.append("revisa1", Revisa1);
         fd.append("autoriza", Autoriza);
         fd.append("solicitudpago", "0");
         
         const rese = await axios.post(process.env.REACT_APP_API_URL, fd);
  
        // const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&idempresa='+props.dptoid+'&tipo='+tipo+'&cantidad='+cantidad+'&proveedor='+proveedor+'&concepto='+concepto);  
         closeModalGenerar();
         notify(rese.data.trim()); 
         setIsOpenGenerar(false);
         //document.getElementById("orden-estacion").value=""; //?
         getOrdenes();
        
    }
    async function generarOrdenPago1(tipo){
        //, cantidad, proveedor, concepto tipo
        if(tipo == 0){
            return;
        }
        let Banco = "";
        let Cantidad = "";
        let Receptor = "";
        let Concepto = "";
        let Solicita = "";
        let Revisa = "";
        let Revisa1 = "";
        let Autoriza = "";
        let Tarjeta = "";
        if(tipo == "1"){ 
            Banco = document.getElementById("nop-bancoretiro").value ;
            Cantidad = document.getElementById("nop-cantidad").value;
            Receptor = document.getElementById("nop-anombre").value;
            Concepto = document.getElementById("nop-concepto").value;
            Solicita = document.getElementById("nfirmas-solicito").value;
            Revisa = document.getElementById("nfirmas-reviso").value;
            Revisa1 = document.getElementById("nfirmas-reviso1").value;
            Autoriza = document.getElementById("nfirmas-autorizo").value;
            Tarjeta = ""; 
        }else if(tipo == "2"){
            Tarjeta = document.getElementById("op-tarjeta").value;
            Banco = document.getElementById("nop-bancoretiroN").value;
            Cantidad = document.getElementById("nop-cantidadN").value;
            Receptor = document.getElementById("nop-anombreN").value;
            Concepto = document.getElementById("nop-conceptoN").value;
            Solicita = document.getElementById("nfirmas-solicitoN").value;
            Revisa = document.getElementById("nfirmas-revisoN").value;
            Revisa1 = document.getElementById("nfirmas-revisoN1").value;
            Autoriza = document.getElementById("nfirmas-autorizoN").value;
        }
        //generarOrdenPagoMultiple
        let fd = new FormData()  
		fd.append("id", "guardarOrdenPago")
		fd.append("banco", Banco);
        fd.append("cantidad", Cantidad);
        fd.append("receptor", Receptor);
        fd.append("concepto", Concepto);
        fd.append("solicita", Solicita);
        fd.append("tarjeta", Tarjeta);
        fd.append("tipo", tipo);
        fd.append("revisa", Revisa);
        fd.append("revisa1", Revisa1);
        fd.append("autoriza", Autoriza);
        fd.append("IDempresa", props.dptoid); 
        fd.append("solicitudpago", "0");
        //for each checked
        for(var i=0;i<document.getElementsByName("checkboxop").length;i++){   
			if(document.getElementsByName("checkboxop")[i].checked == true){ 
			   fd.append("ordenescompra[]", document.getElementsByName("checkboxop")[i].id) 
		   } 
	   }


		const rese = await axios.post(process.env.REACT_APP_API_URL, fd);
 
       // const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&idempresa='+props.dptoid+'&tipo='+tipo+'&cantidad='+cantidad+'&proveedor='+proveedor+'&concepto='+concepto);  
        closeModalGenerar();
        notify(rese.data.trim()); 
        setIsOpenGenerar1(false);
        //set checked false
        
        for(var i=0;i<document.getElementsByName("checkboxop").length;i++){   
			if(document.getElementsByName("checkboxop")[i].checked == true){ 
			    document.getElementsByName("checkboxop")[i].checked = false;
		   } 
	    }
        setImporteAcumulado([]);


        //document.getElementById("orden-estacion").value=""; //?
        getOrdenes();
        
    }

    async function Notificar(tipo){
     //   var tipoFirma = "";
        var name = "";
        if(tipo == "1"){
     //      tipoFirma = "solicito";
             name = document.getElementById("solicita").innerHTML; 
        }else if(tipo == "2"){
       //     tipoFirma = "reviso";
             name = document.getElementById("revisa").innerHTML; 
        }else if(tipo == "3"){
       //     tipoFirma = "realizo";
             name = document.getElementById("realiza").innerHTML; 
        }else if(tipo == "4"){
       //     tipoFirma = "autorizo";
             name = document.getElementById("autoriza").innerHTML; 
        }
        if(window.confirm('Enviar notificacion a: ' + name )){
           
            var folioorden = document.getElementById("folio").value; 
            let fd = new FormData()  
            fd.append("id", "enviarNotificacion")
            fd.append("mensaje", "Se requiere su firma para orden de compra: " + folioorden)
            fd.append("name", name)
            fd.append("folioorden", folioorden)
            fd.append("idrequisicion", document.getElementById("idrequisicion").value)
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            //notify(res.data.trim());
            notify("Notificación enviada correctamente");
        }
    }

    function actualizarFolioFactura(idorden){ 
        var Factura = document.getElementById("Factura"+idorden).value; 
        let topost = new FormData();
                topost.append("id", "actualizarFolioFactura"); //actualizar orden de pago
                topost.append("idorden", idorden);
                topost.append("Factura", Factura);  
                
            fetch(process.env.REACT_APP_API_URL,{
                method: 'POST',
                mode: 'cors',
                body: topost
            })
            .then(response => response.text())
            .then((data) => {
                notify(data.trim());
            });

            getOrdenes();
    
        
}
    
    async function Autorizar(tipo){
        var unob = document.getElementById("bsolicita"); 
        var dosb = document.getElementById("brevisa"); 
        var tresb = document.getElementById("brealiza");
        var tipodos = "0";
        if(tipo == "1"){
            tipodos = document.getElementById("solicita-tipo").innerHTML;
        } 
        if(tipo == "2"){
            tipodos = document.getElementById("revisa-tipo").innerHTML;
        }   
        if(tipo == "3"){
            tipodos = document.getElementById("realiza-tipo").innerHTML;
        }   
        if(tipo == "4"){
            tipodos = document.getElementById("autoriza-tipo").innerHTML;
        } 
        if(tipo == "4" || tipo == "14"){
            if(unob.getAttribute("hidden") == "true" && dosb.getAttribute("hidden") == "true" && tresb.getAttribute("hidden") == "true" ){
              var total = document.getElementById("t-total").value; 
              total = total.replace("$", "");
              total = total.replace(",", "");
              total = total.replace(",", "");
              if(total > 500000){ 
                notify("No se puede firmar, excede la cantidad maxima para firmar digitalmente");  
              }else{
                var id = document.getElementById("idrequisicion").value;  
                openModalLoad();
                let fd = new FormData() 
                fd.append("id", "UpdateAutorizar")
                fd.append("idrequisicion", id)
                fd.append("userid", props.userid)
                fd.append("tipo", tipodos) 
                fd.append("name", props.name)
                fd.append("idorden", document.getElementById("folio").value) 
                fd.append("rzonsocial", props.rzonsocial) 
                const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
                closeModalLoad();
                getOrdenes();
                
                notify(res.data.trim());
                let foliof = document.getElementById("folio").value;
                verOrden(document.getElementById("idrequisicion").value,foliof);
              } 
            } else {
                notify("No es posible autorizar");
            }
        } else {
            openModalLoad();
             
            let fd = new FormData() 
            fd.append("id", "UpdateAutorizar")
            fd.append("idrequisicion", document.getElementById("idrequisicion").value)
            fd.append("userid", props.userid)
            fd.append("tipo", tipodos) 
            fd.append("name", props.name)
            fd.append("idorden", document.getElementById("folio").value) 
            fd.append("rzonsocial", props.rzonsocial) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            closeModalLoad();
            getOrdenes();
            
            notify(res.data.trim());
            let foliof = document.getElementById("folio").value;
            verOrden(document.getElementById("idrequisicion").value,foliof); 
            
        }
    }


    async function Revocar(e){
       // console.log(e.target.innerHTML);
        //alert(e.target.name + " " + props.userid + " " + document.getElementById("idrequisicion").value);


            openModalLoad();
             
            let fd = new FormData() 
            fd.append("id", "revocarFirma")
            fd.append("idrequisicion", document.getElementById("idrequisicion").value)
            fd.append("userid", props.userid)
            fd.append("tipo", e.target.name) 
            fd.append("name", props.name)
            fd.append("idorden", document.getElementById("folio").value) 
            fd.append("rzonsocial", props.rzonsocial) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            closeModalLoad();
            getOrdenes();
            
            notify(res.data.trim());
            let foliof = document.getElementById("folio").value;
            verOrden(document.getElementById("idrequisicion").value,foliof); 
             
    }


    async function restablecerOrden(){  
        //si no puede restablecer retornar mensaje 
        if(props.restableceoc == "0"){
            closeModalMotivo();
            closeModalRes();
            notify("No tiene permiso para restablecer ordenes de compra");
            return;
        }
        if(window.confirm('¿Restablecer orden de compra?')){ 
            if(document.getElementById("inputmotivo").value == ""){
                notify("Ingrese el motivo para restablecer");
                return; 
            }
            let fd = new FormData()  
            fd.append("id", "restablecerOrden")
            fd.append("idorden", document.getElementById("folio").value) 
            fd.append("idrequisicion", document.getElementById("idrequisicion").value) 
            fd.append("rzonsocial", props.rzonsocial) 
            fd.append("motivo", document.getElementById("inputmotivo").value) 
            fd.append("userid", props.userid) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            notify(res.data.trim());
            if (res.data.trim() === "Orden de compra restablecida correctamente") {
                // Hide the 'Imprimir' button
                document.getElementById("bnt-imprimir").hidden = true;
            }
            closeModalMotivo();
            closeModalRes();
            var idre = document.getElementById("idrequisicion").value;  
                let foliof = document.getElementById("folio").value;
                verOrden(idre,foliof);
           //getRequisiciones();
        }
    }

     


    async function getOrdenes(){    
        var id = "GetOrdenes";   

        setLista([]);
        var rzonsocial = props.rzonsocial;
        var date = document.getElementById("input-fecha").value;  
        var termino = document.getElementById("input-fecha-termino").value;  
        var folio = document.getElementById("input-folio").value;  
        var proveedor = document.getElementById("input-proveedor").value;  
        var identificadoroc = document.getElementById("input-identificador").value;   
        var bsolicita = document.getElementById("buscar-solicita").value;  
        var chkboxfirma = document.getElementById("chkboxfirma").checked;  
        var checkboxopi = document.getElementById("checkboxopi").checked;  
         
        
        openModalLoad();
        const res = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&userid='+props.userid+'&date='+date+'&dptoid='+props.dptoid+'&rzonsocial='+rzonsocial+'&tipousuario='+props.tipo+'&termino='+termino+'&folio='+folio+'&proveedor='+proveedor+'&identificador='+identificadoroc+'&solicita='+bsolicita+'&chkboxfirma='+chkboxfirma+'&checkboxopi='+checkboxopi);
        closeModalLoad();
        //console.log(res.data);
        var table = document.getElementById('productstable');
        
        if(res.data.length >= 1){
            let res1;
            if(props.superus != 1){
                res1 = res.data.filter(item => 
                    (item.idsolicita1 == props.userid) 
                || (item.idsolicita2 == props.userid)
                || (item.idrevisa1 == props.userid)
                || (item.idrevisa2 == props.userid)
                || (item.idrealiza1 == props.userid)
                || (item.idrealiza2 == props.userid)
                || (item.idautoriza1 == props.userid)
                || (item.idautoriza2 == props.userid)
                || (item.usuariogenero == props.userid)
                || (item.admindptodestino == "1")
                || (((props.tipo == "2") && item.dptoidsolicita == props.userdptoid)));
            }else{
                res1 = res.data;
            }
            
            setLista(res1); 
            try{
             //   table.removeAttribute("hidden");
            } catch(Exception){}
        } else {
           // table.setAttribute("hidden", true);
        }
        
    }
   

    async function guardarPrecios(){ 
     //   var idd = document.getElementById("idrequisicion").value; 
        var pro = document.getElementById("proveedor1oc").value; 
        var est = document.getElementById("orden-estacion").value;
        
        var observaciones = document.getElementById("observaciones").value;
        observaciones = observaciones.substring(15);

        if(pro==""||pro==null||est==""||est==null){return notify("Complete todos los campos")}

        if(est=="Corporativo"){
            est = 1;
        }
        if(est=="Santa Fe"){
            est=2;
        }
        if(est=="Ley del Mar"){
            est=3;
        }
        if(est=="Libramiento"){
            est=4;
        }
        if(est=="Munich"){
            est=5;
        }
        if(est=="Insurgentes"){
            est=6;
        }
        if(est=="Lopez Saenz"){
            est=7;
        }
        if(est=="Rio"){
            est=8;
        }
        if(est=="Gas"){
            est=9;
        }
        if(est=="Todas las estaciones"){
            est=10;
        }
        if(est=="Servitanques"){
            est=11;
        }

       // notify(observaciones)
        let fd = new FormData() 
        fd.append("id", "guardarPrecios")
        fd.append("idorden", document.getElementById("folio").value) 
        fd.append("ivaret", ((document.getElementById("t-ivaret").value).replace("$","")).replace(",",""))
        fd.append("isr", ((document.getElementById("t-isrret").value).replace("$","")).replace(",",""))
        fd.append("rzonsocial", document.getElementById("rzonsocial").innerHTML)
        fd.append("proveedor", document.getElementById("proveedor1oc").value)
        fd.append("estacion", document.getElementById("orden-estacion").value)
        fd.append("descuento", ((document.getElementById("t-descuento").value).replace("$","")).replace(",",""))
        fd.append("total", ((document.getElementById("t-total").value).replace("$","")).replace(",",""))
        fd.append("observacion", observaciones)
        fd.append("idreq", document.getElementById("idrequisicion").value)

        for (var i=0; i<document.getElementsByName("rowprods").length; i++){
            fd.append("folio[]", document.getElementsByName("folio")[i].value)
            fd.append("preciouni[]", document.getElementsByName("costouni")[i].value)
            let iva = document.getElementsByName("checkbox")[i];
            if(iva.checked==true){
                iva = 1;
            }else{iva=0;} 
            fd.append("iva[]", iva)
        }

       
        //console.log(fd);

		if(pro != ""){

            if(document.getElementById("orden-estacion").value != ""){
                const res = await axios.post(process.env.REACT_APP_API_URL, fd);  
                //
                notify(res.data.trim());
                if (res.data.trim() === "Estacion, proveedor y precios guardados") {
                    // Hide the 'Imprimir' button
                    document.getElementById("bnt-imprimir").hidden = false;
                }
                var idre = document.getElementById("idrequisicion").value;  
                let foliof = document.getElementById("folio").value;
                verOrden(idre,foliof);
            } else {
                notify("Seleccione estación");
            }
            
        } else {
            notify("Ingrese datos del proveedor");
        }
    }

    async function addCotizacion(cell){ 
        //console.log(cell);
        document.getElementById("input-cotizacion").click();
      //  id = cell;
        closeModal1();
    }

    async function selCotizacion(idCotizacion){ 
 
        if(idCotizacion==null||idCotizacion==""){return notify("Seleccione Cotización valida")}
        let fd = new FormData() 
        fd.append("id", "selCotizacion")
        fd.append("idrequisicion", requisicionSeleccionada)
        fd.append("idcotizacion", idCotizacion) 
        const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
        
        notify(res.data.trim()); 
        closeModal1();
        getOrdenes();
        let foliof = document.getElementById("foliof").value;
        verOrden(requisicionSeleccionada,foliof);
    }
    async function actualizarFecha(folio, foliof) { 
        var date = document.getElementById(folio).value; 
        if(window.confirm('Actualizar fecha de la orden de compra con folio: ' + foliof + ' al día: ' + date)){  
         let fd = new FormData()  
         fd.append("id", "actualizarFechaOrdenCompra") 
         fd.append("idorden", folio) 
         fd.append("fechaorden", date) 
         const res = await axios.post(process.env.REACT_APP_API_URL, fd);  
          
         notify(res.data.trim()); 
         getOrdenes(); 
        }}

    async function postFile(){
        let fd = new FormData() 
        fd.append("id", "guardarCotizacion")
        fd.append("idorden", requisicionSeleccionada)
        fd.append("file", document.getElementById("input-cotizacion").files[0]) 
		const res = await axios.post(process.env.REACT_APP_API_URL,  fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });  
        notify(res.data.trim());
        //cierre
        closeModal1();
        getOrdenes();
    }

    


    function filtrarPorProveedor1(select, input){
		var selProveedores = document.getElementById(select);
		var currentInput = document.getElementById(input);
		var result = props.proveedores1.filter(item => item.proveedor.toLowerCase().includes(currentInput.value.toLowerCase()))
		//console.log(result);
		 setProveedor(result);
		 
		if(currentInput.value.toLowerCase() == "" || result.length < 1){
		 selProveedores.style.display = "none";
		}else{
		 selProveedores.style.display = "block";
		 selProveedores.selectedIndex = -1;
		 selProveedores.size = 5;
		 var my_index = 100;
		 selProveedores.style.zIndex = my_index++;
		}
	 }
    function proveedorSelected1(select, input){
		document.getElementById(input).value = document.getElementById(select).value; 
		document.getElementById(select).style.display = "none"; 
	}

    function filtrarPorProveedorFormatoOC(){
		var selProveedores = document.getElementById("pro1oc");
		var currentInput = document.getElementById("proveedor1oc");
		var result = props.proveedores1.filter(item => item.proveedor.toLowerCase().includes(currentInput.value.toLowerCase()))
		//console.log(result);
		 setProveedor(result);
		 
		if(currentInput.value.toLowerCase() == "" || result.length < 1){
		 selProveedores.style.display = "none";
		}else{
		 selProveedores.style.display = "block";
		 selProveedores.selectedIndex = -1;
		 selProveedores.size = 5;
		 var my_index = 100;
		 selProveedores.style.zIndex = my_index++;
		}
	 }
    function proveedorSelectedFormatoOC(){
		document.getElementById("proveedor1oc").value = document.getElementById("pro1oc").value; 
		document.getElementById("pro1oc").style.display = "none"; 
	}

/*
    async function getDepartamentos2(){
    	var id = "getDepartamentoss";
		setDeptos2([]);
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
		setDeptos2(rese.data);  
		//console.log(rese.data);
		var Data     = JSON.stringify(rese.data);
		//console.log(Data[0]); 
	}*/
 
     var tdescuento = 0;
     var tivaret = 0;
     var tisrret = 0;
    async function verOrden(idd, foliof){

        document.getElementById("noReimpresiones").value = "";
        setGenerada(0);
		setAutorizada(0);
		setConOrden(0);
		setConAnticipo(0);
		setPagada(0);
		setSurtida(0);
		setEstadoCancelada(0);
        ocultarCancelada();
        

        var section = document.getElementById("pdf");
        //document.getElementById("esta").removeAttribute("disabled");
        document.getElementById("esta").selectedIndex = 0;
        document.getElementById("orden-estacion").value="";
                if(props.tipo != 2){
                //    alert(props.tipo);
            document.getElementById("bnt-imprimir").hidden = true;
                }
     //   var bttn = document.getElementById(idd);

        for(var i=0;i<document.getElementsByName("producto").length;i++){
            document.getElementsByName("producto")[i].value = "";
            //document.getElementsByName("descripcion")[i].value = "";
            document.getElementsByName("unidad")[i].value = ""; 
            document.getElementsByName("cantidad")[i].value = "";
            document.getElementsByName("folio")[i].value = ""; 
            document.getElementsByName("costouni")[i].value = "";
            document.getElementsByName("total")[i].value = "";
            document.getElementsByName("checkbox")[i].checked = false;
        }

        for( i=0;i<document.getElementsByName("proveedor").length;i++){
            document.getElementsByName("proveedor")[i].value = ""; 
            document.getElementsByName("precio")[i].value = "";      
        }

        setHistorialImpresiones([]);
        setHistorialRestablecer([]);

        document.getElementById("observaciones").value = ""; 
        document.getElementById("proveedor1oc").value = ""; 
        document.getElementById("t-subtotal").value = ""; 
        document.getElementById("t-iva").value = ""; 
        document.getElementById("t-ivaret").value = ""; 
        document.getElementById("t-isrret").value = ""; 
        document.getElementById("t-total").value = "";  
        document.getElementById("idrequisicion").value = "";  
        document.getElementById("t-descuento").value = "";  
       document.getElementById("departamentos").value = "";  
       document.getElementById("usuario-elaboro").value = "";  
        var idrequisicion = idd; 
        var foliofac = foliof;
        var id = "getDetallesOrden";
        var rzonsocial = props.rzonsocial;
        openModalLoad();
        await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&idorden='+idrequisicion+'&rzonsocial='+rzonsocial+'&fcompra='+foliofac)
        .then(res => { 
            closeModalLoad();
           
            setHistorialImpresiones(res.data[5]);
            setHistorialRestablecer(res.data[6]);
            //document.getElementById("departamento").value = res.data[0].rzonsocial;  
            if(res.data[0][0].estadoorden == "Generada"){
                setGenerada(16.66);
            } 
            if(res.data[0][0].estadoorden == "Anticipo"){
                setGenerada(16.66);
                setAutorizada(16.66);
                setConOrden(16.66);
                setConAnticipo(16.66);
            } 
            if(res.data[0][0].estadoorden == "Pagada"){
                setGenerada(16.66);
                setAutorizada(16.66);
                setConOrden(16.66);
                setConAnticipo(16.66);
                setPagada(16.66);
            } 
            if(res.data[0][0].estadoorden == "Surtida"){
                setGenerada(16.66);
                setAutorizada(16.66);
                setConOrden(16.66);
                setConAnticipo(16.66);
                setPagada(16.66);
                setSurtida(16.66);
            } 

            if(res.data[4][0].ordenpago != "0"){
                setGenerada(16.66);
                setAutorizada(16.66);
                setConOrden(16.66);
            } 
            if(res.data[0][0].estadoorden == "Cancelada"){
                mostrarCancelada();
            }
            let ocancelada = 0;
            if(res.data[0][0].estadoorden == "Cancelada"){
                setGenerada(0);
                setAutorizada(0);
                setConOrden(0);
                setConAnticipo(0);
                setPagada(0);
                setSurtida(0);
                setEstadoCancelada(100);
                ocancelada = 1;
            }
            if(res.data[0][0].estado == "Cancelada"){
                setGenerada(0);
                setAutorizada(0);
                setConOrden(0);
                setConAnticipo(0);
                setPagada(0);
                setSurtida(0);
                setEstadoCancelada(100);
            }
            
            document.getElementById("usuario-elaboro").value = res.data[0][0].nombregenero;  


            if(res.data[0][0].departamentoelabora == null ){
                document.getElementById("departamentos").value = res.data[0][0].departamentos; 
             }else{
                //
               // document.getElementById("departamentos").value = res.data[0][0].departamentoelabora; 
                document.getElementById("departamentos").value = res.data[0][0].departamentos; 
             }

             
             try{
                if(res.data.indexOf(2)){
                    for( i=0;i<res.data[2].length;i++){ 
                        if(res.data[2][i].proveedor != ""){
                            document.getElementById("proveedor1oc").value = res.data[2][i].proveedor; 
                        }     
                    
                    }
                    for( i=0;i<document.getElementsByName("proveedor").length;i++){
                        document.getElementsByName("proveedor")[i].value = res.data[2][i].proveedor;  
                        document.getElementsByName("precio")[i].value = res.data[2][i].precio;  
                    } 
                }
            } catch (Exception){}
             
           let identificadorOrden = "";
           if(res.data[0][0].identificador != null){
            identificadorOrden = res.data[0][0].identificador;
            }

            var obs = res.data[0][0].observaciones;
            //obs = obs.replace(/(.*?\s.*?\s)/g, '$3'+'\n');
            document.getElementById("observaciones").value = "Observaciones: "+ obs; 
            document.getElementById("obs1").innerHTML = "Observaciones: ";//+ obs; 
            document.getElementById("solicita").value = res.data[0][0].solicita;
            document.getElementById("fecha").value = formatDate1(res.data[0][0].fechaorden);    // (res.data[0].fechaorden).substring(0, 4) +"/"+ (res.data[0].fechaorden).substring(5,7) + "/" + (res.data[0].fechaorden).substring(8,10); 
            document.getElementById("folio").value = res.data[0][0].foliof;
            document.getElementById("idrequisicion").value = res.data[0][0].idrequisicion;
            if(res.data[0][0].proveedor!= null){
                document.getElementById("proveedor1oc").value = res.data[0][0].proveedor;
            } 
            if(res.data[0][0].estacion != null){
                document.getElementById("orden-estacion").value = res.data[0][0].estacion;
                document.getElementById("esta").setAttribute("hidden", true);
            }else{ 
                document.getElementById("esta").removeAttribute("hidden");

            }
            
            //console.log();
            if(res.data[0][0].ivaret != null){
                document.getElementById("t-ivaret").value = res.data[0][0].ivaret;
                tivaret =document.getElementById("t-ivaret").value;
                document.getElementById("t-ivaret").value =formatNumber(document.getElementById("t-ivaret").value);
                //document.getElementById("t-ivaret").setAttribute("disabled", "disabled");
                //document.getElementById("t-ivaret").style.color = "black";
            }

            if(res.data[0][0].isr != null){
                document.getElementById("t-isrret").value = res.data[0][0].isr;
                tisrret = document.getElementById("t-isrret").value;
                document.getElementById("t-isrret").value = formatNumber(document.getElementById("t-isrret").value);
                //document.getElementById("t-isrret").setAttribute("disabled", "disabled");
                //document.getElementById("t-isrret").style.color = "black";
            }
            if(res.data[0][0].descuento != null){
                document.getElementById("t-descuento").value = res.data[0][0].descuento;
                tdescuento =document.getElementById("t-descuento").value;
                document.getElementById("t-descuento").value = formatNumber(tdescuento);
                //document.getElementById("t-isrret").setAttribute("disabled", "disabled");
                //document.getElementById("t-isrret").style.color = "black";
            }
            setImpreso(res.data[0][0].impreso);

            if(res.data[0][0].proveedor != null){
                document.getElementById("proveedor1oc").setAttribute("readonly", true);
                document.getElementById("pro1oc").setAttribute("disabled", true);
            } else {
                document.getElementById("proveedor1oc").removeAttribute("readonly");
                document.getElementById("pro1oc").removeAttribute("disabled");
            }
            if(res.data[0][0].estacion != null){
                document.getElementById("orden-estacion").setAttribute("disabled", true);
                document.getElementById("esta").setAttribute("disabled", true);
            } else {
                document.getElementById("orden-estacion").removeAttribute("disabled");
                document.getElementById("esta").removeAttribute("disabled");
            }
            var tableHeaderRowCount = 2;
            var table = document.getElementById('productosrows');
            var rowCount = table.rows.length;
            var tedt = document.getElementById("orden-estacion").value;
               
                //console.log ("test",tedt);
                if(tedt==""||tedt==null){
                  
                document.getElementById("bnt-imprimir").hidden = true;
                    }

            for (var i = tableHeaderRowCount; i < rowCount; i++) {
                table.deleteRow(tableHeaderRowCount);
            } 

           // var arraydata = [];
           // arraydata = JSON.stringify(res.data);
            try{
                if(res.data.indexOf(1)){
                    //CREATES EMPTY ROW (FORMATTED) FOR PRODUCT
                    for( i=0;i<res.data[1].length-1;i++){
                        var newrow = table.lastChild.cloneNode(true);
                        newrow.childNodes[0].addEventListener("change", Change);
                        newrow.childNodes[4].addEventListener("keyup", Change);
                        table.appendChild(newrow);
                    }

                    //CLEAN DISABLED BEFORE ALL
                    var costosuni_inputs = document.getElementsByName("costouni");
                    for(var j=0; j<costosuni_inputs.length; j++){
                        costosuni_inputs[j].removeAttribute("disabled");
                    }

                    document.getElementById("t-ivaret").removeAttribute("disabled");
                    document.getElementById("t-isrret").removeAttribute("disabled");
                    //console.log(res.data[1].length);
                    //

                    for( i=0;i<res.data[1].length;i++){
                        document.getElementsByName("producto")[i].style.backgroundColor = '#00000000'; 
                      //  console.log(res.data[1][i].descripcion);
                        if(res.data[1][i].seleccionado == "1"){ 
                        if(res.data[1][i].iva == "1"){
                            document.getElementsByName("checkbox")[i].checked = true;
                        } else {
                            document.getElementsByName("checkbox")[i].checked = false;
                        }
                        if(res.data[1][i].asignadoflotillas == "1"){
                            document.getElementsByName("producto")[i].value = res.data[1][i].descripcion + " (Asignado en flotillas refacción)";
                            document.getElementsByName("producto")[i].style.backgroundColor = '#0071ce47'; 
                        } else if(res.data[1][i].asignadoflotillas == "2"){
                            document.getElementsByName("producto")[i].value = res.data[1][i].descripcion + " (Asignado en flotillas servicio)";
                            document.getElementsByName("producto")[i].style.backgroundColor = '#ff855c'; 
                        } else if(res.data[1][i].asignadoflotillas == "3"){
                            document.getElementsByName("producto")[i].value = res.data[1][i].descripcion + " (Asignado en flotillas accesorio)";
                            document.getElementsByName("producto")[i].style.backgroundColor = '#24dc65f5'; 
                        }else{
                            document.getElementsByName("producto")[i].value = res.data[1][i].descripcion;
                        }
                        
                        
                        document.getElementsByName("unidad")[i].value = res.data[1][i].unidad;
                        document.getElementsByName("cantidad")[i].value = res.data[1][i].cantidad;
                        document.getElementsByName("folio")[i].value = res.data[1][i].folio;
                        let precioi = 0;
                        if(res.data[1][i].costouni != null){
                        document.getElementsByName("costouni")[i].value = res.data[1][i].costouni;
                        precioi = res.data[1][i].costouni;
                        }else{
                            document.getElementsByName("costouni")[i].value = res.data[1][i].costouniinicial;
                            precioi = res.data[1][i].costouniinicial;
                        }
                        let desc = "";
                        let cant = 0;
                        desc = res.data[1][i].descripcion;
                        cant = res.data[1][i].cantidad;
                        let ptotal = precioi * cant;  
                        let foliopr = res.data[1][i].folio;
                      //  document.getElementsByName("producto")[i].addEventListener("dblclick", (e) => modalInventario(res.data[1][i].descripcion, precioi));
                        document.getElementsByName("producto")[i].ondblclick = function() {
                            modalInventario(desc, ptotal, identificadorOrden, foliopr)
                        };
                        }
                    }

                    var b = document.getElementById("boton");
                    var botonRestablecer = document.getElementById("botonRestablecer");
                
                    if(res.data[1][0].costouni != null){
                        b.setAttribute("hidden", true);
                        botonRestablecer.removeAttribute("hidden");
                        

                         costosuni_inputs = document.getElementsByName("costouni");
                        for( j=0; j<costosuni_inputs.length; j++){
                            costosuni_inputs[j].setAttribute("disabled", "disabled");
                        }

                        document.getElementById("t-ivaret").setAttribute("disabled", "disabled");
                        document.getElementById("t-isrret").setAttribute("disabled", "disabled");

                    } else {
                        b.removeAttribute("hidden");
                        botonRestablecer.setAttribute("hidden", true);
                    }

                    //CREATES EMPTY ROW (UNFORMATTED)
                    for( i=0; i<(16 - res.data[1].length-1); i++){ 
                        var a = table.insertRow(-1);
                         b = a.insertCell();
                        b.innerHTML="&nbsp;";
                        a.style.border = "1px solid black"
                        
                    }

                    Change(); 
                }
            } catch(Exception){console.log(Exception);}
            
            

            try{
                var firmasolicita = document.getElementById("firmasolicita");
                firmasolicita.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmasolicita.src;
                var firmarevisa = document.getElementById("firmarevisa");
                firmarevisa.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmarevisa.src;
                var firmarealiza = document.getElementById("firmarealiza");
                firmarealiza.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmarealiza.src;
                var firmaautoriza = document.getElementById("firmaautoriza");
                firmaautoriza.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmaautoriza.src;
                var uno = document.getElementById("solicita"); 
                var unol = document.getElementById("lsolicita");
                var unotipo = document.getElementById("solicita-tipo");
                var unob = document.getElementById("bsolicita");
                var dos = document.getElementById("revisa");
                var dosl = document.getElementById("lrevisa");
                var dostipo = document.getElementById("revisa-tipo");
                var dosb = document.getElementById("brevisa");
                var tres = document.getElementById("realiza");
                var tresl = document.getElementById("lrealiza");
                var trestipo = document.getElementById("realiza-tipo");
                var tresb = document.getElementById("brealiza");
                var cuatro = document.getElementById("autoriza");
                var cuatrol = document.getElementById("lautoriza");
                var cuatrotipo = document.getElementById("autoriza-tipo");
                var cuatrob = document.getElementById("bautoriza");
                
                var revocarb1 = document.getElementById("revocarb1");
                var revocarb2 = document.getElementById("revocarb2");
                var revocarb3 = document.getElementById("revocarb3");
                var revocarb4 = document.getElementById("revocarb4");

                revocarb1.setAttribute("hidden", true);
                revocarb2.setAttribute("hidden", true);
                revocarb3.setAttribute("hidden", true);
                revocarb4.setAttribute("hidden", true);

                //ocultar divs firmas 
                document.getElementById("firma1").setAttribute("hidden", true);
                document.getElementById("firma2").setAttribute("hidden", true);
                document.getElementById("firma3").setAttribute("hidden", true);
                document.getElementById("firma4").setAttribute("hidden", true);


                //ocultar iconos notificacion
                document.getElementById("notificar-icon-1").setAttribute("hidden", true);
                document.getElementById("notificar-icon-2").setAttribute("hidden", true);
                document.getElementById("notificar-icon-3").setAttribute("hidden", true);
                document.getElementById("notificar-icon-4").setAttribute("hidden", true);


                unol.setAttribute("hidden", true);
                dosl.setAttribute("hidden", true);
                tresl.setAttribute("hidden", true);
               {/* solicita xiomara */}
                // cuatrol.setAttribute("hidden", true);
                var CGA = 0;
                unob.setAttribute("hidden", true);
                dosb.setAttribute("hidden", true);
                tresb.setAttribute("hidden", true);
                cuatrob.setAttribute("hidden", true);
                var solicitadof = 0;
                var solicitai = "";
                var revisadof = 0;
                var revisadoi = "";
                var realizadof = 0;
                var realizadoi = "";
                var autorizadof = 0;
                var autorizadoi = "";
                for(a = 0; a < 2; a++){ 
                for( i=0;i<res.data[3].length;i++){ 
                    
                    if(res.data[3][i].tipo == "1" || res.data[3][i].tipo == "11"){
                        //mostrar firma 
                        document.getElementById("firma1").removeAttribute("hidden");
                        if(res.data[3][i].tipo == "1"){
                            solicitai = res.data[3][i].name ;
                        }
                        if(res.data[3][i].autorizado == "1"){ 
                            CGA = CGA+1;
                        }
                        if(res.data[3][i].autorizado == "0" && solicitai == "") {
                            uno.innerHTML = res.data[3][i].name;
                        }else if(res.data[3][i].autorizado == "0"){
                            uno.innerHTML = solicitai;
                        }
 
                        
                        if((props.userid == res.data[3][i].userid) && (res.data[3][i].autorizado == "0") && (solicitadof == 0)){
                              uno.innerHTML = res.data[3][i].name  ;
                            unotipo.innerHTML = res.data[3][i].tipo; 
                            delete firmasolicita.src;
                            if(ocancelada == 0){
                                unob.removeAttribute("hidden");
                            }
                            unob.setAttribute("name", "1"); 
                        } else { 
                            if(res.data[3][i].autorizado == "1"  ){
                                solicitadof = 1;
                                
                                firmasolicita.src = "data:image/png;base64,"+res.data[3][i].imagen; 
                           //    CGA = CGA+1;
                                if(res.data[3][i].tipo == "11"){
                                    uno.innerHTML = res.data[3][i].name + " EN AUSENCIA DE " + solicitai;
                                    if(props.userid == res.data[3][i].userid){
                                    revocarb1.removeAttribute("hidden");
                                    revocarb1.setAttribute("name", "11");
                                    }
                                }else{
                                    uno.innerHTML = solicitai;
                                    if(props.userid == res.data[3][i].userid){
                                        revocarb1.removeAttribute("hidden");
                                        revocarb1.setAttribute("name", "1");
                                    }
                                   
                                }
                                
                                unol.removeAttribute("hidden");
                                unob.setAttribute("hidden", true);
                                
                            }else{
                                document.getElementById("notificar-icon-1").removeAttribute("hidden");
                            }
                          
                        }
                    } 
                    let requierePrimeraRevision = 0;
                    if(res.data[3][i].tipo == "2" || res.data[3][i].tipo == "12"){
                        requierePrimeraRevision = 1;
                        document.getElementById("firma2").removeAttribute("hidden");
                        if(res.data[3][i].tipo == "2"){
                            revisadoi = res.data[3][i].name ;
                        }
                        if(res.data[3][i].autorizado == "1"){ 
                            CGA = CGA+1;
                        }
                        if(res.data[3][i].autorizado == "0" && revisadoi == "") {
                            dos.innerHTML = res.data[3][i].name;
                        }else if(res.data[3][i].autorizado == "0"){
                            dos.innerHTML = revisadoi;
                        }
 
                        if((props.userid == res.data[3][i].userid) && (res.data[3][i].autorizado == "0") && (revisadof == 0)){
                            dos.innerHTML = res.data[3][i].name  ;
                            dostipo.innerHTML = res.data[3][i].tipo; 
                            delete firmarevisa.src;
                            if(ocancelada == 0){
                                if(solicitadof == 1){
                                    dosb.removeAttribute("hidden");
                                }

                            }
                            dosb.setAttribute("name", "2"); 
                        } else {
                            if(res.data[3][i].autorizado == "1"){
                                revisadof = 1;
                                firmarevisa.src = "data:image/png;base64,"+res.data[3][i].imagen;
                            //    CGA = CGA+1;
                                if(res.data[3][i].tipo == "12"){
                                    dos.innerHTML = res.data[3][i].name + " EN AUSENCIA DE " + revisadoi;
                                    if(props.userid == res.data[3][i].userid){
                                    revocarb2.removeAttribute("hidden");
                                    revocarb2.setAttribute("name", "12");
                                }
                                }else{
                                    dos.innerHTML = revisadoi;
                                    if(props.userid == res.data[3][i].userid){
                                    revocarb2.removeAttribute("hidden");
                                    revocarb2.setAttribute("name", "2");
                                    }
                                }
                                dosl.removeAttribute("hidden");
                                dosb.setAttribute("hidden", true);
                            }else{
                                document.getElementById("notificar-icon-2").removeAttribute("hidden");
                            }

                            
                        } 
                    }
                    let requiereSegundaRevision = 0;
                    if(res.data[3][i].tipo == "3" || res.data[3][i].tipo == "13"){
                        requiereSegundaRevision = 1;
                        document.getElementById("firma3").removeAttribute("hidden"); //solo oc
                        
                        if(res.data[3][i].tipo == "3"){
                            realizadoi = res.data[3][i].name ;
                        }

                        if(res.data[3][i].autorizado == "1"){ 
                            CGA = CGA+1;
                        }
                        if(res.data[3][i].autorizado == "0" && realizadoi == "") {
                            tres.innerHTML = res.data[3][i].name;
                        } else if(res.data[3][i].autorizado == "0"){
                            tres.innerHTML = realizadoi;
                        }
                         
                        if((props.userid == res.data[3][i].userid) && (res.data[3][i].autorizado == "0") && (realizadof == 0)){
                            tres.innerHTML = res.data[3][i].name  ;
                            trestipo.innerHTML = res.data[3][i].tipo; 
                            delete firmarealiza.src;
                            if(ocancelada == 0){
                                if(solicitadof == 1 && revisadof == 1){
                                    tresb.removeAttribute("hidden");
                                }
                            }
                            tresb.setAttribute("name", "3");
                        } else {
                            if(res.data[3][i].autorizado == "1"){
                                realizadof = 1;
                                firmarealiza.src = "data:image/png;base64,"+res.data[3][i].imagen;
                           //     CGA = CGA+1;
                                if(res.data[3][i].tipo == "13"){
                                    tres.innerHTML = res.data[3][i].name + " EN AUSENCIA DE " + realizadoi;
                                    if(props.userid == res.data[3][i].userid){
                                    revocarb3.removeAttribute("hidden");
                                    revocarb3.setAttribute("name", "13");
                                    }
                                }else{
                                    tres.innerHTML = realizadoi;
                                    if(props.userid == res.data[3][i].userid){
                                    revocarb3.removeAttribute("hidden");
                                    revocarb3.setAttribute("name", "3");
                                    }
                                }
                                tresl.removeAttribute("hidden");
                                tresb.setAttribute("hidden", true);
                            }else{
                                document.getElementById("notificar-icon-3").removeAttribute("hidden");
                            }
                            
                            
                        }
                    }
                    
                    if(res.data[3][i].tipo == "4" || res.data[3][i].tipo == "14"){
                         
                        document.getElementById("firma4").removeAttribute("hidden");
                        if(res.data[3][i].tipo == "4"){
                            autorizadoi = res.data[3][i].name ;
                        }
                        if(res.data[3][i].autorizado == "0" && autorizadoi == "") {
                            cuatro.innerHTML = res.data[3][i].name;
                        } else if(res.data[3][i].autorizado == "0"){
                            cuatro.innerHTML = autorizadoi;
                        }
                        if((props.userid == res.data[3][i].userid) && (res.data[3][i].autorizado == "0") && (autorizadof == 0)){
                             
                            //if((solicitadof == 1) && (revisadof == 1) && (realizadof == 1)){  anteriormente se requeria realizado tambien 
                               
                                if(requiereSegundaRevision == 1){
                                   // console.log("requiere segunda revision");
                                    if((solicitadof == 1) && (revisadof == 1) && (realizadof == 1) ){ 
                                        cuatro.innerHTML = res.data[3][i].name  ;
                                        cuatrotipo.innerHTML = res.data[3][i].tipo; 
                                        delete firmaautoriza.src;
                                        if(ocancelada == 0){
                                            cuatrob.removeAttribute("hidden");
                                        }
                                        cuatrob.setAttribute("name", "4");
                                    }
                                }else{
                                    if((solicitadof == 1) && (revisadof == 1 || realizadof == 1) ){
                                      //  console.log("sdfghjklñ"); 
                                        cuatro.innerHTML = res.data[3][i].name  ;
                                        cuatrotipo.innerHTML = res.data[3][i].tipo; 
                                        delete firmaautoriza.src;
                                        if(ocancelada == 0){
                                            cuatrob.removeAttribute("hidden");
                                        }
                                        cuatrob.setAttribute("name", "4");
                                    }
                                }
                        } else { 
                            if(res.data[3][i].autorizado == "1"){
                                autorizadof = 1;
                                if(estadocancelada != 100){

                                    setGenerada(16.66);
                                    setAutorizada(16.66);
                                }

                                firmaautoriza.src = "data:image/png;base64,"+res.data[3][i].imagen;
                                if(res.data[3][i].tipo == "14"){
                                    cuatro.innerHTML = res.data[3][i].name + " EN AUSENCIA DE " + autorizadoi;
                                    if(props.userid == res.data[3][i].userid){
                                    revocarb4.removeAttribute("hidden");
                                    revocarb4.setAttribute("name", "14");
                                    }
                                }else{
                                    //res.data[3][i].name
                                    cuatro.innerHTML = autorizadoi;
                                    if(props.userid == res.data[3][i].userid){
                                    revocarb4.removeAttribute("hidden");
                                    revocarb4.setAttribute("name", "4");
                                    }
                                }
                                cuatrol.removeAttribute("hidden");
                                cuatrob.setAttribute("hidden", true);
                            }else{
                                document.getElementById("notificar-icon-4").removeAttribute("hidden");

                            }
                         }
                            
                    }
                }
            }
                var textArea = document.getElementById("autorizartextarea"); 

                if(res.data.indexOf(3)){
                    for( i=0;i<res.data[3].length;i++){
                        textArea.value = textArea.value + res.data[3][i].name + "\n";
                    } 
                }
            } catch (Exception){}
            section.scrollIntoView({ behavior: "smooth", block: "start" });
        }); 
    }
 
    async function checarAnticipo(id){
        let select = document.getElementById("status"+id).value;
        if(select=='Anticipo'){
            openModalAbono(id);
        }else if(select=='Pagada'){
            openModalPagada(id);
        }else{
            ActualizarStatus(id);
        }
    }

    async function ActualizarStatus(id){
        let select = document.getElementById("status"+id).value;
        /*  GET ROW OF WHERE SELECT WAS CHANGED  */
        var rows = document.getElementsByClassName('id-orden');
       // var rw;
        
        for(let element of rows){
            if (element.value == id){
              //  var tr = element.parentElement;
               // var td = tr.getElementsByTagName('select')[0];
              //  rw = td.value;
            }
        }
        
        
        if(window.confirm('Quieres actualizar a: ' + select)){
            
            let fd = new FormData() 
            fd.append("id", "updateOrdenGenerado")
            fd.append("idorden", id)
            fd.append("nvoestado", select)
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
            notify((res.data).trim());
            getOrdenes();
            
        }
    }
    async function guardarInventario(){
        if(window.confirm('Continuar?')){
            
           

            if(document.getElementById("tipo").value == "refaccion"){
                let fd = new FormData() 
                fd.append("id", "guardarDesdeCompras")
                fd.append("producto", document.getElementById("inventarioproducto").value)
                fd.append("descripcion", document.getElementById("inventariodescripcion").value)
                fd.append("proveedor", document.getElementById("inventarioproveedor").value)
                fd.append("fecha", document.getElementById("inventariofecha").value)
                fd.append("precio", document.getElementById("inventarioprecio").value)
                fd.append("vehiculoid", document.getElementById("vehiculoid").value)
                fd.append("tipo", document.getElementById("tipo").value)
                fd.append("foliooc", document.getElementById("folio").value)
                fd.append("folioproducto", folioProductoR)
                
                const res = await axios.post("https://flotillas.grupopetromar.com/apirestflotilla/index.php", fd); 
                
                notify((res.data).trim());
                //getOrdenes();
               
                if(res.data.trim() == "Agregado correctamente"){
                    productoAsignado("1");
                }
            }else if(document.getElementById("tipo").value == "servicio"){
                setOdometroDef(document.getElementById("servicioodometro").value);
                let fd = new FormData() 
                fd.append("id", "guardarDesdeCompras")
                fd.append("producto", document.getElementById("servicioproducto").value)
                fd.append("descripcion", document.getElementById("serviciodescripcion").value) 
                fd.append("fecha", document.getElementById("serviciofecha").value)
                fd.append("odometro", document.getElementById("servicioodometro").value)
                fd.append("vehiculoid", document.getElementById("vehiculoid").value)
                fd.append("tipo", document.getElementById("tipo").value)
                fd.append("precio", document.getElementById("servicioprecio").value)
                fd.append("foliooc", document.getElementById("folio").value)
                fd.append("folioproducto", folioProductoR)

                const res = await axios.post("https://flotillas.grupopetromar.com/apirestflotilla/index.php", fd); 
                
                notify((res.data).trim());
                //getOrdenes(); 
                if(res.data.trim() == "Agregado correctamente"){
                    productoAsignado("2");
                }
            }else if(document.getElementById("tipo").value == "accesorio"){
               
                let fd = new FormData() 
                fd.append("id", "guardarDesdeCompras")
                fd.append("producto", document.getElementById("inventarioproducto").value)
                fd.append("descripcion", document.getElementById("inventariodescripcion").value)
                fd.append("proveedor", document.getElementById("inventarioproveedor").value)
                fd.append("fecha", document.getElementById("inventariofecha").value)
                fd.append("precio", document.getElementById("inventarioprecio").value)
                fd.append("vehiculoid", document.getElementById("vehiculoid").value)
                fd.append("tipo", document.getElementById("tipo").value)
                fd.append("foliooc", document.getElementById("folio").value)
                fd.append("folioproducto", folioProductoR)

                const res = await axios.post("https://flotillas.grupopetromar.com/apirestflotilla/index.php", fd); 
                
                notify((res.data).trim());
                //getOrdenes(); 
                if(res.data.trim() == "Agregado correctamente"){
                    productoAsignado("3");
                }
            }
            var id = document.getElementById("idrequisicion").value;  
            let foliof = document.getElementById("folio").value;
           // notify(id + " folio: "  + foliof);
            verOrden(id, foliof);
            setIsOpenInventario(false);

             
        }
    }

    async function productoAsignado(tipo){

        //1 refaccion
        //2 servicio
        //3 accesorio
        let fd = new FormData() 
        fd.append("id", "asignarProducto")
        fd.append("folioproducto", folioProductoR)
        fd.append("tipo", tipo)
        const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
        //console.log((res.data).trim());
    }

    async function getVehiculos() { 
        var id = "getVehiculos"; 
        const res = await axios.get("https://flotillas.grupopetromar.com/apirestflotilla/index.php"+'?id='+ id+'&idflotilla=1'); 
        setListaV(res.data); 
        //console.log(res.data);  process.env.REACT_APP_API_URL 
       
       }
    function test(){
        props.verReq();
    }
    async function quitarOrdenPago(idordencompra, foliof, idordenpago){
       
        
        if(window.confirm('¿Quitar orden de pago: ' + idordenpago + ' para orden de compra: ' + foliof + '?')){
            //orden de pago multiple
            let fd = new FormData() 
            fd.append("id", "quitarOrdenPago")
            fd.append("idorden", foliof)
            fd.append("idordenpago", idordenpago)
            fd.append("solicitudpago", "0")

            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
            notify((res.data).trim());
            getOrdenes();
            
        }
    }
    async function quitarOrdenPagoUnica(idordenpago){
       
        
        if(window.confirm('¿Quitar orden de pago: ' + idordenpago + '?')){
            //orden de pago multiple
            let fd = new FormData() 
            fd.append("id", "quitarOrdenPagoUnica") 
            fd.append("idordenpago", idordenpago)
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            
            notify((res.data).trim());
            getOrdenes();
            
        }
    }

    function modalInventario(producto, precio, identificador, foliop){
        //console.log(producto); 
        setInventarioProducto(producto);
        setInventarioDescripcion(identificador);
        //setInventarioFolioProducto(identificador);
        setInventarioPrecio(precio);
        setInventarioProveedor(document.getElementById("proveedor1oc").value);
        setFolioProductoR(foliop);
      //  let fech = new Date(document.getElementById("fecha").value.split("/").reverse().join("-"));
       // console.log(fech);
        setInventarioFecha(document.getElementById("fecha").value.split("/").reverse().join("-"));
     //   console.log(document.getElementById("fecha").value.split("/").reverse().join("-"));
        setIsOpenInventario(true);
        setTipoFlotilla([]);

    }

    function Change(){ 
        
        var checkbox = document.getElementsByName('checkbox');   
        var cantidades = document.getElementsByName('cantidad');
        var costosuni = document.getElementsByName('costouni');
        var totales = document.getElementsByName('total'); 
        var ivaret = ((document.getElementById('t-ivaret').value).replace("$", "")).replace(",",""); 
        var isrret = ((document.getElementById('t-isrret').value).replace("$", "")).replace(",",""); 
        var descuento = document.getElementById('t-descuento').value;  

         

        var ivar = 0;
        var isrr = 0; 
        if(ivaret != ""){
            ivar = parseFloat(ivaret);
        } 
        if(isrret != ""){
            isrr = parseFloat(isrret);
        } 

        var subtotal = 0;
      //  var totalesz;
        var iva = 0;
        
        for(var i = 0; i<costosuni.length; i++){
            //console.log(cantidades[i].value);
            if((costosuni[i].value !="") && (cantidades[i].value != "")){
                totales[i].value = formatNumber((costosuni[i].value * cantidades[i].value)); 
                if(checkbox[i].checked == true){
                    iva = iva + (((parseFloat((costosuni[i].value * cantidades[i].value)))) * valueIva);
                                
                }else{ 
                   // iva = 0; 
                } 
                subtotal += parseFloat((costosuni[i].value * cantidades[i].value));
            } else { 
                totales[i].value = ""; 
            }
        }
        // - (descuento.replace("$", "").replace(",",""))
        var descsinsigno = (descuento.replace("$", "").replace(",",""));
        document.getElementById("t-subtotal").value = subtotal;
        document.getElementById("t-iva").value = iva;
       
        //console.log(descuento.length);   
        if(descsinsigno > 0){ 
            
            document.getElementById("t-total").value = "$"+(
                parseFloat(document.getElementById("t-subtotal").value) - (descuento.replace("$", "").replace(",","")) +
                parseFloat(document.getElementById("t-iva").value) - ivar - isrr
            );
            var tsubtotaldescuento =parseFloat(document.getElementById("t-subtotal").value)-(descuento.replace("$", "").replace(",",""));
            
            document.getElementById("t-subtotal").value = formatNumber(tsubtotaldescuento);
           // document.getElementById("t-iva").value =  (tsubtotaldescuento * valueIva);
           //recalcular iva 
           for (var i = 0; i < checkbox.length; i++) {
                if (checkbox[i].checked == true) {
                    // Recalculate IVA if the checkbox is checked
                    document.getElementById("t-iva").value = (tsubtotaldescuento * valueIva);
                }
            }
            //alert(tsubtotaldescuento * valueIva);
            document.getElementById("t-total").value = (
                tsubtotaldescuento  +
                parseFloat(document.getElementById("t-iva").value) - ivar - isrr
            );
          
        } else{
        
            document.getElementById("t-total").value = (
                parseFloat(document.getElementById("t-subtotal").value)  +
                parseFloat(document.getElementById("t-iva").value) - ivar - isrr
            );
            var tsubtotal = document.getElementById("t-subtotal").value;
            document.getElementById("t-subtotal").value = formatNumber(tsubtotal);
           
        }
      
          
        
        document.getElementById("t-iva").value = formatNumber(document.getElementById("t-iva").value);
        
        document.getElementById("t-total").value  = formatNumber(document.getElementById("t-total").value);
    }
    function handleKeyPress  (event) {
		if(event.key == 'Enter'){ 
			getOrdenes();
            document.getElementById("buscar-pro").style.display = "none";
		}
	}
    function filtrarPorProveedor(e){
       var selProveedores = document.getElementById("buscar-pro");
       var result = props.proveedores1.filter(item => item.proveedor.toLowerCase().includes(e.target.value.toLowerCase()))
       //console.log(result);
        setListaProveedores(result);
       if(e.target.value.toLowerCase() == "" || result.length < 1){
        selProveedores.style.display = "none";
       }else{
        selProveedores.style.display = "block";
        selProveedores.selectedIndex = -1;
        selProveedores.size = 5;
        var my_index = 100;
        selProveedores.style.zIndex = my_index++;
        
       }
        
    }

    function proveedorSelected(){
        document.getElementById("input-proveedor").value = document.getElementById("buscar-pro").value;
        document.getElementById("buscar-pro").style.display = "none";
        getOrdenes();
    }
 
    
    function openModal21() {
        setIsOpenGenerar1(true);
      }
  

    function checked(){
        
        var checkBoxElements = document.getElementsByName("checkboxop");
        var proveedordef = document.getElementsByName("proveedordef");
        var conceptodef = document.getElementsByName("conceptodef");
        let proo = "";
        let conc = "";
        let totalAcumulado = 0;
        setImporteAcumulado(totalAcumulado);
        for(var i = 0; i<checkBoxElements.length; i++){ 
            if(checkBoxElements[i].checked == true){
                totalAcumulado += parseFloat(checkBoxElements[i].value);
                proo = proveedordef[i].value;
                conc = conceptodef[i].value;
            }
        }
        totalAcumulado = totalAcumulado.toFixed(2);
        setImporteAcumulado(totalAcumulado);
        setProveedorSugerido(proo);
        setConceptoSugerido(conc); 
    }
    
    function ivaFronterizo(){
        let check  = document.getElementById("checkFronterizo");
        if(check.checked){
            setValueIva(0.08);
        }else{
            setValueIva(0.16);
        }     
    }
   function selTipoOrden(){
        let sel = document.getElementById("slc-orden1");
        document.getElementById("nvaorden1").setAttribute('hidden','true');
        document.getElementById("nvaorden2").setAttribute('hidden','true');
        if(sel.value == "1"){
            document.getElementById("nvaorden1").removeAttribute('hidden'); 
            document.getElementById("nop-cantidad").defaultValue = importeAcumulado;
            document.getElementById("nop-anombre").defaultValue = proveedorSugerido;
            document.getElementById("nop-concepto").defaultValue = conceptoSugerido;
            
            
        }else if(sel.value == "2"){
            document.getElementById("nvaorden2").removeAttribute('hidden'); 
            document.getElementById("nop-cantidadN").defaultValue = importeAcumulado;
            document.getElementById("nop-anombreN").defaultValue = proveedorSugerido;
            document.getElementById("nop-conceptoN").defaultValue = conceptoSugerido;
        }
   }

   function selTipoOrden1(){
    let sel = document.getElementById("slc-orden");
    document.getElementById("nvaorden11").setAttribute('hidden','true');
    document.getElementById("nvaorden22").setAttribute('hidden','true');
    if(sel.value == "1"){
        document.getElementById("nvaorden11").removeAttribute('hidden'); 
        document.getElementById("nop-cantidad").defaultValue = importeTotal;
        document.getElementById("nop-anombre").defaultValue = proveedorSugerido;
        document.getElementById("nop-concepto").defaultValue = conceptoSugerido;
        
        
    }else if(sel.value == "2"){
        document.getElementById("nvaorden22").removeAttribute('hidden'); 
        document.getElementById("nop-cantidadN").defaultValue = importeTotal;
        document.getElementById("nop-anombreN").defaultValue = proveedorSugerido;
        document.getElementById("nop-conceptoN").defaultValue = conceptoSugerido;
    } 
}

function mostrarCancelada(){
    document.getElementById("pdf").classList.add("ocancelada");
    document.getElementById("pdf").classList.remove("no-after");
}

function ocultarCancelada(){
    document.getElementById("pdf").classList.add("no-after");
    document.getElementById("pdf").classList.remove("ocancelada");
}
 function excelExport(){

 }

 const tableRef = useRef(null); 
 
    const { onDownload } = useDownloadExcel({ 
        currentTableRef: tableRef.current, 
        filename: 'Ordenes de compra', 
        sheet: 'Ordenes de compra' 
    })

    const columns = [
        {
            name: '',  
            width: '30px',
            cell: (row) =>{
                return(
                    (row.ordenPagoMultiple == null) ?
                        <td class="width-2"> <input type='checkbox' name="checkboxop" value={row.importeTotal} id={row.foliof} onChange={() => checked()}></input> </td>
                        :
                        <td><input type='checkbox' name="checkboxop" value={row.importeTotal} id={row.foliof} onChange={() => checked()} hidden></input></td>        
                )
            } 
            
        },
        {
            name: '',  
            width: '60px',
            cell: (row) =>{
                return(
                    <td className='id-orden' >{row.foliof}</td>
                )
            } 
            
        },
        {
            name: 'Fecha',   
            cell: (row) =>{
                return(
                    <td><input class="input-date" type="date" id={row.idorden} onChange={() => actualizarFecha(row.idorden, row.foliof)} value={(row.fechacaptura).substring(0,10)}></input></td>
                )
            } 
            
        }, 
        {
            name: 'Pago', 
            width: "80px", 
            cell: (row) => {
                return ( 
                    (row.ordenpago == 0)? 
                    <td align='left'> 
                       <button value={row.ordencompra} className='btn btn-outline-success btn-sm' onClick={()=>openModalGenerar(row.ordencompra, row.foliof, row.importeTotal, row.proveedor, row.identificador)}>Generar</button>
                       {(row.ordenPagoMultiple != null && (props.superus == "1" || props.userdptoid == "9")) ?
                            
                            <label>{row.ordenPagoMultiple} <FaTrash class="color-red"  onClick={() => quitarOrdenPago(row.ordencompra, row.foliof, row.ordenPagoMultiple)} /> <FaExternalLinkSquareAlt  onClick={() => props.verOrdenPago(row.ordenPagoMultiple, "")} style={{color:props.defaultColor}}/></label>  
                         :
                            <></> 
                        }
                    </td>   :
                    <td align='left' id={"celdaOP"+row.foliof}>
                     <button value={row.ordencompra} className='btn btn-outline-success btn-sm' onClick={()=>openModalGenerar(row.ordencompra, row.foliof, row.importeTotal, row.proveedor, row.identificador)}>Generar</button>
                        {
                        getArray(row.arrayID, row.foliof)
                        } 
                        {(row.ordenPagoMultiple != null && (props.superus == "1" || props.userdptoid == "9")) ?
                            <label>{row.ordenPagoMultiple} <FaTrash class="color-red"  onClick={() => quitarOrdenPago(row.ordencompra, row.foliof, row.ordenPagoMultiple)} /> <FaExternalLinkSquareAlt  onClick={() => props.verOrdenPago(row.ordenPagoMultiple, "")} style={{color:props.defaultColor}}/></label>  
                         :
                            <></>
                        }
                     </td> 
                            
                )
            }
        },
        {
            name: 'Solicita',
            sortable: true,  
            cell: (row) =>{
                return(
                    <td title={row.name}>{(row.name).split(" ")[0]}</td>
                )
            } 
        },
        {
            name: 'Identificador',  
            sortable: true, 
            width: '250px',
            cell: (row) => {
                return (
                    <td title={(row.producto + ' ' + row.identificador) + '  ('+row.idrequisicion+')'} onClick={() => props.verReq(row.idrequisicion)}>
                        <td class="width-100" style={{width:'300px'}}>{(row.producto + ' ' + row.identificador).substring(0, 25) + '...' + '  ('+row.idrequisicion+')'} <input value={row.identificador} name="conceptodef" hidden></input></td>
                        <td  align='right'><FaExternalLinkSquareAlt style={{color:props.defaultColor}}/></td>
                    </td>
                )
            }
        },
        {
            name: 'Proveedor',   
            width: '250px',
            cell: (row) => {
                return (
                    <td title={row.proveedor}>{(row.proveedor).substring(0, 25)+'...'} <input value={row.proveedor} name="proveedordef" hidden></input></td>  
                )
            }
        },
        {
            name: 'Mensaje',
            cell: (row) => {
                return (
                    <td class="blink_me">{
                        (row.firmadosolicitado == "0" && row.estadoorden != "Cancelada") ? <label>{row.mensajesolicitado}</label> : <label></label> 
                        }{(row.firmadorevisado == "0" && row.estadoorden != "Cancelada") ? <label>{row.mensajerevisado}</label> : <label></label>
                        }{(row.firmadorealizado == "0" && row.estadoorden != "Cancelada") ? <label>{row.mensajerealizado}</label> : <label></label>
                        }{(row.firmadoautorizado == "0" && row.estadoorden != "Cancelada") ? <label>{row.mensajeautorizado}</label> : <label></label>
                         
                         }</td> 
                )
            }
        },
        {
            name: '',
            cell: (row) => {
                return (
                    <td align='center'>
                                    <button type="button" id={row.idrequisicion} className='btn btn-outline-success btn-sm' onClick={() => verOrden(row.idrequisicion, row.foliof)}>Ver</button>
                                </td>
                )
            } 
            
             
        },
        {
            name: 'Estado',
            cell: (row) => {
                return (
                    ((row.estadoorden == "Generada" || row.estadoorden == "Anticipo"|| row.estadoorden == "Surtida") && (props.tipo ==  "2") && (props.userid == row.usuariogenero)) ? 
                    <select id={'status'+row.ordencompra} name={row.estadoorden} onChange={() => checarAnticipo(row.ordencompra)}>
                    <option>{row.estadoorden}</option>
                    <option>Cancelada</option>
                    <option>Surtida</option>
                    <option>Pagada</option>
                    <option>Anticipo</option>
                    </select> :
                    <label>{row.estadoorden}</label>
                
                   
                )
            }
             
        },
        {
            name: 'Estatus Requisicion',
            width: '180px',
            cell: (row) => {
                return (
                    <td>
                                    
                        <label>{row.test}</label>

                    </td>
                )
            }
        },
        {
            name: 'Cotización',
            cell: (row) => {
                return (
                    (row.cotizacion == null) ? 
                                <td align='center'>
                                    <button value={row.idrequisicion} className='btn btn-outline-success btn-sm' onClick={()=>openModala1(row.idrequisicion)}>Añadir</button>
                                </td> :
                                <td align='center'> 
                                    <a target="_blank" href={process.env.REACT_APP_API_URL_COTIZACIONES+row.cotizacion} rel="noreferrer">Ver</a>
                                   <span>&nbsp; &nbsp; </span>
                                    <FaTrash class="color-red" onClick={() => removerCotizacion(row.idrequisicion)}/>
                                </td>
                )
            }
        },
        {
            name: 'Factura',
            width: '150px',
            cell: (row) => {
                return (
                    <th><input id={"Factura"+row.idorden}  defaultValue={row.factura} style={{width:'50px'}}/> <button onClick={() => actualizarFolioFactura(row.idorden)} className='btn btn-outline-success btn-sm'><FaSyncAlt/></button></th>    
                )
            }
        },
        {
            name: '',
            width: '10px',
            cell: (row) => {
                return (
                    <td hidden>
                    {(row.subtotal).toFixed(2)} 
                  </td>
                )
            }
        },
        {
            name: '',
            width: '10px',
            cell: (row) => {
                return (
                    <td hidden>
                    {formatNumber(row.descuento)} 
                   </td>
                )
            }
        },
        {
            name: '',
            width: '10px',
            cell: (row) => {
                return (
                    <td hidden>
                    {(((row.subtotal)*0.16).toFixed(2))} 
                </td>
                )
            }
        },
        {
            name: '',
            width: '10px',
            cell: (row) => {
                return (
                    <td hidden>
                    {formatNumber(row.ivaret)} 
                </td>
                )
            }
        },
        {
            name: '',
            width: '10px',
            cell: (row) => {
                return (
                    <td hidden>
                    {formatNumber(row.isrret)} 
                </td>
                )
            }
        },
        {
            name: '',
            width: '10px',
            cell: (row) => {
                return (
                    <td hidden>
                    {formatNumber(row.total)} 
                </td>
                )
            }
        },
        {
            name: '',
            width: '10px',
            cell: (row) => {
                return (
                    <td hidden>
                         <input id="idreq" value={row.idrequisicion} ></input>
                    </td>
                )
            }
        },
        {
            name: '',
            width: '10px',
            cell: (row) => {
                return (
                    <td hidden>
                    <input id="foliof" value={row.foliof} ></input>
                </td>
                )
            }
        },
    ];
    
    
    

  
    return(
        <div className="container "> 
            <Nabvar defaultColor={props.defaultColor} idd="navordenes" titulo="Ordenes de compra" departamento={props.rzonsocial} dptoid={props.dptoid}/> 

            <div className="row p-3">
                {/*<h3>Autorizar  </h3>
            */}
                <div>
                    <span>Fecha: &nbsp;</span>
                    <input  id='input-fecha' type='date' class="input-filter" onChange={() => getOrdenes()}></input>
                    <span>&nbsp;</span>
                    <input  id='input-fecha-termino' type='date' class="input-filter" onChange={() => getOrdenes()}></input>
                    <span>&nbsp; &nbsp; </span>
					<button class="btn btn-outline-success btn-sm" onClick={() => getOrdenes()}>Filtrar <FaSyncAlt/></button>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                     <div>
                        {/*onChange={() => getOrdenes()} */}
                    <span>Folio: &nbsp;&nbsp;</span> <br></br>
                    <input  id='input-folio' type='number' class="input-filter"  ></input>
                    <span>&nbsp;</span> <span>&nbsp;</span> 
					<button hidden class="btn btn-outline-success btn-sm" onClick={() => getOrdenes()}>Filtrar <FaSyncAlt/></button>
                    </div>
                    <div>
                    
                    <span>Proveedor: &nbsp;&nbsp;</span><br></br>
                    <input  id='input-proveedor' type='text' onKeyPress={handleKeyPress} onKeyUp={(e) => filtrarPorProveedor(e)} style={{width:'300px',fontSize:'12px', cursor:'pointer'}} ></input> <span>&nbsp;</span> <span>&nbsp;</span> <br></br>
                    <select class="slc-b"   id="buscar-pro" onChange={() => proveedorSelected()}>
                                          
                                            {listaProveedores.map(item => ( 
                                            <option  value={item.proveedor}> {item.proveedor}</option>))
                                            }   
                                        </select>
                    <span>&nbsp;</span> 

					<button hidden class="btn btn-outline-success btn-sm" onClick={() => getOrdenes()}>Filtrar <FaSyncAlt/></button>
                </div>  
              
                <div>
                    <span>Identificador: &nbsp;&nbsp;</span><br></br>
                    <input  id='input-identificador' type='text'   style={{width:'300px',fontSize:'12px', cursor:'pointer'}} ></input><span>&nbsp;</span> <span>&nbsp;</span> <br></br>
                    
                    <span>&nbsp;</span> 
 
                </div>
                <div>
                    <span>Solicita: &nbsp;&nbsp;</span><br></br>
                    <select  style={{width:'300px',fontSize:'12px', cursor:'pointer', height: '23px'}}  id="buscar-solicita" >
                    <option  value=""></option>
                                          {props.usuarios.map(item => ( 
                                          <option  value={item.userid}> {item.name}</option>))
                                          }   
                                      </select>
                    <span>&nbsp;</span> 
 
                </div>
                <div>
                    <span>Pendientes firma: &nbsp;&nbsp;</span><br></br>
                    <input type='checkbox' name="checkboxopf" id="chkboxfirma" ></input> 
 
                </div>
                <div>
                    <span>Pendientes impresión: &nbsp;&nbsp;</span><br></br>
                    <input type='checkbox' name="checkboxopi" id="checkboxopi" ></input> 
 
                </div>
               
                </div>
                
                {proveedorSugerido}
                <div  ref={tableRef} id="divtable-ordenes" style={{ minHeight:'15%', width:'100%'}}>
                <DataTableExtensions
                columns={columns}
                data={lista}
                print={true}
                export={true}
                filterPlaceholder={"Filtrar"}
                
                >
                    <DataTable
                                columns={columns}
                                data={lista}
                                fixedHeader={true}
                                fixedHeaderScrollHeight={'100%'}
                                pagination
                                customStyles={tableCustomStyles}
                                highlightOnHover={true} 
                                noDataComponent={"No hay registros para mostrar"}
                            />
                </DataTableExtensions>
                
                <table hidden id="productstable"  style={{overflowY: 'scroll', overflowX: 'scroll', width:'120%'}}  > 
                 
                

                  {/*  */}
                    <tr class="sticky-header" >
                        <th hidden></th>
                        <th></th>
                        <th></th>
                        <th>Fecha</th>
                        <th>Orden de Pago</th>
                        <th>Solicita</th>
                        <th>Identificador</th>
                        <th>Proveedor</th>
                        <th>Estado</th>
                        <th>Detalles</th> 
                        <th>Mensaje</th>
                        <th>Estado</th> 
                        <th>Cotización</th>
                        <th>Factura</th>
                        <th hidden></th>
                        <th hidden>Subtotal</th>
                        <th hidden>Descuento</th>
                        <th hidden>IVA</th>
                        <th hidden>IVA RET</th>
                        <th hidden>ISR RET</th>
                        <th hidden>Total</th>
                    </tr>

                    {lista.map(item => (    
                    <>
                        {(item.idsolicita1 == props.userid 
                        || item.idsolicita2 == props.userid 
                        || item.idrevisa1 == props.userid 
                        || item.idrevisa2 == props.userid 
                        || item.idrealiza1 == props.userid 
                        || item.idrealiza2 == props.userid 
                        || item.idautoriza1 == props.userid  
                        || item.idautoriza2 == props.userid 
                        || props.userid == item.usuariogenero
                        || (item.admindptodestino == "1")
                        || (props.superus == "1")
                        || ((props.tipo == "2") && (item.dptoidsolicita == props.userdptoid))
                        ) ? 
                        <tr class="font-14 table-row tabletr" >
                        <td hidden> <label class="add-bttn">+</label></td>
                                 {(item.ordenPagoMultiple == null) ?
                                <td class="width-2"> <input type='checkbox' name="checkboxop" value={item.importeTotal} id={item.foliof} onChange={() => checked()}></input> </td>
                                :
                                <td><input type='checkbox' name="checkboxop" value={item.importeTotal} id={item.foliof} onChange={() => checked()} hidden></input></td>
                                } 
                                <td className='id-orden' >{item.foliof}</td>
                                <td><input class="input-date" type="date" id={item.idorden} onChange={() => actualizarFecha(item.idorden, item.foliof)} value={(item.fechacaptura).substring(0,10)}></input></td>
                                {(item.ordenpago == 0)? 
                                <td align='left'> 
                                   <button value={item.ordencompra} className='btn btn-outline-success btn-sm' onClick={()=>openModalGenerar(item.ordencompra, item.foliof, item.importeTotal, item.proveedor, item.identificador)}>Generar</button>
                                   {(item.ordenPagoMultiple != null) ?
                                        <label>{item.ordenPagoMultiple} <FaTrash class="color-red"  onClick={() => quitarOrdenPago(item.ordencompra, item.foliof, item.ordenPagoMultiple)} /> <FaExternalLinkSquareAlt  onClick={() => props.verOrdenPago(item.ordenPagoMultiple, "")} style={{color:props.defaultColor}}/></label>  
                                     :
                                        <></> 
                                    }
                                </td>   :
                                <td align='left' id={"celdaOP"+item.foliof}>
                                 <button value={item.ordencompra} className='btn btn-outline-success btn-sm' onClick={()=>openModalGenerar(item.ordencompra, item.foliof, item.importeTotal, item.proveedor, item.identificador)}>Generar</button>
                                    {getArray(item.arrayID, item.foliof)} 
                                    {(item.ordenPagoMultiple != null) ?
                                        <label>{item.ordenPagoMultiple}<FaTrash class="color-red"  onClick={() => quitarOrdenPago(item.ordencompra, item.foliof, item.ordenPagoMultiple)} /> <FaExternalLinkSquareAlt  onClick={() => props.verOrdenPago(item.ordenPagoMultiple, "")} style={{color:props.defaultColor}}/></label>  
                                     :
                                        <></>
                                    }
                                 </td> 
                                }   
                                <td title={item.name}>{(item.name).split(" ")[0]}</td>
                                <td title={(item.producto + ' ' + item.identificador) + '  ('+item.idrequisicion+')'} onClick={() => props.verReq(item.idrequisicion)}>
                                <td class="width-100">{(item.producto + ' ' + item.identificador).substring(0, 25) + '...' + '  ('+item.idrequisicion+')'} <input value={item.identificador} name="conceptodef" hidden></input></td>
                                    <td   align='right'><FaExternalLinkSquareAlt style={{color:props.defaultColor}}/></td>
                                </td>
                                <td title={item.proveedor}>{(item.proveedor).substring(0, 25)+'...'} <input value={item.proveedor} name="proveedordef" hidden></input></td>  
                                  <td>{formatEstado(item.estado)}</td>   
                                  <td align='center'>
                                    <button type="button" id={item.idrequisicion} className='btn btn-outline-success btn-sm' onClick={() => verOrden(item.idrequisicion, item.foliof)}>Ver</button>
                                </td>
                                <td class="blink_me">{
                                (item.firmadosolicitado == "0" && item.estadoorden != "Cancelada") ? <label>{item.mensajesolicitado}</label> : <label></label> 
                                }{(item.firmadorevisado == "0" && item.estadoorden != "Cancelada") ? <label>{item.mensajerevisado}</label> : <label></label>
                                }{(item.firmadorealizado == "0" && item.estadoorden != "Cancelada") ? <label>{item.mensajerealizado}</label> : <label></label>
                                }{(item.firmadoautorizado == "0" && item.estadoorden != "Cancelada") ? <label>{item.mensajeautorizado}</label> : <label></label>
                                 
                                 }</td> 
                                
                                <td>
                                    {((item.estadoorden == "Pagada" || item.estadoorden == "Generada" || item.estadoorden == "Anticipo"|| item.estadoorden == "Surtida") && (props.tipo ==  "2") && (props.userid == item.usuariogenero)) ? 
                                        <select id={'status'+item.ordencompra} name={item.estadoorden} onChange={() => checarAnticipo(item.ordencompra)}>
                                        <option>{item.estadoorden}</option>
                                        <option>Cancelada</option>
                                        <option>Surtida</option>
                                        <option>Pagada</option>
                                        <option>Anticipo</option>
                                        </select> :
                                        <label>{item.estadoorden}</label>
                                    }
                                </td>
                                
                                
                                
                                {/*  COLUMNA DE COTIZACIONES  */}
                                {(item.cotizacion == null) ? 
                                <td align='center'>
                                    <button value={item.idrequisicion} className='btn btn-outline-success btn-sm' onClick={()=>openModala1(item.idrequisicion)}>Añadir</button>
                                </td> :
                                <td align='center'> 
                                    <a target="_blank" href={process.env.REACT_APP_API_URL_COTIZACIONES+item.cotizacion} rel="noreferrer">Ver</a>
                                   <span>&nbsp; &nbsp; </span>
                                    <FaTrash class="color-red" onClick={() => removerCotizacion(item.idrequisicion)}/>
                                </td>
                                }
                                <td hidden>
                                    {(item.subtotal).toFixed(2)} 
                                </td>
                                <td hidden>
                                    {item.descuento} 
                                </td>
                                <td hidden>
                                    {(((item.subtotal)*0.16).toFixed(2))} 
                                </td>
                                <td hidden>
                                    {item.ivaret} 
                                </td>
                                <td hidden>
                                   {item.isrret}
                                </td>
                                <td hidden>
                                    {item.total}  
                                </td>
                                <th><input id={"Factura"+item.idorden}  defaultValue={item.factura} style={{width:'50px'}}/> <button onClick={() => actualizarFolioFactura(item.idorden)} className='btn btn-outline-success btn-sm'><FaSyncAlt/></button></th>    
                                
                               
                                
                                <td>
                                    <input id="idreq" value={item.idrequisicion} hidden></input>
                                </td>
                                <td>
                                    <input id="foliof" value={item.foliof} hidden></input>
                                </td>

                                
                                
                            </tr>
                        : 
                        <></>
                         }
                   </>
                    ))
                    
                    }	
                    
                    <input id='input-cotizacion' type='file' style={{display:'none'}} onChange={()=> postFile()}></input>

                </table>

                
               
                </div>   
                <div style={{disply:'flex'}}>
                <button class="btn btn-outline-success btn-sm" style={{width:'100px'}} hidden onClick={onDownload}>Excel <FaFileExcel/></button>
                </div>
                {(importeAcumulado > 0) ? 
                    <button onClick={openModal21} value={importeAcumulado} className='btn btn-outline-success btn-sm' style={{width:'200px'}}>Generar orden ({formatNumber(importeAcumulado)})</button>
                :
                    <></>
                }
                <div style={{disply:'flex'}}>
                <label>IVA Fronterizo</label> <input type="checkbox" id="checkFronterizo" onChange={ivaFronterizo}></input>
                </div>
            <div id="div-orden-compra" class="margin-auto" style={{boxShadow: 'rgb(0 0 0 / 45%) 0px 5px 15px', borderRadius: '5px'}}> 
                <br></br>  <ProgressBar>
						<ProgressBar animated striped  variant="success" label={'Generada'} now={generada} key={1} />
						<ProgressBar animated striped variant="success" label={'Autorizada'} now={autorizada} key={2} />
						<ProgressBar animated striped variant="success" label={'Orden de pago generada'} now={conorden} key={3} />
						<ProgressBar animated striped variant="success" label={'Anticipo'} now={conanticipo} key={4} />
						<ProgressBar animated striped variant="success" label={'Pagada'} now={pagada} key={5} />
						<ProgressBar animated striped variant="success" label={'Surtida'} now={surtida} key={6} />
						<ProgressBar animated striped variant="danger" label={'Cancelada'} now={estadocancelada} key={7} />
    				</ProgressBar> <br></br> 
                    
                    <div id="pdf" class="div-section2">
                    <div id="VoidBox" ></div> 
                   
                        <br></br>
                        
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div class="div-section2">
                                <img id="logoOrden"  src={logo} alt="LOGO" style={{width: '160px', height: 'auto', paddingLeft:'25px'}}></img>
                                  <span><b class="font-25">&nbsp; &nbsp; &nbsp; &nbsp;ORDEN DE COMPRA</b></span>
                            </div>
            
                            <div class="div-section2">
                                <span><b class="font-30" id="rzonsocial"></b></span>
                                <span><b id="direccion"></b></span> 
                                <span><b>RFC: </b><b id="rfcempresa"></b></span>
                                <span><b>Tel. </b><b id="telempresa"></b></span>
                                
                            </div>

                            <div class="div-section2">
                                <table class="border-line"> 
                                    <tr align="center">
                                        <th class="cell-1">Folio</th> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <input id="folio" readOnly="readonly" class="input-1"></input>
                                            <input id="idrequisicion" readOnly="readonly" class="input-1"hidden></input>
                                        </td> 
                                          
                                    
                                    </tr>
                                </table>
                                <br></br>
                                <table class="border-line">
                                    <tr align="center">
                                        <th class="cell-1">Fecha</th> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <input id="fecha" readOnly='readonly' class="input-1"></input> 
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div> 
                        <br></br>  {/* 
                         */}

                        <table class="border-1">
                            <tr style={{backgroundColor: '#ddd'}}>
                                <th class="border-1" onClick={()=>verinfoProv()}>PROVEEDOR</th>
                                <th class="border-1">DEPARTAMENTO</th>
                            </tr>

                            <tr>
                                <td style={{border:'1px solid black', width:'663px', height:'28px'}}>
                                    <div class="select-editable">
                                      {/*   <select style={{height:'26px'}}  id="pro" onChange={() => (document.getElementById("orden-proveedor").value = document.getElementById("pro").value)}>
                                            <option value=""></option>
                                            {proveedor.map(item => ( 
                                            <option  value={item.proveedor}> {item.proveedor}</option>))
                                            }   
                                        </select>
                                        <br></br>
                                        
                                        <input id="orden-proveedor" type="text" name="format" style={{height:'auto'}}/>*/}
                                    </div>

                                    <input  name="proveedor1" id={"proveedor1oc"}  onKeyUp={() => filtrarPorProveedorFormatoOC()} class="inpt-nva-req"  style={{cursor:'pointer', border:'none'}}></input>
													<select  id={"pro1oc"} class="slc-b" onChange={() => proveedorSelectedFormatoOC()}>
														{proveedor.map(item => ( 
														<option  value={item.proveedor}> {item.proveedor}</option>))
														}   
													</select>
													<br></br> 
                                </td>

                                <td id="departamento" class="border-1">
                                

                                    <div class="select-editable1">
                                        <select style={{height:'26px'}} id="esta" onChange={() => (document.getElementById("orden-estacion").value = document.getElementById("esta").value)} >
                                           
                                            { dptos2.map(item => ( 
											<option value={item.name}>{item.name}</option>    
											))}  
                                        
                                        
                                        </select>
                                        <br></br>
                                        
                                        <input id="orden-estacion" type="text" name="format1" style={{height:'auto'}}/>
                                    </div>

                                    
                                </td>
                            </tr>

                            <tr style={{backgroundColor: '#ddd'}}>
                                <th class="border-1">SOLICITÓ</th>
                                <th class="border-1">ELABORÓ</th>
                            </tr>

                            <tr>
                                <td style={{border:'1px solid black'}}>
                                    <input id="departamentos" class="width-100"  style={{ backgroundColor:'white', border:'none'}} />
                                </td>
                                <td style={{border:'1px solid black'}}>
                                    <input id="usuario-elaboro" class="width-100"  style={{ backgroundColor:'white', border:'none'}} />
                                </td>
                            </tr>
                        </table>
                        <br></br> <br></br>  {/*
        */}
                        <table id='productosrows' class="border-1">
                            <tr style={{backgroundColor: '#ddd', height:'45px', border:'1px solid black'}} align="center">
                                <th class="border-1 w-20"  id="ivath">IVA</th>
                                <th class="border-1 w-110" >CANTIDAD</th>
                                <th class="border-1 w-110" >UNIDAD</th>
                                <th class="border-1">CONCEPTO</th>
                                <th class="border-1 w-120" >COSTO UNITARIO</th>
                                <th class="border-1 w-120" >TOTAL</th>
                            </tr>
                            <tr Name='rowprods' style={{height:'30px'}}>
                                <td align="center" class="border-1"><input type="checkbox" onChange={() => Change()} name="checkbox"/> </td>
                                <td class="border-1"> <input name="cantidad" readonly="readonly" style={{width:'95%', backgroundColor:'transparent', border:'none', textAlign:'center',height:'auto'}}/> </td>
                                <td class="border-1"> <input name="unidad" readonly="readonly" style={{width:'95%', backgroundColor:'transparent', border:'none', textAlign:'center',height:'auto'}}/> </td>
                                <td class="border-1"> <input name="producto" readonly="readonly" class="width-100" style={{ backgroundColor:'transparent', border:'none',height:'auto'}}/> </td>
                                <td class="border-1"> &nbsp;$<input name="costouni"    onChange={() => Change()} class="orden-input1"/> </td>
                                <td class="border-1"> &nbsp;<input name="total" readonly="readonly" class="orden-input1"/> </td>
                                <input name="folio" type='hidden'></input>
                            </tr>
                        </table>
                        
                        <div>
                            <label id="divdolares">Precio en dolares  &nbsp;</label>
                            <input type="checkbox" id="checkboxdolares"/> <br/>
                            <input type="text" id="checkdlls-valor" style={{height:'auto'}}/>
                        </div> 
                          <br/><br/>{/*
 */}
                        <table>
                            <tr class="orden-row">
                                <td colspan="3">-</td>
                                <td style={{width:'120px'}}>-</td>
                                <td style={{width:'120px', height:'30px', backgroundColor: '#ddd'}}>-</td>
                            </tr>
                            <tr class="orden-row-white">
                            <td colspan="3" rowspan="6" style={{backgroundColor: 'white'}}> <label id='obs1' style={{flex: '1', textAlign: 'left', display:'none', backgroundColor:'white', width:'1021px', wordWrap:'break-word', wordBreak:'break-all'}} >
                                    </label><textarea rows="6" id="observaciones" class="width-100" style={{  height:'100%', resize: 'none', border:'none', textAlign: 'justify', textJustify: 'inter-word'}}></textarea>
                               <br></br> <label id='fac1' hidden="hidden" style={{flex: '1', textAlign: 'left', display:'none', backgroundColor:'white'}} ></label>
                               <textarea hidden="hidden" rows="3" id="facturacion" class="width-100" style={{height:'100%', resize: 'none', border:'none'}} readonly="readonly"></textarea>
                                </td>
                                <td class="orden-cell1" align="center">DESCUENTO</td>
                                <td class="orden-cell2"> &nbsp;<input id='t-descuento' onChange={() => Change()} class="orden-input1"></input> </td>
                                
                            </tr>
                            
                             <tr class="orden-row-white" align="center"> 
                            <td class="orden-cell1"  align="center">SUBTOTAL</td>
                                <td class="orden-cell2"> &nbsp;<input readonly="readonly" id='t-subtotal' class="orden-input1"></input> </td>
                            </tr>
                            <tr class="orden-row-white" align="center"> 
                                <td class="orden-cell1">IVA</td>
                                <td class="orden-cell2"> &nbsp;<input readonly="readonly" id='t-iva' class="orden-input1"></input> </td>
                            </tr>
                            <tr class="orden-row-white" align="center"> 
                                <td class="orden-cell1">IVA RET</td>
                                <td class="orden-cell2"> &nbsp;<input onChange={() => onChange()}  id='t-ivaret' class="orden-input1"></input> </td>
                            </tr>
                            <tr class="orden-row-white" align="center"> 
                                <td class="orden-cell1">ISR RET</td>
                                <td class="orden-cell2"> &nbsp;<input onChange={() => onChange1()}   id='t-isrret' class="orden-input1"></input> </td>
                            </tr>
                            
                            <tr class="orden-row-white border-bottom1"   align="center"> 
                                <td class="orden-cell1">TOTAL</td>
                                <td class="orden-cell2"> &nbsp;<input readonly="readonly" id='t-total' class="orden-input1"></input> </td>
                            </tr>
                            <tr> 
                            </tr>
                            <tr align="right">
                                
                                <td colspan="5">
                                    <button type="button" id="botonRestablecer" className='btn btn-outline-danger btn-sm' style={{marginTop:'3px'}} onClick={() => openModalRes()} >Restablecer</button>
                                    <button type="button" id="boton" className='btn btn-outline-success btn-sm' style={{marginTop:'3px'}} onClick={() => guardarPrecios()}>Guardar</button>
                                </td>

                            </tr>
                        </table> <br/><br/> 
                        
                        <div style={{display: 'flex', gap: '15px', justifyContent: 'space-between'}}>
                            <div class="orden-firma" id="firma1" align="center">
                                <span><img id='firmasolicita' src="https://devapi.tech/apirest/default/b.jpg" alt='Firma' width="250" height='180' class="border-bottom1"></img></span><br></br>
                                <div onClick={() => Notificar("1")}><label id="solicita" ></label><FaBell id="notificar-icon-1"/></div>
                                <label id="solicita-tipo" hidden></label>
                                <br></br>
                                <button   className='btn btn-outline-success btn-sm' onClick={() => Autorizar("1")} id="bsolicita" class="orden-button btn btn-outline-success btn-sm" hidden>SOLICITO</button> 
                                <label id="lsolicita" class="names"  hidden>SOLICITÓ</label><br></br> 
                                <button class="btn btn-outline-danger btn-sm" id="revocarb1" hidden  onClick={(e) => Revocar(e)}>Remover firma</button>
                                <span>&nbsp;</span>
                            </div>
                            <div class="orden-firma" id="firma2" align="center">
                                <span><img id='firmarevisa' src="https://devapi.tech/apirest/default/b.jpg" alt='Firma'  width="250" height='180' class="border-bottom1"></img></span><br></br>
                                <div onClick={() => Notificar("2")}><label id="revisa" ></label><FaBell id="notificar-icon-2"/></div>
                                <label id="revisa-tipo" hidden></label>
                                <br></br>
                                <button className='btn btn-outline-success btn-sm' onClick={() => Autorizar("2")} id="brevisa" class="orden-button btn btn-outline-success btn-sm" hidden>REVISO</button> 
                                <label id="lrevisa" class="names"  hidden>REVISÓ</label> <br></br> 
                                <button class="btn btn-outline-danger btn-sm" id="revocarb2" hidden  onClick={(e) =>  Revocar(e)}>Remover firma</button>
                                <span>&nbsp;</span>
                            </div>
                            <div class="orden-firma" id="firma3" align="center">
                                <span><img id='firmarealiza' src="https://devapi.tech/apirest/default/b.jpg" alt='Firma' width="250" height='180' class="border-bottom1"></img></span> <br></br>
                                <div onClick={() => Notificar("3")}><label id='realiza' ></label><FaBell id="notificar-icon-3"/></div>
                                <label id="realiza-tipo" hidden></label>
                                <br></br>
                                <button className='btn btn-outline-success btn-sm' onClick={() => Autorizar("3")} id="brealiza" class="orden-button btn btn-outline-success btn-sm" hidden>REALIZO</button> 
                                <label id="lrealiza" class="names"   hidden>REVISÓ</label><br></br> 
                                <button class="btn btn-outline-danger btn-sm" id="revocarb3" hidden  onClick={(e) => Revocar(e)}>Remover firma</button>
                                <span>&nbsp;</span>
                            </div>
                            <div class="orden-firma" id="firma4" align="center">
                                <span><img id='firmaautoriza' src="https://devapi.tech/apirest/default/b.jpg" alt='Firma' width="250" height='180' class="border-bottom1"></img></span><br></br>
                                <div onClick={() => Notificar("4")}><label id='autoriza' ></label><FaBell id="notificar-icon-4"/></div>
                                <label id="autoriza-tipo" hidden></label>
                                <br></br>
                                <button   className='btn btn-outline-success btn-sm' onClick={() => Autorizar("4")} id="bautoriza" class="orden-button btn btn-outline-success btn-sm" hidden>AUTORIZO</button> 
                                <label id="lautoriza" class="names"  >AUTORIZÓ</label> <br></br> 
                                {/* Anteriormente lautoriza hidden */}
                                <button class="btn btn-outline-danger btn-sm" id="revocarb4" hidden  onClick={(e) =>  Revocar(e)}>Remover firma</button>
                                <span>&nbsp;</span>   
                            </div>
                        </div>
                        <br></br> 
                    <label id="noReimpresiones"></label> 
                    </div>
                    <br></br>  
                    <input type="checkbox" id="chbxVistaPrevia" onChange={()=> vistaPrevia()}></input><label>Vista previa</label>
                    
                    <br></br>  
                    <br></br>  
                    {
                    (impreso == "1") ? 
                    <button id="bnt-imprimir" className="btn btn-outline-success btn-sm" onClick={() => openModala()} >Reimprimir <FaPrint /></button> 
                    :<button id="bnt-imprimir" className="btn btn-outline-success btn-sm" onClick={(e) => actualizarEstadoImpresion(e)}  >Imprimir <FaPrint /></button> 
                    }
                    <br></br>
                    <br></br>
                   <button id="bnt-imprimir" className="btn btn-outline-success btn-sm" onClick={(e) => mostrarObservacionesf(e)}  >Observaciones</button> 
                   <br></br>
                   {
                    (mostrarObservaciones) ? 
                    <>
                        <table>
                            <tr><td colspan="3">Historial de reimpresiones</td ></tr>
                            <tr><th>Usuario</th>
                            <th>Fecha</th>
                            <th>Motivo</th>
                            </tr>
                        { historialImpresiones.map(item => ( 
                            <tr>
                                <td style={{border: '1px black solid', width: '350px'}}>{item.name}</td>    
                                <td style={{border: '1px black solid', width: '155px'}}>{item.fechacaptura}</td>    
                                <td style={{border: '1px black solid', width: '250px'}}>{item.motivo}</td>    
                            </tr>
                            ))
                            } 
                        </table>
                         <table>
                         <tr><td colspan="3">Historial de re-establecimientos</td ></tr>
                         <tr><th>Usuario</th>
                         <th>Fecha</th>
                         <th>Motivo</th>
                         </tr>
                     { historialRestablecer.map(item => ( 
                         <tr>
                             <td style={{border: '1px black solid', width: '350px'}}>{item.name}</td>    
                             <td style={{border: '1px black solid', width: '155px'}}>{item.fechacaptura}</td>    
                             <td style={{border: '1px black solid', width: '250px'}}>{item.motivo}</td>    
                         </tr>
                         ))
                         } 
                     </table>
                     </>
                    :
                    <></>
                   }
                    <script></script>
                    <br></br>
                    <br></br>
                </div>
            </div>


            <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label style={{color:'black'}} class="label-1">Ingrese Contraseña</label>
        <br></br>
        <br></br>
        <input style={{width: "100%",height:'auto'}}id="artnoc" type="password"></input>
        <br></br>
        <br></br>
  
<br></br>
		<button onClick={closeModal} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button onClick={() => CEU()} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>


       <Modal
        isOpen={modalIsOpenRes}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalRes}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label style={{color:'black'}} class="label-1">Ingrese Contraseña</label>
        <br></br>
        <br></br>
        <input style={{width: "100%",height:'auto'}}id="artnoc" type="password"></input>
        <br></br>
        <br></br>
  
<br></br>
		<button onClick={closeModal} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button onClick={() => CEUrestablecer()} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>



       <Modal
        isOpen={modalIsOpen1}
        onAfterOpen={afterOpenModal1}
        onRequestClose={closeModal1}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Seleccionar Cotización</label>
        <br></br> 
        <select class="width-100" id="slc-cotizacion"> 
            { proveedores.map(item => ( 
            (item.proveedor == "Saabwews") ? 
            <option>hgfd</option>:
            <option value={item.documento}> {item.proveedor + " (" + item.documento +")"}</option>
                
            ))
            } 
        </select>
        <br></br>
        <br></br>

        <button id="btnS" style={{display:"block"}}onClick={() => selCotizacion(document.getElementById("slc-cotizacion").value)} class="btn btn-outline-success btn-sm ">Aceptar</button>
            
        
     
        <br></br>
        <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Cargar Cotización</label>
      
<br></br>
            {}
		    <button onClick={() => addCotizacion()} class="btn btn-outline-success btn-sm ">&nbsp;Cargar&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       </Modal>
       <Modal
        isOpen={modalIsOpen3}
        onAfterOpen={afterOpenModal3}
        onRequestClose={closeModal3}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">DATOS DEL PROVEEDOR</label>
        
        <br></br>
        <br></br>
        { infoproveedores.map(item => (
        <div>
        <label>Proveedor: {item.proveedor}</label><br></br>
        <label>Descripción: {item.descripcion}</label><br></br>
        <label>Nombre: {item.nombre}</label><br></br>
        <label>Correo: {item.correo}</label><br></br>
        <label>Banco: {item.banco}</label><br></br>
        <label>Clabe: {item.clave}</label><br></br>
        </div>
         ))}
         
  
<br></br>
		<button onClick={closeModal3} class="btn btn-outline-danger btn-sm ">Cerrar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    
       </Modal>
       <Modal
        isOpen={modalIsOpenAbono}
        onAfterOpen={afterOpenModalAbono}
        onRequestClose={closeModalAbono}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <center><label ref={(_subtitle) => (subtitle = _subtitle)} style={{color:'red', fontSize:'32px'}}>Abono</label></center>
        <br></br> 
        $<input type='number' id='abono' textAlign="right" style={{height:'auto'}}></input>
        <br></br>
        <br></br>
        <center><button  class="btn btn-outline-success btn-sm" onClick={()=> abonar(ordenn2)}>&nbsp;Abonar&nbsp;</button></center>
       </Modal>
{/* 
Motivo para restablecer orden de compra 
guardar historial de restablecimiento 
*/}
       <Modal
        isOpen={modalIsOpenMotivo}
        onAfterOpen={afterOpenModalAbono}
        onRequestClose={closeModalMotivo}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label style={{ fontSize:'32px', color: 'black'}}>Motivo para restablecer:</label>
        <br></br> 
        <input type='text' id='inputmotivo' textAlign="right" style={{height:'auto', width:'500px'}}></input>
        <br></br>
        <br></br>
        <center><button  class="btn btn-outline-success btn-sm" onClick={()=> restablecerOrden()}>&nbsp;Restablecer&nbsp;</button></center>
       </Modal>



       <Modal
        isOpen={modalIsOpenMotivoRI}
        onAfterOpen={afterOpenModalAbono}
        onRequestClose={closeModalMotivoRI}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label style={{ fontSize:'32px', color: 'black'}}>Motivo para reimprimir:</label>
        <br></br> 
        <input type='text' id='inputmotivoreimpresion' textAlign="right" style={{height:'auto', width:'500px'}}></input>
        <br></br>
        <br></br>
        <center><button  class="btn btn-outline-success btn-sm" onClick={()=> reimpresion()}>&nbsp;Reimprimir&nbsp;</button></center>
       </Modal>



       {/* FECHA DE PAGO */}
       <Modal
        isOpen={modalIsOpenPagada}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalPagada}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <center><label ref={(_subtitle) => (subtitle = _subtitle)} style={{color:'red', fontSize:'32px'}}>Fecha de pago</label></center>
        <br></br> 
        <input type='date' id='fechadepago' textAlign="right" style={{height:'auto', width:'100%'}}></input>
        <br></br>
        <br></br>
        <center><button  class="btn btn-outline-success btn-sm" onClick={()=> guardarFechaDePago(ordennP)}>&nbsp;Guardar&nbsp;</button></center>
       </Modal>

       {/* Guardar producto en inventario */}
       <Modal
        isOpen={modalIsOpenInventario} 
        onRequestClose={closeModalInventario}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <center><label  style={{color:'black', fontSize:'32px'}}>Guardar refacción / servicio </label></center>
          <br></br>
          <label>Tipo</label> 
        <select id="tipo" style={{width:'100%', marginTop:'5px'}} onChange={(e) => setTipoFlotilla(e.target.value)}> 
            
                                <option value="">Seleccione</option> 
                                <option value="refaccion">Refacción</option> 
                                <option value="servicio">Servicio</option> 
                                <option value="accesorio">Accesorio</option> 
            
        </select>
          <br></br>
          {(tipoFlotilla == "refaccion")
          ?
          <>
          <label>Vehiculo</label>  
          <select id="vehiculoid" style={{width:'100%', marginTop:'5px'}} defaultValue={vehiculoDef} onChange={(e) => setVehiculoDef(e.target.value)}> 
          <option value="0">STOCK</option> 
                  {listav.map(item => (  
                                  <option value={item.vehiculoid}>{item.descripcion + " " + item.modelo + " " + item.numvehiculo }</option> 
              
                  ))} 
          </select>
          <br></br>
          <label>Producto</label> 
          <input type='text' id='inventarioproducto' defaultValue={inventarioProducto} textAlign="right" style={{height:'auto', width:'100%'}}></input>
          <br></br>
          <label>Descripción</label> 
          <input type='text' id='inventariodescripcion' defaultValue={inventarioDescripcion} textAlign="right" style={{height:'auto', width:'100%'}}></input>
          <br></br>
          <label>Proveedor</label> 
          <input type='text' id='inventarioproveedor' defaultValue={inventarioProveedor} textAlign="right" style={{height:'auto', width:'100%'}}></input>
          <br></br>
          <label>Fecha compra</label> 
          <input type='date' id='inventariofecha' defaultValue={inventarioFecha} textAlign="right" style={{height:'auto', width:'100%'}}></input>
          <br></br> 
          <label>Precio</label> 
          <input type='text' id='inventarioprecio' defaultValue={inventarioPrecio} textAlign="right" style={{height:'auto', width:'100%'}}></input>
          <br></br> </>:<></>}
          {(tipoFlotilla == "servicio")
            ?
            <>
            <label>Vehiculo</label> 
            <select id="vehiculoid" style={{width:'100%', marginTop:'5px'}} defaultValue={vehiculoDef} onChange={(e) => setVehiculoDef(e.target.value)}> 
            <option value="0">STOCK</option> 
                    {listav.map(item => (  
                                    <option value={item.vehiculoid}>{item.descripcion + " " + item.modelo + " " + item.numvehiculo }</option> 
                
                    ))} 
            </select>
            <br></br>
            <label>Servicio</label> 
            <input type='text' id='servicioproducto' defaultValue={inventarioProducto} textAlign="right" style={{height:'auto', width:'100%'}}></input>
            <br></br>
            <label>Descripción</label> 
            <input type='text' id='serviciodescripcion' defaultValue={inventarioDescripcion} textAlign="right" style={{height:'auto', width:'100%'}}></input>
            <br></br>
             
            <label>Fecha </label> 
            <input type='date' id='serviciofecha' defaultValue={inventarioFecha} textAlign="right" style={{height:'auto', width:'100%'}}></input>
            <br></br> 
            <label>Odometro</label> 
            <input type='text' id='servicioodometro'  defaultValue={odometroDef} textAlign="right" style={{height:'auto', width:'100%'}}></input>
            <br></br> <label>Precio</label> 
          <input type='text' id='servicioprecio' defaultValue={inventarioPrecio} textAlign="right" style={{height:'auto', width:'100%'}}></input>
           </>
            :
            <></>
            }

             

{(tipoFlotilla == "accesorio")
          ?
          <>
          <label>Vehiculo</label>  
          <select id="vehiculoid" style={{width:'100%', marginTop:'5px'}} defaultValue={vehiculoDef} onChange={(e) => setVehiculoDef(e.target.value)}> 
          <option value="0">STOCK</option> 
                  {listav.map(item => (  
                                  <option value={item.vehiculoid}>{item.descripcion + " " + item.modelo + " " + item.numvehiculo }</option> 
              
                  ))} 
          </select>
          <br></br>
          <label>Accesorio</label> 
          <input type='text' id='inventarioproducto' defaultValue={inventarioProducto} textAlign="right" style={{height:'auto', width:'100%'}}></input>
          <br></br>
          <label>Descripción</label> 
          <input type='text' id='inventariodescripcion' defaultValue={inventarioDescripcion} textAlign="right" style={{height:'auto', width:'100%'}}></input>
          <br></br>
          <label>Proveedor</label> 
          <input type='text' id='inventarioproveedor' defaultValue={inventarioProveedor} textAlign="right" style={{height:'auto', width:'100%'}}></input>
          <br></br>
          <label>Fecha compra</label> 
          <input type='date' id='inventariofecha' defaultValue={inventarioFecha} textAlign="right" style={{height:'auto', width:'100%'}}></input>
          <br></br> 
          <label>Precio</label> 
          <input type='text' id='inventarioprecio' defaultValue={inventarioPrecio} textAlign="right" style={{height:'auto', width:'100%'}}></input>
          <br></br> </>:<></>}
         
          
         
        <br></br>
        <center><button  class="btn btn-outline-success btn-sm" onClick={()=> guardarInventario()}>&nbsp;Guardar&nbsp;</button></center>
       
       </Modal>

       <Modal
        isOpen={modalIsOpenGenerar}
        onAfterOpen={afterOpenModalGenerar}
        onRequestClose={closeModalGenerar}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label   class="label-1">Seleccionar Opción</label>
        <br></br> 
        <label>Tipo de orden de pago:</label><br></br>
        <select style={{width: "300px"}} onChange={selTipoOrden1} id="slc-orden"> 
        <option value='0'>Seleccione</option>
        <option value='1'>ORDEN DE PAGO</option>
        <option value='2'>ORDEN DE PAGO CON TDC EMPRESARIAL</option>
        </select>
        <br></br>
        <label hidden>Importe:</label> 
        <input hidden style={{width: "300px"}}  defaultValue={importeTotal} id="importeTotalNvaOC"></input><br></br>
       
        <div id='nvaorden11' hidden>
                    <NvaOrdenpago proveedor={proveedor} proveedorSelected={proveedorSelected1} filtrarPorProveedor={filtrarPorProveedor1} rzonsocialEmpresa={props.rzonsocial} bancos={props.bancos} tarjetas={props.tarjetas} departamento={props.departamento}  usuarios={props.usuarios} logoOP={logoOP}/>            
                   
        </div>
                    
        <div id='nvaorden22' hidden>
                    <NvaOrdenpago1 proveedor={proveedor} proveedorSelected={proveedorSelected1} filtrarPorProveedor={filtrarPorProveedor1} rzonsocialEmpresa={props.rzonsocial} bancos={props.bancos} tarjetas={props.tarjetas} departamento={props.departamento}  usuarios={props.usuarios} logoOP={logoOP}/>
                   
        </div>
        <br></br>
         
        <button id="btnS" style={{display:"block"}}onClick={() => generarOrdenPago(folioempresa, ordenn,document.getElementById("slc-orden").value)} class="btn btn-outline-success btn-sm ">Aceptar</button>
            
    
       </Modal>

       <Modal
        isOpen={modalIsOpenGenerar1}
        onAfterOpen={afterOpenModalGenerar}
        onRequestClose={closeModalGenerar1}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Seleccionar Opción</label>
        <br></br> 
        <label>Tipo de orden de pago:</label><br></br>
        <select style={{width: "300px"}} id="slc-orden1" onChange={selTipoOrden}> 
        <option value='0'>Seleccione</option>
        <option value='1'>ORDEN DE PAGO</option>
        <option value='2'>ORDEN DE PAGO CON TDC EMPRESARIAL</option>
        </select>
        <br></br>
         
        <div id='nvaorden1' hidden>
                    <NvaOrdenpago proveedor={proveedor} proveedorSelected={proveedorSelected1} filtrarPorProveedor={filtrarPorProveedor1} rzonsocialEmpresa={props.rzonsocial} bancos={props.bancos} tarjetas={props.tarjetas} departamento={props.departamento}  usuarios={props.usuarios} logoOP={logoOP}/>            
                   
        </div>
                    
        <div id='nvaorden2' hidden>
                    <NvaOrdenpago1 proveedor={proveedor} proveedorSelected={proveedorSelected1} filtrarPorProveedor={filtrarPorProveedor1} rzonsocialEmpresa={props.rzonsocial} bancos={props.bancos} tarjetas={props.tarjetas} departamento={props.departamento}  usuarios={props.usuarios} logoOP={logoOP}/>
                   
        </div>
        <br></br>
         
           
        <button id="btnS" style={{display:"block"}}onClick={() => generarOrdenPago1(document.getElementById("slc-orden1").value)} class="btn btn-outline-success btn-sm ">Aceptar</button>
            
    
       </Modal>


       <ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    
 		
       
       <Toast/>    
        
        </div>
    );   
}

export default Ordenes;
