import React  from 'react'; 
import App from '../App';   
import LoginForm from './loginform';
import HttpsRedirect from 'react-https-redirect';
class Login extends React.Component {
    constructor(props) {
        super(props);  
        this.state = {isLoggedIn: false};
	}

	render(){ 
		const isLoggedIn = this.state.isLoggedIn;
		//console.log(this.state);
		 
			return ( 
				  
					 <LoginForm isLoggedIn={false} /> 
				   
		  ); 
		 
	}
}

export  default Login;