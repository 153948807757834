import React,{useState, useEffect} from 'react';  
export default function Ordenpago(props){
    const styles = `
        #div-ordenpago1{
            display: flex;
            flex-direction: column;
            width: auto;
            padding: 2vw;
            background-color: white;
            border: 2px solid black; 
            border-radius: 3px;
        }
        .underlined{
            border: none;
            border-bottom: 1px solid black;
        }
        #header-op{
            display: flex;
            margin-bottom: 1.5vmax;
        }
        #header-logo{
            width: 5vmax;
        }
        #header-labels{
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #tbl-ordenpago{
            width: match-parent;
        }
        #firstcolumn{
            width: 15%;
        }
        #secondcolumn{
            width: 35%;
        }
        #thirdcolumn{
            width: 14%;
        }
        #fourthcolumn{
            width: 35%;
        }
        #footer-op{
            display: flex;
            margin-top: 1.5vmax;
            justify-content: space-between;
        }
        #footer-solicito{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-reviso{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-reviso1{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-autorizo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-op label{
            font-weight: bold;
        }
        #tbl-ordenpago input{
            width: 100%;
            border: none;
        }
        #tbl-ordenpago label{
            font-weight: bold;
        }
        .labeltoright{
            float: right;
        }
        #header-empresa{
            font-weight: bold;
            font-size: 20px;
        }
        #header-rzonsocial1{
            font-weight: bold;
            font-size: 25px;
        }
    `;
    const [tarjetas1, setTarjetas1] = useState(props.tarjetas);
    const [tarjetas2, setTarjetas2] = useState(props.tarjetas);
    useEffect(() =>{
        //console.log("tarjetas1:"+props.tarjetas); 
    } 
    , [])
    function filtraTarjetas(folio){
         
		var result = props.tarjetas.filter((x) => (x.foliobanco == folio)); 
        //console.log("res:"+result); 
		setTarjetas1(result);
        //var selbanco = "";
	
    }
    return(
        <div id="div-ordenpago1">
            <style>
                {styles}
            </style>

            <div id="header-op">
                
                <img src={props.logoOP} alt="LOGO" style={{width: '160px', height: 'auto', paddingLeft:'25px'}}></img>
                <div id="header-labels">
                <span id="header-rzonsocial1"></span>
                    <span id="header-empresa">{props.departamento}</span>
                    <span>ORDEN DE PAGO CON TDC EMPRESARIAL</span>
                    <span id="header-categoria1"></span>
                </div>
                <div id="ordenN" style={{border: '1px solid black',padding:'5px'}}>
                    <center><span id="header-"><b>ORDEN</b></span></center>
                    
                    
                    <tr>
                    <td id="thirdcolumn">
                        <label ><b>N.</b></label>
                    </td>
                    <td  className="underlined">
                        <input id="op-numeroN" disabled class="inp-border-n" ></input>
                    </td>
                     </tr>
                     <tr>
                    <td id="firstcolumn">
                        <label><b>FECHA&nbsp;</b></label>
                    </td>
                    <td  className="underlined"> 
                        <input id="op-fechaN" disabled class="inp-border-n" ></input>
                    </td>
                    
                </tr>
                        
                </div>
            </div>

            <table id="tbl-ordenpago">
            <tbody>
                <tr>
                <td id="firstcolumn">
                        <label>BANCO:</label>
                    </td>
                    <td id="secondcolumn" className="underlined"> 
                    <select  id="op-bancoretiroN" onChange={(e) => filtraTarjetas(e.target.value)}  class="slc-tarjeta-op">
											{ props.bancos.map(item => ( 
											<option value={item.folio}> {item.nombrebanco}</option>    
											))}  
										</select>   
                    </td>
                    <td id="firstcolumn">
                        <label className="labeltoright" id="lblcompra">COMPRA &nbsp; #</label>
                    </td>
                    <td id="secondcolumn" className="underlined">
                        <input id="op-folio-orden-compraN"  onClick={() => props.verOrden1(2)}></input>
                         
                            <input id="op-folio-orden-requisicionN" hidden></input>
                    </td>
                   
                </tr>

                <tr>
                    
                    <td id="firstcolumn">
                        <label>TARJETA</label>
                    </td>
                    <td id="secondcolumn" className="underlined"> 
                    <select id="op-tarjeta"  class="slc-tarjeta-op">
											{  tarjetas1.map(item => ( 
                                                <option value={item.folio}> {item.notarjeta}</option>    
                                                ))  
                                            }
                                            
										</select>  
                        {/*<input id="op-tarjeta" ></input> */}
                    </td>
                    <td id="firstcolumn">
                        <label className="labeltoright">CANTIDAD &nbsp; </label>
                    </td>
                    <td id="secondcolumn" className="underlined">
                        <input id="op-cantidadN"  type='text'></input>
                    </td>
                </tr>
                

               <br></br>
                

                
               <tr>
                    <td>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="op-importeletraN" textAlign="center" class="txtCenter"></input>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>PROVEEDOR:</label>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="op-anombreN" onKeyUp={() => props.filtrarPorProveedor("op-anombreN-sel", "op-anombreN")} style={{width:'97.5%', height:'auto', cursor:'pointer'}}></input>
                        <select  id="op-anombreN-sel" class="slc-b" onChange={() => props.proveedorSelected("op-anombreN-sel", "op-anombreN")}>
                                      
                        {props.proveedor.map(item => ( 
                                        ((item.proveedor != item.nombre) && (item.nombre != ""))
                                        ?
                                        <option  value={item.nombre}>{item.proveedor}</option>
                                        :
                                      <option  value={item.proveedor}>{item.proveedor}</option>
                                      ))
                                      }   
                                  </select>
                    </td>
                </tr>

                <tr>
                    <td>
                        <label>CONCEPTO:</label>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="op-conceptoN"></input>
                    </td>
                    
                </tr>
                <tr>
                
                </tr>
                {(props.empresaid == "12") 
                ?
                <tr>
                <td>
                    <label>CLIENTE:</label>
                </td>
                <td className="underlined">
                    <span> <select   id="op-clienteN" class="slc-tarjeta-op">
                        <option>PETROMAR DEL PACIFICO SA DE CV</option>
                        <option>SERVICIO RIO PRESIDIO SA DE CV</option>
                        <option>MARIA CELIDA URIAS BELTRAN</option>
                        <option>SERVICIO DEL ROSARIO SA DE CV</option>
                        <option>ACCESORIOS Y REFACCIONES BEGCAL SA DE CV</option>
                        <option>SERVICIO EL RECREO SA DE CV</option>
                        <option>SERVICIO LERMA DEL PACIFICO SA DE CV</option>
                        <option>SERVICIO IQ DEL VALLE SA DE CV</option>
                        <option>GRUPO PRO-ENERGETICOS OIL COMPANIES SA DE CV</option>
                        <option>PRO-ENERGETICOS, COMBUSTIBLES Y SERVICIOS A LA INDUSTRIA SA DE CV</option>
                        <option>PETROLAND SA DE CV</option>
                        <option>SUPER AMIGO PETRO S DE RL DE CV</option>
                        <option>EXACTO COMBUSTIBLES, SA DE CV</option>
                        <option>PROMOTORA LIO, SA DE CV</option>
                        </select></span>
                </td>
                <td>
                    <label className="labeltoright">PEDIDO: &nbsp; </label>
                </td>
                <td className="underlined">
                    <span><input type='text' id="op-pedidoN" ></input></span>
                </td>
                 </tr>
                :
                <>
                 <tr hidden>
                <td>
                    <label>CLIENTE:</label>
                </td>
                <td className="underlined">
                    <span>
                    <select   id="op-clienteN" >
                        <option>PETROMAR DEL PACIFICO SA DE CV</option>
                        <option>SERVICIO RIO PRESIDIO SA DE CV</option>
                        <option>MARIA CELIDA URIAS BELTRAN</option>
                        <option>SERVICIO DEL ROSARIO SA DE CV</option>
                        <option>ACCESORIOS Y REFACCIONES BEGCAL SA DE CV</option>
                        <option>SERVICIO EL RECREO SA DE CV</option>
                        <option>SERVICIO LERMA DEL PACIFICO SA DE CV</option>
                        <option>SERVICIO IQ DEL VALLE SA DE CV</option>
                        <option>GRUPO PRO-ENERGETICOS OIL COMPANIES SA DE CV</option>
                        <option>PRO-ENERGETICOS, COMBUSTIBLES Y SERVICIOS A LA INDUSTRIA SA DE CV</option>
                        <option>PETROLAND SA DE CV</option>
                        <option>SUPER AMIGO PETRO S DE RL DE CV</option>
                        <option>EXACTO COMBUSTIBLES, SA DE CV</option>
                        <option>PROMOTORA LIO, SA DE CV</option>
                        </select>
                        </span>
                </td>
                <td>
                    <label className="labeltoright">PEDIDO: &nbsp; </label>
                </td>
                <td className="underlined">
                    <span><input type='text' id="op-pedidoN" ></input></span>
                </td>
                 </tr>
                </>
                 }
                </tbody>
            </table>
            <br></br>     
            <div id="footer-op">
                <div id="footer-solicito-1" class="firma-orden-pago">
                <span><img id='firmasolicita2'  alt='Firma' width="250" height='180' ></img></span><br></br>
                <select id="firmas-solicitoN" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                     
                    <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("100", "2")} id="bsolicita2"   hidden>SOLICITO</button> 
                    <label id="lblsolicitoN">SOLICITÓ</label>
                </div>
                <div id="footer-reviso-1" class="firma-orden-pago" >
                <span><img id='firmarevisa2'  alt='Firma' width="250" height='180' ></img></span><br></br>
                <select id="firmas-revisoN" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                  
                <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("200", "2")} id="brevisa2"   hidden>REVISO</button> 
                <label id="lblrevisoN">REVISÓ</label>
                </div>
                {(props.empresaid == "12")? 
                <div id="footer-reviso1-1" class="firma-orden-pago" hidden>
                <span><img id='firmarevisa12'  alt='Firma' width="250px" height='180px' ></img></span><br></br>
                <select id="firmas-revisoN1" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                 
                <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("300", "2")} id="brevisa12"   hidden>REVISO</button> 
                <label id="lblrevisoN1">REVISÓ</label>
                </div>
                
                :
                <div id="footer-reviso1-1" class="firma-orden-pago" >
                <span><img id='firmarevisa12'  alt='Firma' width="250px" height='180px' ></img></span><br></br>
                <select id="firmas-revisoN1" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                 
                <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("300", "2")} id="brevisa12"   hidden>REVISO</button> 
                <label id="lblrevisoN1">REVISÓ</label>
                </div>
                
                }
                
                <div id="footer-autorizo-1" class="firma-orden-pago">
                <span><img id='firmaautoriza2'  alt='Firma' width="250" height='180' ></img></span><br></br>
                <select id="firmas-autorizoN" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                  
                <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("400", "2")} id="bautoriza2"   hidden>AUTORIZO</button> 
                <label id="lblautorizoN">AUTORIZÓ</label>
                </div>
            </div>
            {(props.empresaid == "20")
            ?<div id="div-ausencia1">
            <span>En ausencia</span>
            <div id="footer-op">
            
                <div id="footer-solicito"  class="firma-orden-pago1">
                                         <select id="firmas-solicitoN-au" class="firma-select">
                                        <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   
                 <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("1000", "2")} id="bsolicita2au"   hidden>SOLICITO</button> 
                <label>SOLICITÓ</label>
                </div>
                <div id="footer-reviso" class="firma-orden-pago1">
                  <select id="firmas-revisoN-au" class="firma-select">
                    <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                      
                   <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("2000", "2")} id="brevisa2au"   hidden>REVISO</button> 
                   <label>REVISÓ</label>
                </div>
                <div id="footer-reviso1" class="firma-orden-pago1" >
                 <select id="firmas-revisoN1-au" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   
                   <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("3000", "2")} id="brevisa12au"  hidden>REVISO</button> 
                   <label>REVISÓ</label>
                </div> 
                <div id="footer-autorizo" class="firma-orden-pago1">
                <select id="firmas-autorizoN-au" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   
                   <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("4000", "2")} id="bautoriza2au"   hidden>AUTORIZO</button> 
                   <label>AUTORIZÓ</label>
                </div>
            </div>
                </div>
            :
            <></>
            }
        </div>
    )
}