 
import React,{useState, useEffect} from 'react'; 
import  {FaCheckCircle, FaTrash, FaEdit} from 'react-icons/fa'
import axios from '../node_modules/axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import {Nabvar} from './component/Navbar'; 
import {ModalCarga, Toast} from './component/modalCarga'; 
import customStyles from './component/modalCustomStyles';
import './App.css'; 
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";  
 
function Proveedores(props) { 
let subtitle;
const [modalIsOpen, setIsOpen] = React.useState(false);
const [modalIsOpenP, setIsOpenP] = React.useState(false);
const [modalIsOpenU, setIsOpenU] = React.useState(false);
function notify(message){
  toast(message);
}
 

function openModalp() {
  setIsOpenP(true);
  }

function openModalU() {
    setIsOpenU(true);
    }
  
function afterOpenModal() {
// references are now sync'd and can be accessed.
subtitle.style.color = '#f00';
} 
function closeModal() {
setIsOpen(false);
}
function closeModalP() {
  setIsOpenP(false);
}

function closeModalU() {
    setIsOpenU(false);
}

 
function openModalP(idproveedor, accionR){
  openModalp();
  setIdProveedor(idproveedor);
  setAccion(accionR);

}

  

     const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);

    
     function closeModalLoad() { 
    setIsOpenLoad(false); 
     }

 

 
    const [idproveedor, setIdProveedor] =  useState([]); 
    const [accion, setAccion] =  useState([]);   
    const [listaP, setListaP] =  useState([]);   
    const [descripcionproveedor, setDescripcionProveedor] = useState([]); 

	useEffect(()=> { 
    getProveedores() 
	}, [])

 
  
  
	async function getProveedores(){
		var id = "getProveedoress";
    setIsOpenLoad(true);
		const res = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);
    closeModalLoad();
		setListaP(res.data); 
	}

	async function addProveedor(e) {
		e.preventDefault(); 
		var inputpro = document.getElementById("nombreproveedor");
		var descpro = document.getElementById("descproveedor");
    var banco = document.getElementById("banco");
    var clabe = document.getElementById("clabe");
    var correo = document.getElementById("correo");
    var nombre = document.getElementById("nombre1");
    var expReg = /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    var validar = expReg.test(correo.value);
  //  if(validar==true){}else{return notify("El correo NO es valido")}

		if(inputpro.value != ""){
			let fd = new FormData() 
			fd.append("id", "insertProveedor")
			fd.append("proveedor", inputpro.value)
			fd.append("descripcion", descripcionproveedor) 
      fd.append("banco", banco.value ) 
      fd.append("clabe", clabe.value) 
      fd.append("correo", correo.value) 
      fd.append("nombre", nombre.value) 
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			//getImagenes();
			notify(res.data.msg);
			inputpro.value = null;
			descpro.value = null;
      banco.value = null;
      nombre.value = null;
      clabe.value = null;
      correo.value = null;
      getProveedores();
		} else {
			notify("Capture el proveedor para continuar");
		}
	}
 
   


 

  async function CEU(){
    
    
      let contra = document.getElementById("artnoc").value;
      
      
      let fd1 = new FormData() 
      fd1.append("id","C1") 
      fd1.append("CT",contra) 
      fd1.append("userid",props.userid) 
			const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
    
      if(res1.data==1){
 
        closeModal();
      }else{
        closeModal();
        return notify("CONTRASEÑA INCORRECTA");
        
      }
  } 

  async function CEUProveedor(a, accionRe){
    
    
    let contra = document.getElementById("artnocproveedor").value;
    
    
    let fd1 = new FormData() 
    fd1.append("id","C1") 
    fd1.append("CT",contra) 
    fd1.append("userid",props.userid) 
    const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
  
    if(res1.data==1){
      if(accionRe == "editarProveedor"){
        editProveedor(a);
      }else if(accionRe = "bajaProveedor"){
        bajaProveedor(a);
      }
      
      closeModal();
    }else{
      closeModal();
      return notify("CONTRASEÑA INCORRECTA");
      
    }
}
  
  async function CEUusuario(a){
    
    
    let contra = document.getElementById("artnocusuario").value;
    
    
    let fd1 = new FormData() 
    fd1.append("id","C1") 
    fd1.append("CT",contra) 
    fd1.append("userid",props.userid) 
    const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
  
    if(res1.data==1){
 
      closeModalU();
    }else{
      closeModalU();
      return notify("CONTRASEÑA INCORRECTA");
      
    }
} 
 
  async function bajaProveedor(id){

		if(window.confirm('¿Eliminar proveedor?')){

        let fd = new FormData()    
			fd.append("id", "bajaProveedor")
			fd.append("idproveedor", id)  
			
			const res = await axios.post(process.env.REACT_APP_API_URL, fd);
			getProveedores(); 
			
			notify(res.data.trim());
      
			
		} 
	}


  async function editProveedor(id){

		if(window.confirm('¿Actualizar proveedor?')){

        let fd = new FormData() 
			var proveedor = document.getElementById("proveedor"+id).value;
			var descripcion = document.getElementById("descripcion"+id).value;
			var nombre = document.getElementById("nombre"+id).value; 
			var banco = document.getElementById("banco"+id).value; 
			var clave = document.getElementById("clabe"+id).value; 
			var correo = document.getElementById("correo"+id).value;   

			fd.append("id", "actualizarProveedor")
			fd.append("idproveedor", id) 
			fd.append("proveedor", proveedor) 
			fd.append("descripcion", descripcion) 
			fd.append("nombre", nombre) 
			fd.append("banco", banco)  
			fd.append("clave", clave)  
			fd.append("correo", correo) 
			
			const res = await axios.post(process.env.REACT_APP_API_URL, fd);
			getProveedores(); 
			
			notify(res.data.trim());
      
			
		} 
	}

  	return (
		<div className="container ">
		 
      


<Nabvar defaultColor={props.defaultColor} titulo="Proveedores" departamento={props.rzonsocial} dptoid={props.dptoid}/>    
 			  
    <div className="row p-3">

       <div className="col-md-5 p-2 ">
         <form className="card p-2 mt-2 shadowBox" encType="multipart/form-data">
           <h5>Nuevo Proveedor</h5>
            
           <br></br> 
           <input id='nombreproveedor' placeholder="Proveedor">
           
            </input> 
            <br></br> 
           <input id='nombre1' placeholder="Nombre" type="text">
           
            </input> 
            <br></br> 
           <input id='banco' placeholder="Banco" type="text">
           
            </input> 
            <br></br> 
           <input id='clabe' placeholder="Clabe" type="number">
           
            </input> 
            <br></br> 
           <input id='correo' placeholder="Correo" type="text">
           
            </input> 

      
           <br></br> 
           <textarea id="descproveedor" cols="4" placeholder="Descripcion" className="form-control" 
           onChange={(e) => setDescripcionProveedor(e.target.value)} >
           </textarea>
           <br></br> 
             
              <button  className="btn btn-outline-success btn-sm" 
                onClick={(e) => addProveedor(e)} >Agregar <FaCheckCircle /></button> 
         </form>
       </div>

       <div className="col-md-7 p-2" style={{maxHeight:'42vmax', overflowY: 'scroll'}} >
       { listaP.map(item => (
            <div className="card p-2 mt-2 shadowBox" key={item.proveedor} >
              <div className="card-body" >
                <b><label>Proveedor:&nbsp;</label></b> 
                <br></br>
               <input  defaultValue={item.proveedor} class="width-100" id={"proveedor"+item.id}></input><br></br>
               <b><label>Descripcion:&nbsp;</label></b> 
               <br></br>
               <input defaultValue={item.descripcion} class="width-100" id={"descripcion"+item.id}></input><br></br>
               <b><label>Nombre:&nbsp;</label></b> 
               <input defaultValue={item.nombre} class="width-100" id={"nombre"+item.id}></input><br></br>
               <b><label>Correo:&nbsp;</label></b> 
               <input defaultValue={item.correo} class="width-100" id={"correo"+item.id}></input><br></br>
               <b><label>Banco:&nbsp;</label></b> 
               <input defaultValue={item.banco} class="width-100" id={"banco"+item.id}></input><br></br>
               <b><label>Clabe:&nbsp;</label></b> 
               <input defaultValue={item.clave} class="width-100" id={"clabe"+item.id}></input><br></br> 
                    <div className="d-flex flex-row-reverse" >  
                       <button onClick={() => openModalP(item.id, "bajaProveedor")} className="btn btn-outline-danger btn-eliminar "
                      ><FaTrash /> </button> 
                       <label>&nbsp;&nbsp;</label>
                       <button className="btn btn-outline-success btn-sm" 
                           onClick={() => openModalP(item.id, "editarProveedor")} ><FaEdit />
                           </button> 
                     </div>  
                      
                  </div> 
              </div>         
            ))}  
     </div>

     </div>

     <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
         <h3>Editar usuario</h3>
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Ingrese Contraseña</label>
        <br></br>
        <br></br>
        <input class="width-100" id="artnoc" type="password"></input>
        <br></br>
        <br></br>
  
<br></br>
		<button onClick={closeModal} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button onClick={() => CEU()} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>

       <Modal
        isOpen={modalIsOpenP}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalP}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Ingrese Contraseña</label>
        <br></br>
        <br></br>
        <input class="width-100" id="artnocproveedor" type="password"></input>
        <br></br>
        <br></br>
        <br></br>
		    <button onClick={closeModalP} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button onClick={() => CEUProveedor(idproveedor, accion)} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>
        
       <Modal
        isOpen={modalIsOpenU}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalU}
        style={customStyles}
        contentLabel="Example Modal">
        <h3>Eliminar usuario</h3>
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Ingrese Contraseña</label>
        <br></br>   <br></br>
        <input class="width-100" id="artnocusuario" type="password"></input>
        <br></br>    <br></br>     <br></br>
		    <button onClick={closeModalU} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button onClick={() => CEUusuario()} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>


        <ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    
 		

       
        <Toast/>    

      </div>
 
  );   
}



 



export default Proveedores;
