import React from 'react';
import Modal from 'react-modal';
import customStyles from './modalCustomStyles';
import { TailSpin, ProgressBar,  InfinitySpin, ThreeDots, Vortex, Dna, Circles, Oval, Puff, Watch, MutatingDots, Rings, LineWave, MagnifyingGlass  } from  'react-loader-spinner'   
import { ToastContainer } from 'react-toastify'; 
export  const ModalCarga = (props) =>(
<Modal 
        isOpen={props.modalIsOpenLoad}  
        onRequestClose={props.closeModalLoad}   
        style={customStyles}
        ariaHideApp={false}> 
        
        <div class="width-100">  
       
     <TailSpin color={props.defaultColor} height={80} width={80} /> 

         {/* 

 
         <div class="tree">

        <svg viewBox="0 0 120 140">
            <text x="20" y="130" class="text" font-weight="normal" font-size="10"></text>
            <polygon class="p" fill="none" stroke-width="1" points="60,10 10,110 110,110 "/>
            <circle class="c c1"cx="30" cy="100" r="4" fill="none" stroke-width="1"/>
            <circle class="c c2" cx="65" cy="40" r="4" fill="none" stroke-width="1"/>
            <circle class="c c3" cx="90" cy="90" r="4" fill="none" stroke-width="1"/>
            <circle class="c c4" cx="50" cy="60" r="4" fill="none" stroke-width="1"/>
            <circle class="c c5" cx="69" cy="102" r="4" fill="none" stroke-width="1"/>
            <circle class="c c6" cx="45" cy="80" r="4" fill="none" stroke-width="1"/>
            <circle class="c c7" cx="75" cy="70" r="4" fill="none" stroke-width="1"/>
        </svg>

    </div>*/}
        </div>  
        </Modal>


)
export  const Toast = (props) =>(

<ToastContainer 
        progressClassName="toastProgress"
        position="top-center"
        />

)
