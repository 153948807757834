export default function Ordenpago(props){
    const styles = `
        #div-ordenpago{
            display: flex;
            flex-direction: column;
            width: auto;
            padding: 2vw;
            border: 2px solid black;
            background-color: white; 
            border-radius: 3px;
            color: black;
        }
        .underlined{
            border: none;
            border-bottom: 1px solid black;
        }
        #header-op{
            display: flex;
            margin-bottom: 1.5vmax;
        }
        #header-logo{
            width: 5vmax;
        }
        #header-labels{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #tbl-ordenpago{
            width: match-parent;
        }
        #firstcolumn{
            width: 15%;
        }
        #secondcolumn{
            width: 35%;
        }
        #thirdcolumn{
            width: 14%;
        }
        #fourthcolumn{
            width: 35%;
        }
        #footer-op{
            display: flex;
            margin-top: 1.5vmax;
            justify-content: space-between;
        }
        #footer-solicito{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-reviso{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-reviso1{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-autorizo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        
        #footer-op label{
            font-weight: bold;
        }
        #tbl-ordenpago input{
            width: 100%;
            border: none;
        }
        #tbl-ordenpago label{
            font-weight: bold;
        }
        .labeltoright{
            float: right;
        }
        #header-empresa{
            font-weight: bold;
            font-size: 20px;
        }#header-rzonsocial{
            font-weight: bold;
            font-size: 25px;
        }
        
    `;
    
    return(
        <div id="div-ordenpago">
            <style>
                {styles}
            </style>

            <div id="header-op">
                
                <img src={props.logoOP}  alt="LOGO" style={{width: '160px', height: 'auto', paddingLeft:'25px'}}></img>
                <div id="header-labels">
                    <span id="header-rzonsocial"></span>
                    <span id="header-empresa">{props.departamento}</span>
                    <span>ORDEN DE PAGO</span>
                    <span id="header-categoria"></span>
                </div>
            </div>

            <table id="tbl-ordenpago">
            <tbody>
                <tr>
                    <td id="firstcolumn">
                        <label>FECHA</label>
                    </td>
                    <td id="secondcolumn" className="underlined"> 
                        <input id="op-fecha" disabled></input>
                    </td>
                    <td id="thirdcolumn">
                        <label className="labeltoright">N.</label>
                    </td>
                    <td id="fourthcolumn" className="underlined">
                        <input id="op-numero" disabled></input>
                    </td>
                    
                </tr>

                <tr>
                    <td>
                        <label>BANCO RETIRO</label>
                    </td>
                    <td colSpan="1" className="underlined">
                    <select id="op-bancoretiro" class="slc-tarjeta-op">
											{ props.bancos.map(item => ( 
											<option value={item.folio}> {item.nombrebanco}</option>    
											))}  
										</select>  
                    </td> 
                    <td>
                        <label className="labeltoright">CANTIDAD &nbsp; </label>
                    </td>
                    <td className="underlined">
                        <span><input type='text' id="op-cantidad" ></input></span>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2"></td>
                    <td>
                        <label className="labeltoright" id="lblcompra">COMPRA:&nbsp;#</label>
                    </td>
                    <td className="underlined" id="verOrdendeCompra">
                        <span>
                            <input id="op-folio-orden-compra" onClick={() => props.verOrden1(1)}></input>
                            <input id="op-folio-orden-requisicion" hidden></input>
                        </span>
                    </td>
                </tr> 
                <tr>
                    <td>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="op-cantidadletra" class="txtCenter" ></input>
                    </td>
                </tr> 
                <tr>
                    <td>
                        <label>PROVEEDOR:</label>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="op-anombre" onKeyUp={() => props.filtrarPorProveedor("op-anombre-sel", "op-anombre")} style={{width:'97.5%', height:'auto', cursor:'pointer'}}></input>
                        <select  id="op-anombre-sel" class="slc-b" onChange={() => props.proveedorSelected("op-anombre-sel", "op-anombre")}>
                                      
                        {props.proveedor.map(item => ( 
                                        ((item.proveedor != item.nombre) && (item.nombre != ""))
                                        ?
                                        <option  value={item.nombre}>{item.proveedor}</option>
                                        :
                                      <option  value={item.proveedor}>{item.proveedor}</option>
                                      ))
                                      }   
                                        </select>
                    </td>
                </tr>  
                <tr>
                    <td>
                        <label>CONCEPTO:</label>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="op-concepto"></input>
                    </td>
                </tr> 
                {(props.empresaid == "12") 
                ?
                <tr>
                <td>
                    <label>CLIENTE:</label>
                </td>
                <td className="underlined">
                    <span><select   id="op-cliente" class="slc-tarjeta-op">
                        <option>PETROMAR DEL PACIFICO SA DE CV</option>
                        <option>SERVICIO RIO PRESIDIO SA DE CV</option>
                        <option>MARIA CELIDA URIAS BELTRAN</option>
                        <option>SERVICIO DEL ROSARIO SA DE CV</option>
                        <option>ACCESORIOS Y REFACCIONES BEGCAL SA DE CV</option>
                        <option>SERVICIO EL RECREO SA DE CV</option>
                        <option>SERVICIO LERMA DEL PACIFICO SA DE CV</option>
                        <option>SERVICIO IQ DEL VALLE SA DE CV</option>
                        <option>GRUPO PRO-ENERGETICOS OIL COMPANIES SA DE CV</option>
                        <option>PRO-ENERGETICOS, COMBUSTIBLES Y SERVICIOS A LA INDUSTRIA SA DE CV</option>
                        <option>PETROLAND SA DE CV</option>
                        <option>SUPER AMIGO PETRO S DE RL DE CV</option>
                        <option>EXACTO COMBUSTIBLES, SA DE CV</option>
                        <option>PROMOTORA LIO, SA DE CV</option>
                        </select></span>
                </td>
                <td>
                    <label className="labeltoright">PEDIDO: &nbsp; </label>
                </td>
                <td className="underlined">
                    <span><input type='text' id="op-pedido" ></input></span>
                </td>
                 </tr>
                :
                <> 
                    {(props.empresaid == "7")
                    ?
                    <tr >
                <td>
                    <label>ESTACIÓN:</label>
                </td>
                <td className="underlined">
                    <span><select   id="op-cliente" class="slc-tarjeta-op" >
                        <option>SANTA FE</option>
                        <option>LEY DEL MAR</option>
                        <option>LIBRAMIENTO</option>
                        <option>MUNICH</option>
                        <option>INSURGENTES</option>
                        <option>LOPEZ SAENZ</option>
                        <option>RIO</option>
                        </select>
                        </span>
                </td>
                <td hidden>
                    <label className="labeltoright">PEDIDO: &nbsp; </label>
                </td >
                <td className="underlined" hidden>
                    <span><input type='text' id="op-pedido" ></input></span>
                </td>
                 </tr>
                    :
                    <tr hidden>
                <td>
                    <label>CLIENTE:</label>
                </td>
                <td className="underlined">
                    <span><select   id="op-cliente" >
                        <option>PETROMAR DEL PACIFICO SA DE CV</option>
                        <option>SERVICIO RIO PRESIDIO SA DE CV</option>
                        <option>MARIA CELIDA URIAS BELTRAN</option>
                        <option>SERVICIO DEL ROSARIO SA DE CV</option>
                        <option>ACCESORIOS Y REFACCIONES BEGCAL SA DE CV</option>
                        <option>SERVICIO EL RECREO SA DE CV</option>
                        <option>SERVICIO LERMA DEL PACIFICO SA DE CV</option>
                        <option>SERVICIO IQ DEL VALLE SA DE CV</option>
                        <option>GRUPO PRO-ENERGETICOS OIL COMPANIES SA DE CV</option>
                        <option>PRO-ENERGETICOS, COMBUSTIBLES Y SERVICIOS A LA INDUSTRIA SA DE CV</option>
                        <option>PETROLAND SA DE CV</option>
                        <option>SUPER AMIGO PETRO S DE RL DE CV</option>
                        <option>EXACTO COMBUSTIBLES, SA DE CV</option>
                        <option>PROMOTORA LIO, SA DE CV</option>
                        </select>
                        </span>
                </td>
                <td>
                    <label className="labeltoright">PEDIDO: &nbsp; </label>
                </td>
                <td className="underlined">
                    <span><input type='text' id="op-pedido" ></input></span>
                </td>
                 </tr>
                    }
                </>
                 }
               

                </tbody>
            </table>
            <br></br>                                            
            <div id="footer-op">
                <div id="footer-solicito"  class="firma-orden-pago">
                <span><img id='firmasolicita'  alt='Firma' width="250" height='180' ></img></span><br></br>
                                        <select id="firmas-solicito" class="firma-select">
                                        <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   
                 <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("100", "1")} id="bsolicita"   hidden>SOLICITO</button> 
                <label id="lblsolicito">SOLICITÓ</label>
                </div>
                <div id="footer-reviso" class="firma-orden-pago">
                <span><img id='firmarevisa'  alt='Firma' width="250" height='180' ></img></span><br></br>
                <select id="firmas-reviso" class="firma-select">
                    <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                     
                   <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("200", "1")} id="brevisa"   hidden>REVISO</button> 
                   <label id="lblreviso">REVISÓ</label>
                </div>
                {(props.empresaid == "12")?
                <div id="footer-reviso1" class="firma-orden-pago" hidden >
                <span><img id='firmarevisa1'  alt='Firma' width="250px" height='180px' ></img></span><br></br>
                <select id="firmas-reviso1" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   
                   <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("300", "3")} id="brevisa1"  hidden>REVISO</button> 
                   <label id="lblreviso1">REVISÓ</label>
                </div>
                :
                <div id="footer-reviso1" class="firma-orden-pago" >
                <span><img id='firmarevisa1'  alt='Firma' width="250px" height='180px' ></img></span><br></br>
                <select id="firmas-reviso1" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   
                   <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("300", "3")} id="brevisa1"  hidden>REVISO</button> 
                   <label id="lblreviso1">REVISÓ</label>
                </div>
                }
               
                <div id="footer-autorizo" class="firma-orden-pago">
                <span><img id='firmaautoriza'  alt='Firma' width="250" height='180' ></img></span><br></br>
                <select id="firmas-autorizo" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   
                   <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("400", "4")} id="bautoriza"   hidden>AUTORIZO</button> 
                   <label id="lblautorizo">AUTORIZÓ</label>
                </div>
            </div>
            {(props.empresaid == "20")
            ?   <div id="div-ausencia">
            <span>En ausencia</span>
            <div id="footer-op">
            
                <div id="footer-solicito"  class="firma-orden-pago1">
                                         <select id="firmas-solicito-au" class="firma-select">
                                        <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   
                 <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("1000", "1")} id="bsolicitaau"   hidden>SOLICITO</button> 
                <label>SOLICITÓ</label>
                </div>
                <div id="footer-reviso" class="firma-orden-pago1">
                  <select id="firmas-reviso-au" class="firma-select">
                    <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                      
                   <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("2000", "1")} id="brevisaau"   hidden>REVISO</button> 
                   <label>REVISÓ</label>
                </div>
                <div id="footer-reviso1" class="firma-orden-pago1" >
                 <select id="firmas-reviso1-au" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   
                   <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("3000", "3")} id="brevisa1au"  hidden>REVISO</button> 
                   <label>REVISÓ</label>
                </div> 
                <div id="footer-autorizo" class="firma-orden-pago1">
                <select id="firmas-autorizo-au" class="firma-select">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                   
                   <button className='btn btn-outline-success btn-sm btn-orden-pago-f' onClick={() => props.Autorizar("4000", "4")} id="bautorizaau"   hidden>AUTORIZO</button> 
                   <label>AUTORIZÓ</label>
                </div>
            </div>
                </div>
            :<></>
            }
        </div>
    )
}