import React from 'react';
//box-shadow: rgb(0 0 0 / 45%) 0px 5px 15px;
export  const Nabvar = (props) =>(
<div id={props.idd} className="m-2">
  <nav style={{borderRadius:'5px', border:'1px solid '+props.defaultColor,  position: 'relative', display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '.3rem',
  paddingBottom: '.3rem',
  boxShadow: 'rgb(0 0 0 / 45%) 0px 5px 15px',
  }}>
  <label style={{marginLeft:'15px', color:props.defaultColor, fontFamily: "'Roboto', Sans-Serif", fontSize:'16px', fontWeight:'bold'}}> {props.titulo}</label>
  <label style={{marginRight:'15px', color:props.defaultColor, fontFamily: "'Roboto', Sans-Serif", fontSize:'16px', fontWeight:'bold'}}>{props.departamento}</label>
  </nav>
</div>

)
 

    export const NavbarPass = (props) =>(
		<div className="m-2">
			<nav style={{borderRadius:'5px', border:'2px solid '+props.defaultColor,backgroundColor:'white', position: 'relative', display: 'flex', 
			flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', paddingTop: '.3rem', paddingBottom: '.3rem'}}>
				<label style={{marginLeft:'15px', color:props.defaultColor, fontFamily: "'Roboto', Sans-Serif", fontSize:'16px', fontWeight:'bold'}}>Cambiar contraseña</label>
			</nav>
		</div>
    )

    