const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: '15px',
        fontWeight: 'bold', 
        backgroundColor: '#e5e5e5',
        paddingLeft: '8px',
        paddingRight: '0px',         
      },
    },cells: {
        style: {
         paddingLeft: '8px', // override the cell padding for data cells
         paddingRight: '0px',
        },
    }
  }



  export default tableCustomStyles;