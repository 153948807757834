import React,{useState, useEffect} from 'react';   
export default function Ordenpago(props){
    
    const [tarjetas11, setTarjetas11] = useState([]);
    const [tarjetas22, setTarjetas22] = useState([]);
    useEffect(() =>{
        setTarjetas11(props.tarjetas);
        setTarjetas22(props.tarjetas);
    } 
    , [])
    function filtraTarjetas(folio){
        //console.log(folio); 
        setTarjetas11([]); 
		var result = tarjetas22.filter((x) => (x.foliobanco == folio)); 
		setTarjetas11(result);
	
    }
    const styles = `
        #div-ordenpago{
            display: flex;
            flex-direction: column;
            width: auto;
            padding: 2vw;
            background-color: white;
            border: 2px solid black;
            border-radius: 3px;
        }
        .underlined{
            border: none;
            border-bottom: 1px solid black;
        }
        #header-op{
            display: flex;
            margin-bottom: 1.5vmax;
        }
        #header-logo{
            width: 5vmax;
        }
        #header-labels{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #tbl-ordenpago{
            width: match-parent;
        }
        #firstcolumn{
            width: 15%;
        }
        #secondcolumn{
            width: 35%;
        }
        #thirdcolumn{
            width: 14%;
        }
        #fourthcolumn{
            width: 35%;
        }
        #footer-op{
            display: flex;
            margin-top: 1.5vmax;
            justify-content: space-between;
        }
        #footer-solicito{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-reviso{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-reviso1{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-autorizo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        #footer-op label{
            font-weight: bold;
        }
        #tbl-ordenpago input{
            width: 100%;
            border: none;
        }
        #tbl-ordenpago label{
            font-weight: bold;
        }
        .labeltoright{
            float: right;
        }
        #header-empresa{
            font-weight: bold;
            font-size: 20px;
        }#nueva-op-header-rzonsocial1{
            font-weight: bold;
            font-size: 20px;
        }#header-rzonsocial{
            font-weight: bold;
            font-size: 25px;
        }
    `;
    return(
        <div id="div-ordenpago1">
            <style>
                {styles}
            </style>
            <button class="btn btn-outline-success btn-sm" style={{display:'block',width:'150px', height:'30px'}} onClick={() => document.getElementById("cargaXML1").click()}>Cargar factura (XML)</button>
            <input style={{display:'none'}} type='file' id="cargaXML1" onChange={() => props.XML("2")} accept=".xml"></input>
            <div id="header-op">
                
                <img src={props.logoOP} alt="LOGO" style={{width: '160px', height: 'auto', paddingLeft:'25px'}}></img>
                <div id="header-labels">
                <span id="nueva-op-header-rzonsocial1">{props.rzonsocialEmpresa}</span>
                    {/* <span id="header-empresa">{props.departamento}</span>*/}
                    <span>ORDEN DE PAGO CON TDC EMPRESARIAL</span>
                </div>
                <div hidden id="ordenN" style={{border: '1px solid black',padding:'5px'}}>
                    <center><span id="header-"><b>ORDEN</b></span></center>
                    
                    
                    <tr>
                    <td id="thirdcolumn">
                        <label ><b>N.</b></label>
                    </td>
                    <td  className="underlined">
                        <input id="op-numeroN" disabled class="inp-border-n"></input>
                    </td>
                     </tr>
                     <tr>
                    <td id="firstcolumn">
                        <label><b>FECHA&nbsp;</b></label>
                    </td>
                    <td  className="underlined"> 
                        <input id="op-fechaN" disabled class="inp-border-n"></input>
                    </td>
                    
                </tr>
                        
                </div>
            </div>

            <table id="tbl-ordenpago">
            <tbody>
                <tr>
                    <td id="firstcolumn">
                        <label>BANCO:</label>
                    </td>
                    <td id="secondcolumn" className="underlined"> 
                    <select id="nop-bancoretiroN"  onChange={(e) => filtraTarjetas(e.target.value)} class="slc-tarjeta-op">
											{ props.bancos.map(item => ( 
											<option value={item.folio}> {item.nombrebanco}</option>    
											))}  
										</select>  
                       {/*  <input id="nop-bancoretiroN" ></input>*/}
                    </td>
                    <td hidden id="firstcolumn">
                        <label className="labeltoright">COMPRA &nbsp; #</label>
                    </td>
                    <td hidden id="secondcolumn" className="underlined">
                        <input id="op-folio-orden-compraN" disabled></input>
                    </td>
                   
                </tr>

                <tr>
                    
                    <td id="firstcolumn">
                        <label>TARJETA</label>
                    </td>
                    <td id="secondcolumn" className="underlined"> 
                    <select id="op-tarjeta"  class="slc-tarjeta-op">
											{ tarjetas11.map(item => ( 
											<option value={item.folio}> {item.notarjeta}</option>    
											))}  
										</select>  
                         {/* <input id="nop-tarjeta" ></input>*/}
                    </td>
                    <td id="firstcolumn">
                        <label className="labeltoright">CANTIDAD &nbsp; $</label>
                    </td>
                    <td  id="secondcolumn" className="underlined">
                        <input id="nop-cantidadN"  type='number'></input>
                    </td>
                </tr>
                 
                

                
               <tr hidden>
                    <td>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="op-importeletraN" textAlign="center" class="txtCenter"></input>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>PROVEEDOR:</label>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="nop-anombreN" onKeyUp={() => props.filtrarPorProveedor("nop-anombreN-sel", "nop-anombreN")} style={{width:'97.5%', height:'auto', cursor:'pointer'}}></input>
                        <select  id="nop-anombreN-sel" class="slc-b" onChange={() => props.proveedorSelected("nop-anombreN-sel", "nop-anombreN")}> 
                        {props.proveedor.map(item => ( 
                                        ((item.proveedor != item.nombre) && (item.nombre != ""))
                                        ?
                                        <option  value={item.nombre}>{item.proveedor}</option>
                                        :
                                      <option  value={item.proveedor}>{item.proveedor}</option>
                                      ))
                                      }    
                                  </select>
                    </td>
                </tr>

                <tr>
                    <td>
                        <label>CONCEPTO:</label>
                    </td>
                    <td colSpan="3" className="underlined">
                        <input id="nop-conceptoN"></input>
                    </td>
                    
                </tr>
                <tr>
                
                </tr>
                </tbody>
            </table>

            <div id="footer-op">
                <div id="footer-solicito">
                <select id="nfirmas-solicitoN" class="select-orden-pago">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                     <label>SOLICITÓ</label>
                </div>
                <div id="footer-reviso">
                <select id="nfirmas-revisoN" class="select-orden-pago">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                     <label>REVISÓ</label>
                </div>
                <div id="footer-reviso1">
                <select id="nfirmas-revisoN1" class="select-orden-pago">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                     <label>REVISÓ</label>
                </div>
                <div id="footer-autorizo">
                <select id="nfirmas-autorizoN" class="select-orden-pago">
                <option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option value={item.name}> {item.name}</option>    
											))}  
										</select> 
                      <label>AUTORIZÓ</label>
                </div>
            </div>
        </div>
    )
}