import React,{useState, useEffect, useRef} from 'react'; 
import  {FaCheckCircle, FaTrash, FaEdit, FaSignature} from 'react-icons/fa'
import axios from '../node_modules/axios';
import { toast } from 'react-toastify';
import { ModalCarga, Toast} from './component/modalCarga'; 
import 'react-toastify/dist/ReactToastify.css';
import CanvasDraw from "react-canvas-draw";
import Modal from 'react-modal';
import {Nabvar} from './component/Navbar'; 
import customStyles from './component/modalCustomStyles';

import './App.css'; 

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";  
import { ThreeDots } from  'react-loader-spinner'


function App(props) {


  
let subtitle;
const [modalIsOpen, setIsOpen] = React.useState(false);
const [modalIsOpenFirma, setIsOpenFirma] = React.useState(false);
const [modalIsOpenNuevaContrasena, setIsOpenNuevaContrasena] = React.useState(false);
const [modalIsOpenVerFirma, setIsOpenVerFirma] = React.useState(false);
const [modalIsOpenP, setIsOpenP] = React.useState(false);
const [modalIsOpenM, setIsOpenM] = React.useState(false);
const [modalIsOpenU, setIsOpenU] = React.useState(false);
const [modalIsOpenEliminarFirma, setIsOpenEliminarFirma] = React.useState(false);
const [firmaTemp, setFirmaTemp] = React.useState([]);
const [nuevaFirmaUserid, setNuevaFirmaUserId] = React.useState([]);
const [nuevaFirmaUserName, setNuevaFirmaUserName] = React.useState([]);
const canvasRef = useRef(null);
function notify(message){
  toast(message);
}
function openModal() {
setIsOpen(true);
}

function openModalp() {
  setIsOpenP(true);
  }

  function openModalM() {
    setIsOpenM(true);
    }
  
function openModalU() {
    setIsOpenU(true);
    }
    function openModalFirma(name, userid) {
      setNuevaFirmaUserId(userid);
      setNuevaFirmaUserName(name);
      setIsOpenFirma(true);
      }
      function openModalVerFirma(userid) {
        getFirmaTemp(userid);
        setIsOpenVerFirma(true);
        }





  
function afterOpenModal() {
// references are now sync'd and can be accessed.
subtitle.style.color = props.defaultColor;
} 
function closeModal() {
setIsOpen(false);
}
function closeModalNuevaContrasena() {
  setIsOpenNuevaContrasena(false);
  }

function closeModalP() {
  setIsOpenP(false);
}

function closeModalU() {
    setIsOpenU(false);
}

function closeModalFirma() {
  setIsOpenFirma(false);
}

function closeModalVerFirma() {
  setIsOpenVerFirma(false);
}


function openModala(idusuario, accionID){
  setIsOpenVerFirma(false);
  openModal();
  setIdUsuario(idusuario);
  setAccion(accionID);
  
}

function openModalu(idusuario){
  openModalU();
  setIdUsuario(idusuario);
  
}
function openModalP(idproveedor){
  openModalp();
  setIdProveedor(idproveedor);

}


const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);

    function openModalLoad() { 
    setIsOpenLoad(true); 
     }  
   
     function closeModalLoad() { 
    setIsOpenLoad(false); 
     }



     const [modalIsOpenLoadP, setIsOpenLoadP] = React.useState(false);

    function openModalLoadP() { 
    setIsOpenLoadP(true); 
     }  
   
     function closeModalLoadP() { 
    setIsOpenLoadP(false); 
     }


     const [modalIsOpenLoadF, setIsOpenLoadF] = React.useState(false);

    function openModalLoadF() { 
    setIsOpenLoadF(true); 
     }  
   
     function closeModalLoadF() { 
    setIsOpenLoadF(false); 
     }


    const [idusuario, setIdUsuario] =  useState([]); 
    const [accion, setAccion] =  useState([]); 
    const [idproveedor, setIdProveedor] =  useState([]); 
    const [listausuario, setListausuario] =  useState([]);    
    const [descripcion, setDescripcion] = useState([]); 
    const [imagen, setImagen] = useState([]); 
    const [dptos, setDeptos] = useState([]); 
    const [modulos, setModulos] = useState([]); 
    const [modalIsOpenAccesos, setIsOpenAccesos] = React.useState(false);
    const [usuarioSeleccionado, setUsuarioSeleccionado] = React.useState(false);
    const [foliousuarioSeleccionado, setFolioUsuarioSeleccionado] = React.useState(false);
    const [listaAccesos, setListaAccesos] = React.useState([]); 
    const [listaModulos, setListaModulos] = React.useState([]); 
	useEffect(()=> { 
      
     getDepartamentos()
     getModulos()
	}, [])
  function closeModalAccesos() { 
		setIsOpenAccesos(false); 
		 }

     function closeModalModulos() { 
      setIsOpenM(false); 
       }
 
    
	async function getDepartamentos(){
    	var id = "getDepartamentoss";
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
		setDeptos(rese.data);  
		//console.log(rese.data);
		var Data     = JSON.stringify(rese.data);
		//console.log(Data[0]); 
	}



  async function getModulos(){
    var id = "getModulos";
  const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id);  
  setModulos(rese.data);  
  //console.log(rese.data); 
}

 

  // Dynamically create select list
  let options = [];

	 
  async function getFirmaTemp(userid){
		var id = "getFirmaTemp";
    setFirmaTemp([]);
    openModalLoadP();
		const res = await axios.get(process.env.REACT_APP_API_URL+'/?id='+id+'&userid='+userid);
   
    closeModalLoadP();
		setFirmaTemp(res.data); 
	}

	 

 
 
  function b64toBlob(dataURI) {
    
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}
	async function addImagen(e) {
		e.preventDefault();

    let fd = new FormData() 
		fd.append("id", "addImagen")
    var subir = document.getElementById("subir2").checked;
    if(subir == true){
      //mostrar input 
      fd.append("imagen", imagen) 
    }else{
      //mostrar canvas  

      var base64 = canvasRef.current.canvasContainer.childNodes[1].toDataURL(); 
      var blob = b64toBlob(base64);
      fd.append("imagen", blob)
       
    }


    
   // setBase64Image(base64);
		var select = document.getElementById("idselect");
		var desc = document.getElementById("agg1");
		var input = document.getElementById("desc1"); 
    canvasRef.current.eraseAll(); 
    
    openModalLoad();
		
		//fd.append("imagen", imagen)
		//fd.append("imagen", blob)
		fd.append("descripcion", descripcion)
	//	fd.append("userid", select.options[select.selectedIndex].value)
		fd.append("userid", nuevaFirmaUserid)
		const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
    setIsOpenFirma(false);
    closeModalLoad();
    notify(res.data.msg); 
  
		props.getUsuarios();
		input.value = null;
		desc.value = null;
	}

	async function addUsuario(e) {
		e.preventDefault();
		let fd = new FormData() 
		var selectdpto = document.getElementById("selectdpto");
		var newusertipo = document.getElementById("newuser-tipo");
		fd.append("id", "addUsuario")
		fd.append("name", listausuario.trim())
		fd.append("dptoid", selectdpto.options[selectdpto.selectedIndex].value)
		fd.append("tipo", newusertipo.options[newusertipo.selectedIndex].value)
		fd.append("user", document.getElementById("newuser-user").value)
		fd.append("password", document.getElementById("newuser-password").value) 
		const res = await axios.post(process.env.REACT_APP_API_URL, fd);  
		
		//console.log(document.getElementById("newuser-tipo").value);
		//console.log(document.getElementById("newuser-user").value);
		//console.log(document.getElementById("newuser-password").value);
		notify(res.data.msg);
    props.getUsuarios(); 
      //CLEANING FORM
    document.getElementById("selectdpto").value = 1;
    document.getElementById("newuser-tipo").value = 1;
    var addUser_Inputs = document.getElementsByClassName("form-control");
    addUser_Inputs[0].value = null;
    addUser_Inputs[1].value = null;
    addUser_Inputs[2].value = null;
	}

	async function deleteImagen(id){
		if(window.confirm('¿Eliminar firma?')){
			let fd = new FormData() 
			fd.append("id", "deleteImagen")
			fd.append("idimagen", id) 
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			notify(res.data.msg);		
      //getFirmaTemp([]);
      props.getUsuarios();
		}
	}

	async function deleteUsuario(id){
		if(window.confirm('¿Eliminar este usuario?')){
			let fd = new FormData() 
			fd.append("id", "deleteUsuario")
			fd.append("idusuario", id)  
			const res = await axios.post(process.env.REACT_APP_API_URL, fd);
			props.getUsuarios();  
			notify(res.data.msg);		
		} 
	}

  async function editarContrasena(id){
    let nuevacontrasena = document.getElementById("newpassword").value;
    let nuevacontrasena1 = document.getElementById("newpassword1").value;
    if(nuevacontrasena != nuevacontrasena1){
      alert("Las contraseñas no coinciden");
      return;
    }else{
      if(window.confirm('¿Actualizar contraseña para este usuario?')){
        openModalLoad();
        let fd = new FormData() 
        fd.append("id", "editarContrasena")
        fd.append("idusuario", id)  
        fd.append("nuevacontrasena", nuevacontrasena)  
        const res = await axios.post(process.env.REACT_APP_API_URL, fd);
        setIsOpenNuevaContrasena(false); 
        closeModalLoad();
        notify(res.data);		
      } 
    }
		
	}


  
 


 

  async function CEU(a, accionR){ 
      let contra = document.getElementById("artnoc").value; 
      let fd1 = new FormData() 
      fd1.append("id","C1") 
      fd1.append("CT",contra) 
      fd1.append("userid",props.userid) 
			const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
    
      if(res1.data==1){
        if(accionR == "editUser"){
          editUsuario(a);
        }else if(accionR == "deleteSignature"){
          deleteImagen(a);
        }else if(accionR == "actualizarContrasena"){
          setIsOpenNuevaContrasena(true);
        }
        

        closeModal();
      }else{
        closeModal();
        return notify("CONTRASEÑA INCORRECTA"); 
      }
  } 

  async function CEUProveedor(a){
    
    
    let contra = document.getElementById("artnocproveedor").value; 
    let fd1 = new FormData() 
    fd1.append("id","C1") 
    fd1.append("CT",contra) 
    fd1.append("userid",props.userid) 
    const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
  
    if(res1.data==1){ 
      getListaAccesos(foliousuarioSeleccionado);
    setIsOpenAccesos(true); 
   //openModalAccesos();
     // editProveedor(a);
      closeModal();
    }else{
      closeModal();
      return notify("CONTRASEÑA INCORRECTA");
      
    }
}
  
  async function CEUusuario(a){ 
    let contra = document.getElementById("artnocusuario").value; 
    let fd1 = new FormData() 
    fd1.append("id","C1") 
    fd1.append("CT",contra) 
    fd1.append("userid",props.userid) 
    const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
  
    if(res1.data==1){ 
      deleteUsuario(a);
      closeModalU();
    }else{
      closeModalU();
      return notify("CONTRASEÑA INCORRECTA");
      
    }
} 

	async function editUsuario(id){

		if(window.confirm('¿Actualizar usuario?')){

        let fd = new FormData()  
			var departamento = document.getElementById("changeDpto"+id).value; 
			var tipousuario = document.getElementById("changeuser-tipo"+id).value; 
			var nombre = document.getElementById("changeName"+id).value; 
			var usuario = document.getElementById("changeUser"+id).value; 
			var accesoGlobal = document.getElementById("accesoGlobal"+id).checked;  
			var restablecerOrdenes = document.getElementById("restablecerOrdenes"+id).checked;  
			fd.append("id", "actualizarUsuario")
			fd.append("idusuario", id)  
			fd.append("dptoid", departamento)  
			fd.append("nombre", nombre)  
			fd.append("usuario", usuario)  
			fd.append("accesoGlobal", +accesoGlobal)   
			fd.append("tipousuario", tipousuario)   
			fd.append("restablecerOrdenes", +restablecerOrdenes)   
			const res = await axios.post(process.env.REACT_APP_API_URL, fd);
			props.getUsuarios();  
			notify(res.data.trim()); 
		} 
	}

  async function getListaAccesos(userid){
    var id = "getListaAccesos";
		openModalLoad();
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&userid='+userid);  
		closeModalLoad();
		
		setListaAccesos(rese.data);  
  }


  async function getListaModulos(userid){
    setListaModulos([]);
    var id = "getListaModulos";
		openModalLoad();
		const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&userid='+userid);  
		closeModalLoad();
		setListaModulos(rese.data);  
  }

  function openModalAccesos(usuario, userid) { 
  
    
    setUsuarioSeleccionado(usuario); 
    setFolioUsuarioSeleccionado(userid); 
    //getListaAccesos(userid);
   // setIsOpenAccesos(true); 
   openModalp();
  }  

  function openModalModulos(usuario, userid) { 
  
    
    setUsuarioSeleccionado(usuario); 
    setFolioUsuarioSeleccionado(userid); 
    //getListaAccesos(userid);
   // setIsOpenAccesos(true); 
   getListaModulos(userid);
   openModalM();
  }  


    async function eliminarAcceso(name, folio){
        
        
      if(window.confirm('¿Eliminar acceso a: ' + name+' para '+usuarioSeleccionado+'?')){ 
          let fd = new FormData() 
          fd.append("id", "eliminarAccesoUsuario")
          fd.append("folio", folio)  
          const res = await axios.post(process.env.REACT_APP_API_URL, fd);  
          notify((res.data).trim());
          getListaAccesos(foliousuarioSeleccionado);
          
      }
  }

  async function eliminarAccesoModulo(name, folio){
        
        
    if(window.confirm('¿Eliminar acceso a: ' + name+' para '+usuarioSeleccionado+'?')){ 
        let fd = new FormData() 
        fd.append("id", "eliminarAccesoUsuarioModulo")
        fd.append("folio", folio)  
        const res = await axios.post(process.env.REACT_APP_API_URL, fd);  
        notify((res.data).trim());
        getListaModulos(foliousuarioSeleccionado);
        
    }
}
  async function agregarAcceso(userid){
        
         let dptoidsel = document.getElementById("agregarAccesoSel").value;
       //  alert(userid + "  " + dptoidsel)
        let fd = new FormData() 
        fd.append("id", "agregarAcceso")
        fd.append("userid", userid)  
        fd.append("dptoid", dptoidsel)  
        const res = await axios.post(process.env.REACT_APP_API_URL, fd);  
        notify((res.data).trim());
        getListaAccesos(foliousuarioSeleccionado);
         
}


async function agregarAccesoModulo(userid){
  
  let modulodsel = document.getElementById("agregarAccesoModuloSel").value;
//  alert(userid + "  " + dptoidsel)
 let fd = new FormData() 
 fd.append("id", "agregarAccesoModulo")
 fd.append("userid", userid)  
 fd.append("folio", modulodsel)  
 const res = await axios.post(process.env.REACT_APP_API_URL, fd);  
 notify((res.data).trim());
 getListaModulos(foliousuarioSeleccionado);
  
}


 
  function Lim(e){
    e.preventDefault();
    canvasRef.current.eraseAll(); 
  }

  function radioChanged(){
    var subir = document.getElementById("subir2").checked;
    if(subir == true){
      //mostrar input
      //ocultar canvas
      var input = document.getElementById("inputsubir2");
      var canvas = document.getElementById("canvas2");
      input.removeAttribute("hidden");
      canvas.setAttribute("hidden", true);
    }else{
      //mostrar canvas 
      //ocultar input
      
      var input = document.getElementById("inputsubir2");
      var canvas = document.getElementById("canvas2");
      input.setAttribute("hidden", true);
      canvas.removeAttribute("hidden");
    }
    //alert(subir);
  }

  	return (
		<div className="container ">
			<Nabvar defaultColor={props.defaultColor} titulo="Usuarios" departamento={props.rzonsocial} dptoid={props.dptoid}/>    
				<div className="row p-3"> 
  					<div className="col-md-5 p-2 " >
    <form className="card p-2 mt-2  shadowBox" encType="multipart/form-data" >
      <h5>Agregar Nuevo Usuario</h5>    
      <label>Departamento:</label> 
      
      <select  id="selectdpto" >
        { dptos.map(item => ( 
          <option value={item.dptoid}> {item.name}</option>))
        }  
      </select>       
      <br></br>

      <label>Tipo: </label> 
      <select id="newuser-tipo">
        <option value='1'>Usuario</option>
        <option value='2'>Administrador</option> 
      </select>
      <br></br>

      <input placeholder="Nombre" className="form-control" onChange={(e) => setListausuario(e.target.value)} >
      </input> 
        <br></br> 

      <input id="newuser-user" placeholder="Usuario" className="form-control"></input>
      <br></br>

      <input id="newuser-password" placeholder="Contraseña" className="form-control" type="password"></input>
      <br></br>

     
      
      
      <button  className="btn btn-outline-success btn-sm" onClick={(e) => addUsuario(e)} >Agregar <FaCheckCircle /></button> 
    </form>
  </div>

       <div className="col-md-7 p-2" style={{maxHeight:'42vmax', overflowY: 'scroll'}}>
       { props.usuarios.map(item => (
            <div className="card p-2 mt-2  shadowBox" key={item.id} >
              <div className="card-body"  style={{padding: '0',display : 'flex', flexDirection : 'row', justifyContent: 'space-between'}}>

              <div>
            <b><label >Nombre:</label></b> <input id={"changeName"+item.userid} class="width-100" defaultValue={item.name} style={{color: props.defaultColor, border:'none', borderBottom: '1px solid black'}}></input> 
            <b><label >Usuario:</label></b> <input id={"changeUser"+item.userid} class="width-100" defaultValue={item.usuario} style={{ color: props.defaultColor, border:'none', borderBottom: '1px solid black'}}></input>
            <b><label > Departamento:</label> </b><label style={{color: props.defaultColor}}>
            </label>  
              {/*item.departamento*/}
              {/* ACTUALIZAR DEPARTAMENTOS */} 
              <select  id={"changeDpto"+item.userid} >
                <option value={item.dptoid}>{item.departamento}</option>
                { dptos.map(item => ( 
                  <option value={item.dptoid}>{item.name}</option>))
                }  
              </select>   
              <b><label > Tipo usuario: </label> </b><label style={{color: props.defaultColor}}></label>
              
              <select id={"changeuser-tipo"+item.userid}> 
              <option value={item.tipousuarioid}>{item.tipousuario}</option>
                <option value='1'>Usuario</option>
                <option value='2'>Administrador</option> 
              </select>
                
              <div style={{display:'flex'}}><b><label >Accesos: &nbsp;</label></b></div>                  
              <td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalAccesos(item.name, item.userid)}>Ver / agregar</button></td>
              <div style={{display:'flex'}}><b><label >Módulos: &nbsp;</label></b></div>                  
              <td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalModulos(item.name, item.userid)}>Ver / agregar</button></td>

              {(item.superus == "1") ?
              <><b><label > Acceso global:</label> </b><input id={"accesoGlobal"+item.userid}  defaultChecked={true} type="checkbox"   />  </>
              :
              <><b><label > Acceso global:</label> </b><input id={"accesoGlobal"+item.userid}  defaultChecked={false} type="checkbox"   /> </>
              
             }
             <br></br>
             {(item.restableceoc == "1") ?
              <><b><label > Restablecer ordenes:</label> </b><input id={"restablecerOrdenes"+item.userid}  defaultChecked={true} type="checkbox"   />  </>
              :
              <><b><label > Restablecer ordenes:</label> </b><input id={"restablecerOrdenes"+item.userid}  defaultChecked={false} type="checkbox"   /> </>
              
             }
             
             {(item.tieneFirma == "1") ?
              <td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalVerFirma(item.userid)}>Ver Firma <FaSignature  /></button></td>
              :
              <td><button  className='btn btn-outline-success btn-sm' onClick={() => openModalFirma(item.name, item.userid)}>Firma</button></td>
              
             }


              {(props.superus == "1") ?
              <td><button  className='btn btn-outline-success btn-sm' onClick={() => openModala(item.userid, "actualizarContrasena")}>Actualizar contraseña<FaSignature  /></button></td>
              :
              <td></td>
              
             }

             
            
            
        
               
                          
                
                  
                 
                  </div>  <div className="d-flex flex-row"  >
                       <button className="btn btn-outline-success btn-sm" 
                           onClick={() => openModala(item.userid, "editUser")} ><FaEdit />
                           </button>   
                   <label>&nbsp;&nbsp;</label>
                       <button  className="btn btn-outline-danger btn-sm " 
                           onClick={() => openModalu(item.userid)} ><FaTrash />
                           </button>  
                     </div>  
                      
                  </div> 
              </div>         
            ))}  
     </div>

</div>



      <div className="row p-3" hidden>
 
         <form className="card p-2 mt-2 border-secondary" encType="multipart/form-data" align="center">
           <h5>Cargar Firma</h5>
            
           <br></br> 
           <select id='idselect'>
           { props.usuarios.map(item => ( 
            <option value={item.userid}> {item.name}</option>    
            ))}  
            </select> 

      
           <br></br> 
           <textarea id="desc" cols="4" placeholder="Descripcion" className="form-control" 
           onChange={(e) => setDescripcion(e.target.value)} >
           </textarea>
           <br></br> 
           <div className="form-group">
           <input type="radio" id="subir" name="fav_language"  onChange={()=>radioChanged()}/>
            <label for="html">Subir imagen</label> <span>   </span>
            <input type="radio" id="capturar" name="fav_language"defaultChecked={true}   onChange={()=>radioChanged()}/>
            <label for="css">Capturar firma</label><br/>
                <br></br> 
              <div id="inputsubir" hidden>
                <input type="file"  id="agg" className="btn btn-outline-success btn-sm" accept="image/*"
                  onChange={(e) => setImagen(e.target.files[0])} /></div> 
                </div> 
             
                <div id="canvas" class="width-100" align="center">
           <button onClick={(e)=>Lim(e)}  className="btn btn-outline-success btn-sm" >Limpiar</button>
           <br></br> 
         
                 </div>
          <br></br> 
              <button  className="btn btn-outline-success btn-sm" 
                onClick={(e) => addImagen(e)} >Agregar <FaCheckCircle /></button> 
  </form>
         

     

     </div>
      

     

       <Modal
        isOpen={modalIsOpenP}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalP}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Ingrese Contraseña</label>
        <br></br>
        <br></br>
        <input class="width-100" id="artnocproveedor" type="password"></input>
        <br></br>
        <br></br>
  
<br></br>
		<button onClick={closeModalP} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button onClick={() => CEUProveedor(idproveedor)} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>

       
        
     

       <Modal 
			isOpen={modalIsOpenAccesos}  
			onRequestClose={closeModalAccesos}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Accesos</label><span>&nbsp; </span><br></br>
			<label  style={{color:'black', fontSize:'20px'}}>{usuarioSeleccionado}</label>
       		 <br></br>  
			 <table>
				<tr> 
					<th>Empresa</th> 
					<th>Eliminar</th>
				</tr>
				{ listaAccesos.map(item => ( 
				<tr> 
					<td style={{width:'350px'}}>{item.name}</td>  
          <td style={{width:'50px'}}><button  className='btn btn-outline-danger btn-sm' onClick={() => eliminarAcceso(item.name, item.folio)}>Eliminar</button></td>  
                  
				</tr>
				))}  
				 
				 
			 </table>
			 <label>Agregar:</label><br></br> 
			 <select id={"agregarAccesoSel"} style={{width:'450px',height:'30.8px'}}>
							 
				{ props.empresas.map(item => ( 
				<option value={item.dptoid}> {item.name}</option>    
				))}  
			</select> 	 	
			 <br></br>
			 <button className='btn btn-outline-success btn-sm' onClick={() => agregarAcceso(foliousuarioSeleccionado)} >Agregar</button> 
			</div>  
			</Modal>


      <Modal 
			isOpen={modalIsOpenM}  
			onRequestClose={closeModalModulos}   
			style={customStyles}> 
			<div class="width-100">  
			<label  class="label-1">Acceso a módulos</label><span>&nbsp; </span><br></br>
			<label  style={{color:'black', fontSize:'20px'}}>{usuarioSeleccionado}</label>
       		 <br></br>  
			 <table>
				<tr> 
					<th>Módulo</th> 
					<th>Eliminar</th>
				</tr>
				{ listaModulos.map(item => ( 
				<tr> 
					<td style={{width:'350px'}}>{item.nombre}</td>  
          <td style={{width:'50px'}}><button  className='btn btn-outline-danger btn-sm' onClick={() => eliminarAccesoModulo(item.nombre, item.folio)}>Eliminar</button></td>  
                  
				</tr>
				))}  
				 
				 
			 </table>
			 <label>Agregar:</label><br></br> 
			 <select id={"agregarAccesoModuloSel"} style={{width:'450px',height:'30.8px'}}>
							 
				{ modulos.map(item => ( 
				<option value={item.folio}> {item.nombre}</option>    
				))}  
			</select> 	 	
			 <br></br>
			 <button className='btn btn-outline-success btn-sm' onClick={() => agregarAccesoModulo(foliousuarioSeleccionado)} >Agregar</button> 
			</div>  
			</Modal>

       

        <ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    




        <Modal 
        isOpen={modalIsOpenLoadP}  
        onRequestClose={closeModalLoadP}   
        style={customStyles}> 
        <div class="width-100">  
        <ThreeDots color={props.defaultColor} height={80} width={80} /> 
        </div>  
        </Modal>
       



        <Modal 
        isOpen={modalIsOpenLoadF}  
        onRequestClose={closeModalLoadF}   
        style={customStyles}> 
        <div class="width-100">  
        <ThreeDots color={props.defaultColor} height={80} width={80} /> 
        </div>  
        </Modal>
        <Toast/>    

        <Modal 
        isOpen={modalIsOpenVerFirma}  
        onRequestClose={closeModalVerFirma}   
        style={customStyles}> 
        <div class="width-100" style={{ height:'565px'}} align="center">
           <div className="col-md-7 p-2" style={{ width: '673px', height: '407px'}} >
       { firmaTemp.map(item => (
            <div className="card p-2 mt-2 border-secondary" key={item.id} >
              <div className="card-body" >
              <h5   style={{color: props.defaultColor}}> {item.nombreusuario}</h5>  
              <img src={"data:image/png;base64,"+item.imagen} className="img-fluid" 
                alt="imagen"/>
            <h5   style={{color: props.defaultColor}}> {item.descripcion}</h5>  
            

                    <div className="d-flex flex-row-reverse" >
                       <button   className="btn btn-outline-danger btn-sm " 
                           onClick={() => openModala(item.id, "deleteSignature")} ><FaTrash />
                           </button> 
                      
                     </div>  
                      
                  </div> 
              </div>         
            ))}  
     </div>
     </div>
        </Modal>

        <Modal 
        isOpen={modalIsOpenFirma}  
        onRequestClose={closeModalFirma}   
        style={customStyles}> 
        <div style={{width:'686px'}}>  
        <div className="row p-3">
 
              <form className="card p-2 mt-2 border-secondary" encType="multipart/form-data" align="center">
                <h5>Cargar Firma</h5>
                <br></br> 
                <input id="input-firma" value={nuevaFirmaUserName}></input>
                 
                <br></br> 
                <textarea id="desc1" cols="4" placeholder="Descripcion" className="form-control" onChange={(e) => setDescripcion(e.target.value)} ></textarea>
                <br></br> 
                <div className="form-group">
                <input type="radio" id="subir2" name="fav_language"  onChange={()=>radioChanged()}/>
                  <label for="html">Subir imagen</label> <span>   </span>
                  <input type="radio" id="capturar" name="fav_language"defaultChecked={true}   onChange={()=>radioChanged()}/>
                  <label for="css">Capturar firma</label><br/>
                      <br></br> 
                    <div id="inputsubir2" hidden>
                      <input type="file"  id="agg1" className="btn btn-outline-success btn-sm" accept="image/*"
                        onChange={(e) => setImagen(e.target.files[0])} /></div> 
                      </div> 
                  
                <div id="canvas2" class="width-100" align="center">
                  <button onClick={(e)=>Lim(e)}  className="btn btn-outline-success btn-sm" >Limpiar</button>
                <br></br> 
                <CanvasDraw
                hideGrid
                brushColor='#1e80f5'
                lazyRadius={0}
                canvasWidth={668}
                canvasHeight={363}
                brushRadius={6}
                backgroundColor={"white"} 
                ref={canvasRef}
                        style={{
                          brushColor: "#1e80f5",
                          lazyRadius: 10,
                          alignSelf: 'center',  
                          boxShadow:
                            "0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)"
                        }}
                />
                </div>
                <br></br> 
                    <button  className="btn btn-outline-success btn-sm" 
                      onClick={(e) => addImagen(e)} >Agregar <FaCheckCircle /></button> 
              </form> 
            </div>            
          </div>  
        </Modal> 
        <Modal
          isOpen={modalIsOpenU}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModalU}
          style={customStyles}
          contentLabel="Example Modal">
          <h3>Eliminar usuario</h3>
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Ingrese Contraseña</label>
          <br></br>
          <br></br>
          <input class="width-100" id="artnocusuario" type="password"></input>
          <br></br>
          <br></br>
          <br></br>
		      <button onClick={closeModalU} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={() => CEUusuario(idusuario)} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>

       <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
         <h3>Editar usuario</h3>
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Ingrese Contraseña</label>
            <br></br>
            <br></br>
             <input class="width-100" id="artnoc" type="password"></input>
            <br></br>
            <br></br> 
            <br></br>
	      	<button onClick={closeModal} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button onClick={() => CEU(idusuario, accion)} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>


       <Modal
        isOpen={modalIsOpenNuevaContrasena} 
        onRequestClose={closeModalNuevaContrasena}
        style={customStyles}
        contentLabel="">
         <h3>Editar contraseña</h3>
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Ingrese nueva contraseña</label>
            <br></br>
            <br></br>
             <input class="width-100" id="newpassword"  type="password"></input>
            <br></br>
            <br></br>
            <input class="width-100" id="newpassword1"  type="password"></input>
            <br></br> 
            <br></br>
	      	<button onClick={closeModalNuevaContrasena} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button onClick={() => editarContrasena(idusuario)} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>


      </div>
 
  );   
}



 



export default App;
