import React,{useState, useEffect, useRef} from 'react'; 
import {FaSyncAlt, FaExternalLinkSquareAlt, FaFileExcel} from 'react-icons/fa'
import { useDownloadExcel } from 'react-export-table-to-excel';

import axios from '../node_modules/axios'; 
import {Nabvar} from './component/Navbar';  
import { Chart } from "react-google-charts";
import { ToastContainer, toast } from 'react-toastify';
import {ModalCarga, Toast} from './component/modalCarga'; 
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal'; 
import './App.css';  
import {formatNumber} from './commonFunctions';
import { formatDate} from './formatDate';
import customStyles from './component/modalCustomStyles';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";  
import {   ThreeDots } from  'react-loader-spinner'
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component';
import tableCustomStyles from './tableCustomStyles';
 
 
function Egresos(props){
  
    const [lista, setLista] =  useState([]);    
    const [lista1, setLista1] =  useState([]);    
    const [lista2, setLista2] =  useState([]);    

    
    
 
    useEffect(()=> {
        getOrdenes(); 
    }, [])
 
  
    const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);
    function notify(message){
        toast(message);
    }
    function openModalLoad() { 
    setIsOpenLoad(true); 
     }  
   
     function closeModalLoad() { 
    setIsOpenLoad(false); 
     }

 
  
    async function getOrdenes(){    
        var id = "getPagosRealizados";   
        setLista([]);
        var date = document.getElementById("input-fecha").value; 
        var date1 = document.getElementById("input-fecha1").value;  
        openModalLoad();
        const res = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&userid='+props.userid+'&date='+date+'&date1='+date1+'&dptoid='+props.dptoid);
        closeModalLoad();  
        
        if(res.data.length >= 1){
            setLista(res.data);  
           
        }  
       

    }

    async function getPagosBancos(){    
        var id = "getPagosBancos";   
        setLista1([]);
        var date = document.getElementById("input-fecha0").value; 
        var date1 = document.getElementById("input-fecha01").value;  
        openModalLoad();
        const res = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&userid='+props.userid+'&date='+date+'&date1='+date1+'&dptoid='+props.dptoid);
        closeModalLoad();  
        //console.log(res.data.categorias);
        if(res.data.categorias.length >= 1){
            setLista1(res.data.categorias);  
            
        }  
        if(res.data.data.length >= 1){
            setLista2(res.data.data);  
            
        }  
       

    }




    
     

/* 
Columnas tabla
*/
const columns = [
    {
        name: 'Banco',   
            width: '150px',
            sortable: true,  
            cell: (row) => {
                return (
                    <label>{row.banco}</label>  
                )
            }
         
        
    },
    {
        name: 'Fecha',   
        width: '100px',
        sortable: true,  
        cell: (row) => {
            return (
                <label>{row.fecha}</label>  
            )
        }
        
    },
    {
        name: 'Concepto',    
        sortable: true,  
        cell: (row) => {
            return (
                <label>{row.concepto}</label>  
            )
        }
        
         
    }, 
    {
        name: 'Importe',   
        width: '100px',
        sortable: true,  
        cell: (row) => {
            return (
                <label>{row.importe}</label>  
            )
        }
        
        
    },
    {
        name: 'Categoría',   
        width: '250px',
        sortable: true,  
        cell: (row) => {
            return (
                <label>{row.categoria}</label>  
            )
        }
       
    }
];


 

const tableRef = useRef(null); 
 
const { onDownload } = useDownloadExcel({ 
    currentTableRef: tableRef.current, 
    filename: 'Ordenes de compra', 
    sheet: 'Ordenes de compra' 
})

  

  
    return(
        <div className="container "> 
            <Nabvar  defaultColor={props.defaultColor} titulo="Reporte de compras" departamento={props.rzonsocial} dptoid={props.dptoid}/> 
           
            <div className="row p-3">
                <h3>Reporte de Egresos</h3>
            
                <div>
                    <span>Filtrar por fecha &nbsp;</span>
                    <input id='input-fecha0' type='date' class="input-filter"></input>
                    <span>&nbsp; &nbsp;</span>
                    <input id='input-fecha01' type='date' class="input-filter"></input>
                    <span>&nbsp; &nbsp;</span>
                    <button class='btn btn-outline-success btn-sm' onClick={() => getPagosBancos()} style={{borderRadius:'6px', border:'1px solid'}}>Filtrar <FaSyncAlt/></button>
                     
                     
                </div> 
              
            </div>
                    <div className="row p-3">
                
                
               
                    <button class="btn btn-outline-success btn-sm" style={{width:'100px', marginLeft: '12px'}}  onClick={onDownload}>Excel <FaFileExcel/></button>

                    <div class="div-section1"  ref={tableRef} >
                    <table id="productstable"  class="width-100" style={{backgroundColor: 'white'}}> 
                    <tr>
                        <th style={{backgroundColor:'#e5e5e5', height: '51px'}}>Categoría</th> 
                        <th style={{backgroundColor:'#e5e5e5'}}>BBVA</th> 
                        <th style={{backgroundColor:'#e5e5e5'}}>BANORTE</th> 
                        <th style={{backgroundColor:'#e5e5e5'}}>BANAMEX</th> 
                        <th style={{backgroundColor:'#e5e5e5'}}>BAJÍO</th> 
                        
                    </tr>

                    {lista1.map(item => (  
                    <tr class="font-14"  style={{backgroundColor: 'white'}}>
                        <td className='id-orden' >{item.categoria}</td>
                        {
                            lista2.map(it => (
                                (it.categoria == item.categoria && it.banco == '5') ? 
                                <td>{formatNumber(it.importe)}</td>
                                :< ></>
                            ))
                        }
                         {
                            lista2.map(it => (
                                (it.categoria == item.categoria && it.banco == '6') ? 
                                <td>{formatNumber(it.importe)}</td>
                                :<></>
                            ))
                        }
                         {
                            lista2.map(it => (
                                (it.categoria == item.categoria && it.banco == '7') ? 
                                <td>{formatNumber(it.importe)}</td>
                                :<></>
                            ))
                        }
                         {
                            lista2.map(it => (
                                (it.categoria == item.categoria && it.banco == '8') ? 
                                <td>{formatNumber(it.importe)}</td>
                                :<></>
                            ))
                        }
                         {
                            lista2.map(it => (
                                (it.categoria == item.categoria && it.banco == '9') ? 
                                <td>{formatNumber(it.importe)}</td>
                                :<></>
                            ))
                        }
                        
                      
                    </tr>
                    ))}	
                   
              
                </table> 
                                
                    </div>
 

            
            </div>


            <div className="row p-3">
                <h3>Pagos realizados</h3>
            
                <div>
                    <span>Filtrar por fecha &nbsp;</span>
                    <input id='input-fecha' type='date' class="input-filter"></input>
                    <span>&nbsp; &nbsp;</span>
                    <input id='input-fecha1' type='date' class="input-filter"></input>
                    <span>&nbsp; &nbsp;</span>
                    <button class='btn btn-outline-success btn-sm' onClick={() => getOrdenes()} style={{borderRadius:'6px', border:'1px solid'}}>Filtrar <FaSyncAlt/></button>
                     
                     
                </div> 
              
            </div>
                    <div className="row p-3">
                
                
               

                    <div class="div-section1">
                            <DataTableExtensions
                                columns={columns}
                                data={lista}
                                print={true}
                                export={true}
                                filterPlaceholder={"Filtrar"}
                                >
                        <DataTable
                                    columns={columns}
                                    data={lista}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight={'100%'}
                                    pagination
                                    customStyles={tableCustomStyles}
                                    highlightOnHover={true}
                                    noDataComponent={"No hay registros para mostrar"}
                                />
                        </DataTableExtensions>
                                
                    </div>
 

            
            </div>

                     
                    <ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    

                    <Toast/>    
        </div>
    );   
}

export default Egresos;
