 
    
   export const formatDate = (date) =>{
      let index = date.search(" ");
      date = date.substring(0, index);
      date = date.split("-");
      let formatedDate = date[2] +"/"+ date[1] +"/"+ date[0];
      return(formatedDate);
    }
 
  

export default formatDate;