import React from "react";
import { useState } from "react";
import axios from "axios";
import './App.css';
import {  toast } from 'react-toastify';
import {Toast} from './component/modalCarga'; 
import 'react-toastify/dist/ReactToastify.css';
import { NavbarPass } from "./component/Navbar";

function ChangePassForm(props){

    const[passActual, setPassActual] = useState();
    const[passNueva, setPassNueva] = useState();
    const[confirmarPass, setConfirmarPass] = useState();

    
    function notify(message){
        toast(message);
    }
    async function postChangePass(postbody){
        const rese = await axios.post(process.env.REACT_APP_API_URL, postbody);
        notify(rese.data.trim());
    }
    const handleSubmit = async e => {
        e.preventDefault();
 
        if(passNueva != confirmarPass){
            notify('La nueva contraseña no coincide');
            return;
        }

        let postbody = new FormData()
        postbody.append("id", "changePassword")
        postbody.append("passActual", passActual)
        postbody.append("passNueva", passNueva)
        postbody.append("idcte", props.userid);
        postChangePass(postbody);
    }

    return(
        <div className='container' align="center">
            <NavbarPass defaultColor={props.defaultColor}/>

            <div class="form-password" >
                <form onSubmit={handleSubmit} className="card p-2 mt-2 border-secondary" >
                    <label>Contraseña actual</label>
                    <input type='text' onChange={e => setPassActual(e.target.value)}></input><br></br>

                    <label>Nueva contraseña</label>
                    <input type='password' onChange={e => setPassNueva(e.target.value)}></input><br></br>

                    <label>Confirmar contraseña</label>
                    <input type='password' onChange={e => setConfirmarPass(e.target.value)}></input>

                    <button type='submit' id="form-btn" class="inpt-password" style={{backgroundColor:props.defaultColor}}>CONFIRMAR</button>
                </form>
            </div>
            <Toast/>    
        </div>
    );
}

export default ChangePassForm;