
import React,{useState, useEffect} from 'react'; 
import  {FaCheckCircle, FaTrash, FaTimesCircle, FaSyncAlt, FaExternalLinkSquareAlt, FaShare} from 'react-icons/fa'
import ProgressBar from 'react-bootstrap/ProgressBar';
import add from './resources/add.svg'; 
import axios from '../node_modules/axios'; 
import {Nabvar} from './component/Navbar'; 
import {ModalCarga, Toast} from './component/modalCarga'; 
import Modal from 'react-modal';
import html2canvas from 'html2canvas';
import {jsPDF} from "jspdf";
import { toast } from 'react-toastify';
import formatNumber from './commonFunctions';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';  
import NvaOrdenpago1 from './component/NvaOrdenpago1'; 
import NvaOrdenpago from './component/NvaOrdenpago';
import Nvasolicitudpago from './Nvasolicitudpago';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";   
import customStyles from './component/modalCustomStyles';
import DocViewer,  {DocViewerRenderers} from "react-doc-viewer";
import FileViewer from 'react-file-viewer';
import formatDate from './formatDate';
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component';
import tableCustomStyles from './tableCustomStyles';
/* Audio, BallTriangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin */
function SolicitudesOrdenPago(props) {
	
let subtitle;
	const [couno, setCouno] =  useState([]);  
	const [codos, setCodos] =  useState([]);  
	const [cotres, setCotres] =  useState([]);  

	const [lista, setLista] =  useState([]);   
	const [idcotizacion, setIdCotizacion] = useState([]); 
	const [proveedores, setLProveedores] = useState([]); 
	 
	const [logoOP, setLogoOP] = React.useState([]); 
	const [btnText, setBtnText] = useState("Nueva solicitud"); 
	const [modalIsOpenLoad, setIsOpenLoad] = React.useState(false);
	const [nuevaR, setNuevaR] = React.useState(false);
	const [modalIsOpenFile, setIsOpenFile] = React.useState(false);
	const [currentFile, setCurrentFile] = React.useState(false);
	const [ordenn, setorden] = React.useState(false);
	//estados
	const [generada, setGenerada] = React.useState([]);
	const [conorden, setConOrden] = React.useState([]);
	const [surtida, setSurtida] = React.useState([]);
	const [recepcionconfirmada, setRecepcionConfirmada] = React.useState([]);
	const [importeTotal, setImporteTotal] = React.useState([]); 
	const [proveedorSugerido, setProveedorSugerido] = React.useState([]);
	const [conceptoSugerido, setConceptoSugerido] = React.useState([]);
	const [importeAcumulado, setImporteAcumulado] = React.useState([]);
	//cancelada
	const [cancelada, setCancelada] = React.useState([]);
	let id = 0;  

	const[btn_Generar, setbtn_Generar] = useState();
 
	const [proveedor, setProveedor] =  useState(props.proveedores); 
	//const [listaProveedores, setListaProveedores] = useState([]); 
//	const [listaProveedores1, setListaProveedores1] = useState([]); 
const [modalIsOpenGenerar, setIsOpenGenerar] = React.useState(false);
const [modalIsOpenGenerar1, setIsOpenGenerar1] = React.useState(false);
const [folioempresa, setfolioEmpresa] = React.useState(false);
	useEffect(()=> { 
		if(props.cargarDefault != ""){
			verRequisicion(props.cargarDefault);
		} 
		getSolicitudes();
		getDatosEmpresa();
		 
   
		//console.log(props.datose);
		 
	}, [])
 
 


    async function getDatosEmpresa(){
        var id = "getDatosEmpresa";
        var rzonsocial = props.rzonsocial;

      /*  const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&rzonsocial='+rzonsocial);  
        //console.log(process.env.REACT_APP_API_URL+'?id='+id+'&rzonsocial='+rzonsocial);
        */var razonsocial = document.getElementById("rzonsocial");
        var direccion = document.getElementById("direccion");
        var facturacion = document.getElementById("facturacion");
        var facturacionL = document.getElementById("fac1");
        var telempresa = document.getElementById("telempresa");
        var rfcempresa = document.getElementById("rfcempresa");
        var logoOrden = document.getElementById("logoOrden"); 
        try{
			setLogoOP("data:image/png;base64,"+props.datose.logoblob);
            razonsocial.innerHTML = props.datose.name;
            direccion.innerHTML = props.datose.direccion;
            telempresa.innerHTML = props.datose.telefono;
            telempresa.innerHTML = props.datose.telefono;
            rfcempresa.innerHTML = props.datose.rfc;
          
            logoOrden.src = "data:image/png;base64,"+props.datose.logoblob; 
 
          
           facturacion.innerHTML = "Datos de facturación: " + props.datose.rfc + " " + props.datose.direccion + "\n" + document.getElementById("rzonsocial").innerHTML;
            facturacionL.innerHTML = "Datos de facturación: " + props.datose.rfc + " " + props.datose.direccion + "\n" + document.getElementById("rzonsocial").innerHTML;
        } catch(Exception ){console.log("error al obtener datos de empresa - getDatosEmpresa()")}
    }

	
    async function generarOrdenPago1(tipo){
        //, cantidad, proveedor, concepto tipo
        if(tipo == 0){
            return;
        }
        let Banco = "";
        let Cantidad = "";
        let Receptor = "";
        let Concepto = "";
        let Solicita = "";
        let Revisa = "";
        let Revisa1 = "";
        let Autoriza = "";
        let Tarjeta = "";
        if(tipo == "1"){ 
            Banco = document.getElementById("nop-bancoretiro").value ;
            Cantidad = document.getElementById("nop-cantidad").value;
            Receptor = document.getElementById("nop-anombre").value;
            Concepto = document.getElementById("nop-concepto").value;
            Solicita = document.getElementById("nfirmas-solicito").value;
            Revisa = document.getElementById("nfirmas-reviso").value;
            Revisa1 = document.getElementById("nfirmas-reviso1").value;
            Autoriza = document.getElementById("nfirmas-autorizo").value;
            Tarjeta = ""; 
        }else if(tipo == "2"){
            Tarjeta = document.getElementById("op-tarjeta").value;
            Banco = document.getElementById("nop-bancoretiroN").value;
            Cantidad = document.getElementById("nop-cantidadN").value;
            Receptor = document.getElementById("nop-anombreN").value;
            Concepto = document.getElementById("nop-conceptoN").value;
            Solicita = document.getElementById("nfirmas-solicitoN").value;
            Revisa = document.getElementById("nfirmas-revisoN").value;
            Revisa1 = document.getElementById("nfirmas-revisoN1").value;
            Autoriza = document.getElementById("nfirmas-autorizoN").value;
        }
        //generarOrdenPagoMultiple
        let fd = new FormData()  
		fd.append("id", "guardarOrdenPago")
		fd.append("banco", Banco);
        fd.append("cantidad", Cantidad);
        fd.append("receptor", Receptor);
        fd.append("concepto", Concepto);
        fd.append("solicita", Solicita);
        fd.append("tarjeta", Tarjeta);
        fd.append("tipo", tipo);
        fd.append("revisa", Revisa);
        fd.append("revisa1", Revisa1);
        fd.append("autoriza", Autoriza);
        fd.append("IDempresa", props.dptoid); 
        fd.append("solicitudpago", "1"); 
        //for each checked
        for(var i=0;i<document.getElementsByName("checkboxop").length;i++){   
			if(document.getElementsByName("checkboxop")[i].checked == true){ 
			   fd.append("ordenescompra[]", document.getElementsByName("checkboxop")[i].id) 
		   } 
	   }


		const rese = await axios.post(process.env.REACT_APP_API_URL, fd);
 
       // const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&idempresa='+props.dptoid+'&tipo='+tipo+'&cantidad='+cantidad+'&proveedor='+proveedor+'&concepto='+concepto);  
        closeModalGenerar();
        notify(rese.data.trim()); 
        setIsOpenGenerar1(false);
        //set checked false
        
        for(var i=0;i<document.getElementsByName("checkboxop").length;i++){   
			if(document.getElementsByName("checkboxop")[i].checked == true){ 
			    document.getElementsByName("checkboxop")[i].checked = false;
		   } 
	    }
        setImporteAcumulado([]);


        //document.getElementById("orden-estacion").value=""; //?
        getSolicitudes();
        
    }


	function nuevaRequisicionGuardada(){
	  getSolicitudes();
	  //get solicitudes
	 }

	 async function generarOrdenPago(folioempresa,ordenCompra,tipo){
      
        if(tipo == 0){
            return;
        }
         //, cantidad, proveedor, concepto tipo
         let Banco = "";
         let Cantidad = "";
         let Receptor = "";
         let Concepto = "";
         let Solicita = "";
         let Revisa = "";
         let Revisa1 = "";
         let Autoriza = "";
         let Tarjeta = "";
         if(tipo == "1"){ 
             Banco = document.getElementById("nop-bancoretiro").value ;
             Cantidad = document.getElementById("nop-cantidad").value;
             Receptor = document.getElementById("nop-anombre").value;
             Concepto = document.getElementById("nop-concepto").value;
             Solicita = document.getElementById("nfirmas-solicito").value;
             Revisa = document.getElementById("nfirmas-reviso").value;
             Revisa1 = document.getElementById("nfirmas-reviso1").value;
             Autoriza = document.getElementById("nfirmas-autorizo").value;
             Tarjeta = ""; 
         }else if(tipo == "2"){
             Tarjeta = document.getElementById("op-tarjeta").value;
             Banco = document.getElementById("nop-bancoretiroN").value;
             Cantidad = document.getElementById("nop-cantidadN").value;
             Receptor = document.getElementById("nop-anombreN").value;
             Concepto = document.getElementById("nop-conceptoN").value;
             Solicita = document.getElementById("nfirmas-solicitoN").value;
             Revisa = document.getElementById("nfirmas-revisoN").value;
             Revisa1 = document.getElementById("nfirmas-revisoN1").value;
             Autoriza = document.getElementById("nfirmas-autorizoN").value;
         }
         //generarOrdenPagoMultiple
         //console.log(Tarjeta);
         let fd = new FormData()  
         fd.append("id", "generarOrdenPagoUnica")
         fd.append("idordencompra", ordenCompra); //
         fd.append("idempresa", props.dptoid); //
         fd.append("folioempresa", folioempresa); //
         fd.append("tipo", tipo); //
         fd.append("cantidad", Cantidad); //
         fd.append("banco", Banco); 
         fd.append("receptor", Receptor);
         fd.append("concepto", Concepto);
         fd.append("solicita", Solicita);
         fd.append("tarjeta", Tarjeta); 
         fd.append("revisa", Revisa);
         fd.append("revisa1", Revisa1);
         fd.append("autoriza", Autoriza);
		 fd.append("solicitudpago", "1");
         
         const rese = await axios.post(process.env.REACT_APP_API_URL, fd);
  
        // const rese = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&idempresa='+props.dptoid+'&tipo='+tipo+'&cantidad='+cantidad+'&proveedor='+proveedor+'&concepto='+concepto);  
         closeModalGenerar();
         notify(rese.data.trim()); 
         setIsOpenGenerar(false);
         //document.getElementById("orden-estacion").value=""; //?
         getSolicitudes();
        
    }

	function closeModalGenerar() {
        setIsOpenGenerar(false);
    }
    function closeModalGenerar1() {
        setIsOpenGenerar1(false);
    }

	function openModalGenerar(orden, folioempresa, importe, proveedor, concepto) {
        setIsOpenGenerar(true); 
        setfolioEmpresa(folioempresa);
        setImporteTotal(importe);
        setProveedorSugerido(proveedor);
        setConceptoSugerido(concepto);
        setorden(orden);  
}

function getArray(cadena, foliof){  
	if(cadena != null){
	var stringArray = cadena.split(" ");   
	return stringArray.map(item => <><label >{item} <FaTrash class="color-red"  onClick={() => quitarOrdenPagoUnica(item)} /> <FaExternalLinkSquareAlt style={{color:props.defaultColor}} onClick={() => props.verOrdenPago(item, "")}/></label> </>);
	}
}

async function quitarOrdenPagoUnica(idordenpago){
       
        
	if(window.confirm('¿Quitar orden de pago: ' + idordenpago + '?')){
		//orden de pago multiple
		let fd = new FormData() 
		fd.append("id", "quitarOrdenPagoUnica") 
		fd.append("idordenpago", idordenpago)
		const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
		
		notify((res.data).trim());
		getSolicitudes();
		
	}
}
async function quitarOrdenPago(idordencompra, foliof, idordenpago){
       
        
	if(window.confirm('¿Quitar orden de pago: ' + idordenpago + ' para orden la solicitud de pago : ' + foliof + '?')){
		//orden de pago multiple
		let fd = new FormData() 
		fd.append("id", "quitarOrdenPago")
		fd.append("idorden", foliof)
		fd.append("idordenpago", idordenpago)
		fd.append("solicitudpago", "1")
		const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
		
		notify((res.data).trim());
		getSolicitudes();
		
	}
}

function selTipoOrden(){
	let sel = document.getElementById("slc-orden1");
	document.getElementById("nvaorden1").setAttribute('hidden','true');
	document.getElementById("nvaorden2").setAttribute('hidden','true');
	if(sel.value == "1"){
		document.getElementById("nvaorden1").removeAttribute('hidden'); 
		document.getElementById("nop-cantidad").defaultValue = importeAcumulado;
		document.getElementById("nop-anombre").defaultValue = proveedorSugerido;
		document.getElementById("nop-concepto").defaultValue = conceptoSugerido;
		
		
	}else if(sel.value == "2"){
		document.getElementById("nvaorden2").removeAttribute('hidden'); 
		document.getElementById("nop-cantidadN").defaultValue = importeAcumulado;
		document.getElementById("nop-anombreN").defaultValue = proveedorSugerido;
		document.getElementById("nop-conceptoN").defaultValue = conceptoSugerido;
	}
}

function selTipoOrden1(){
let sel = document.getElementById("slc-orden");
document.getElementById("nvaorden11").setAttribute('hidden','true');
document.getElementById("nvaorden22").setAttribute('hidden','true');
if(sel.value == "1"){
	document.getElementById("nvaorden11").removeAttribute('hidden'); 
	document.getElementById("nop-cantidad").defaultValue = importeTotal;
	document.getElementById("nop-anombre").defaultValue = proveedorSugerido;
	document.getElementById("nop-concepto").defaultValue = conceptoSugerido;
	
	
}else if(sel.value == "2"){
	document.getElementById("nvaorden22").removeAttribute('hidden'); 
	document.getElementById("nop-cantidadN").defaultValue = importeTotal;
	document.getElementById("nop-anombreN").defaultValue = proveedorSugerido;
	document.getElementById("nop-conceptoN").defaultValue = conceptoSugerido;
} 
}


function filtrarPorProveedor1(select, input){
	var selProveedores = document.getElementById(select);
	var currentInput = document.getElementById(input);
	var result = props.proveedores1.filter(item => item.proveedor.toLowerCase().includes(currentInput.value.toLowerCase()))
	//console.log(result);
	 setProveedor(result);
	 
	if(currentInput.value.toLowerCase() == "" || result.length < 1){
	 selProveedores.style.display = "none";
	}else{
	 selProveedores.style.display = "block";
	 selProveedores.selectedIndex = -1;
	 selProveedores.size = 5;
	 var my_index = 100;
	 selProveedores.style.zIndex = my_index++;
	}
 }
function proveedorSelected1(select, input){
	document.getElementById(input).value = document.getElementById(select).value; 
	document.getElementById(select).style.display = "none"; 
}

	 
 
	function filtrarPorProveedor(id){
		var selProveedores = document.getElementById("pro1"+id);
		var currentInput = document.getElementById("proveedor1"+id);
		var result = props.proveedores1.filter(item => item.proveedor.toLowerCase().includes(currentInput.value.toLowerCase()))
		//console.log(result);
		 setProveedor(result);
		if(currentInput.value.toLowerCase() == "" || result.length < 1){
		 selProveedores.style.display = "none";
		}else{
		 selProveedores.style.display = "block";
		 selProveedores.selectedIndex = -1;
		 selProveedores.size = 5;
		 var my_index = 100;
		 selProveedores.style.zIndex = my_index++;
		}
	 }
 
	 function proveedorSelected(id){
		 document.getElementById("proveedor1"+id).value = document.getElementById("pro1"+id).value; 
		 document.getElementById("pro1"+id).style.display = "none"; 
	 }

	function openModalLoad() { 
		setIsOpenLoad(true); 
		 }  
	   
		 function closeModalLoad() { 
		setIsOpenLoad(false); 
		 }

		  
		  function notify(message){
			toast(message);
		}
	
	async function addPersona(tipo){
		var tv = "0";
	if(tipo == "1000"){
		 tv = document.getElementById("slc-solicita").value
	}else if(tipo == "2000"){
		 tv = document.getElementById("slc-recibe").value
	} 
		if(window.confirm('¿Está seguro? agregar a: ' + tv )){
			var folio = document.getElementById("folio").value;
			let fd = new FormData() 
			fd.append("id","7") 
			fd.append("folio",folio) 
			fd.append("tipo", tipo) 
			fd.append("name", tv)
			const res = await axios.post(process.env.REACT_APP_API_URL, fd);
			notify(res.data.trim()); 
			if(tipo == "1"){
				document.getElementById("slc-solicita").disabled = true;
				document.getElementById("solicitar").style.display = "none";
				document.getElementById("solicitarC").style.display = "block";
			} else if(tipo == "11") {
				document.getElementById("slc-solicita-au").disabled = true;
				document.getElementById("solicitar-au").style.display = "none";
				document.getElementById("solicitarC-au").style.display = "block";
			}  
			
		}

	} 

	async function  actualizarDepartamento(dptoid, tipo){
		var idrequisicion = document.getElementById("folio").value;
		if(window.confirm('Actualizar departameto para requisición con folio: '+ idrequisicion)){
			let fd = new FormData() 
			fd.append("id", "actualizarDepartamento")
			fd.append("idrequisicion", idrequisicion)
			fd.append("dptoid", dptoid)
			fd.append("tipo", tipo)
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			
			notify(res.data.trim());
			verRequisicion(idrequisicion);
			getSolicitudes();
		} 
		
	}
	async function removerCotizacionFCotizaciones(idcotizacion){ 
		var idrequisicion = document.getElementById("folio").value;
        if(window.confirm('¿Quitar cotización?')){ 
            let fd = new FormData()  
            fd.append("id", "removerCotizacionFCotizaciones")
            fd.append("idcotizacion", idcotizacion) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            notify(res.data.trim());
            verRequisicion(idrequisicion);
        }
    }

	async function confirmarRecepcion(idrequisicion){  
        if(window.confirm('¿Confirmar recepción?')){ 
            let fd = new FormData()  
            fd.append("id", "confirmarRecepcion")
            fd.append("idrequisicion", idrequisicion) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            notify(res.data.trim());
           getSolicitudes();
        }
    }
 

	async function CancelarAutoriza(e){ 
	 
		let nombre="";
		var folio = document.getElementById("folio").value;
		if(e==1){
			nombre =document.getElementById("slc-solicita").value;
		}
		if(e==11){
			nombre =document.getElementById("slc-solicita-au").value;
		}
		if(e==2){
			nombre =document.getElementById("slc-revisa").value;
		}
		if(e==12){
			nombre =document.getElementById("slc-revisa-au").value;
		}
		if(e==3){
			nombre =document.getElementById("slc-realiza").value;
		}
		if(e==13){
			nombre =document.getElementById("slc-realiza-au").value;
		}
		if(e==4){
			nombre =document.getElementById("slc-autoriza").value;
		}
		if(e==14){
			nombre =document.getElementById("slc-autoriza-au").value;
		}
		let fd = new FormData() 
		fd.append("id","BorrarAutorizacion") 
		fd.append("folio",folio) 
		fd.append("tipo", e) 
		fd.append("name", nombre)
		//console.log(folio + document.getElementById("slc-autoriza").value);
		const res = await axios.post(process.env.REACT_APP_API_URL, fd);
		notify(res.data.trim());
		//verRequisicion(folio);
		if(e==1){
		document.getElementById("slc-solicita").disabled = false;
		document.getElementById("solicitar").style.display = "block";
		document.getElementById("solicitarC").style.display = "none";
		document.getElementById("slc-solicita").selectedIndex=0;
		}
		if(e==11){
		document.getElementById("slc-solicita-au").disabled = false;
		document.getElementById("solicitar-au").style.display = "block";
		document.getElementById("solicitarC-au").style.display = "none";
		document.getElementById("slc-solicita-au").selectedIndex=0;
		}
		if(e==2){
		document.getElementById("slc-revisa").disabled = false;
		document.getElementById("revisar").style.display = "block";
		document.getElementById("revisarC").style.display = "none";
		document.getElementById("slc-revisa").selectedIndex=0;
		}
		if(e==12){
		document.getElementById("slc-revisa-au").disabled = false;
		document.getElementById("revisar-au").style.display = "block";
		document.getElementById("revisarC-au").style.display = "none";
		document.getElementById("slc-revisa-au").selectedIndex=0;
		}
		if(e==3){
		document.getElementById("slc-realiza").disabled = false;
		document.getElementById("realizar").style.display = "block";
		document.getElementById("realizarC").style.display = "none";
		document.getElementById("slc-realiza").selectedIndex=0;
		}
		if(e==13){
		document.getElementById("slc-realiza-au").disabled = false;
		document.getElementById("realizar-au").style.display = "block";
		document.getElementById("realizarC-au").style.display = "none";
		document.getElementById("slc-realiza-au").selectedIndex=0;
		}
		if(e==4){
		document.getElementById("slc-autoriza").disabled = false;
		document.getElementById("autorizar").style.display = "block";
		document.getElementById("autorizarC").style.display = "none";
		document.getElementById("slc-autoriza").selectedIndex=0;
		}
		if(e==14){
		document.getElementById("slc-autoriza-au").disabled = false;
		document.getElementById("autorizar-au").style.display = "block";
		document.getElementById("autorizarC-au").style.display = "none";
		document.getElementById("slc-autoriza-au").selectedIndex=0;
		}
	}

	async function generarOrden(idrequisicion){  
		var folio = document.getElementById('folio').value; 
		
		if(folio == idrequisicion){
			if(folio != ""){
				var contador = 0; 
				let fd = new FormData() 
				fd.append("id", "GenerarOrden")
				fd.append("idrequisicion", idrequisicion)
				fd.append("dptoid", props.dptoid)
				fd.append("usuariogenero", props.userid)
				
				for(var i=0;i<document.getElementsByName("producto").length;i++){   
				 	if(document.getElementsByName("checkbox")[i].checked == true){ 
						fd.append("productos[]", document.getElementsByName("descripcion")[i].value)
						fd.append("seleccionado[]", "1") 
						contador ++;
					}else{
						fd.append("productos[]", document.getElementsByName("descripcion")[i].value)
						fd.append("seleccionado[]", "0")
					}
				}
				 
				if(contador >=1){
					openModalLoad();
					const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
					closeModalLoad();
					getSolicitudes();
					//cleanForm();
					notify(res.data.trim());
					verRequisicion(idrequisicion); 
				} else {
					notify("Seleccione Almenos Una Opción");
				}

			} else {
				notify("Seleccione una requisición");
			}
		} else {
			notify("Click en ver está requisición para generar orden de compra");
		}
	}

	async function ActualizarReq(){ 
		let folio = document.getElementById('folio').value;
		 
		let	descuentoSolicitud = document.getElementById('descuentoSolicitud').value;
	 
	 
		let identificador = document.getElementById('identificador').value;
		let proveedor = document.getElementById('proveedor').value;
 
		let solicita = document.getElementById('slc-solicita-solicitud').value;
		let recibe = document.getElementById('slc-recibe-solicitud').value;

		let cantidad = document.getElementsByName('cantidad');
		let descripcion = document.getElementsByName('descripcion');
		let precio = document.getElementsByName('preciouni');
		let idproducto = document.getElementsByName('idproducto');
		
		 
		let fd = new FormData()
        fd.append("id", "ActuaizarSolicitudPago")
        fd.append("folio", folio)
        fd.append("identificador", identificador)
        fd.append("proveedor", proveedor) 
        fd.append("solicita", solicita) 
        fd.append("recibe", recibe) 
        fd.append("descuentoSolicitud", descuentoSolicitud) 

        for(let i=0;i<document.getElementsByName("descripcion").length;i++){ 
			if(descripcion[i].value != ""){ 
			fd.append("cantidad[]", cantidad[i].value) 
            fd.append("descripcionproducto[]", (descripcion[i].value).replaceAll("'", "´").replaceAll('"', "´´"))   
			fd.append("precio[]", (precio[i].value).replaceAll("$", "").replaceAll(",", ""))  
			fd.append("idproducto[]", idproducto[i].value)   
			}
        }
 
	 
  
		const res = await axios.post(process.env.REACT_APP_API_URL, fd);
		//reestablecer precios de la orden de compra (?)
			//
			/*if(res.data.trim() == "Error al actualizar, orden de compra ya generada"){
				if(window.confirm(res.data.trim() + ' ¿continuar?')){
					openModal(); 
				}

			}else{*/
				notify(res.data.trim());
			//}
			
			
			
			if(res.data.trim() == "Error al actualizar, orden de compra ya generada"){

			}else{
				for(let i=0;i<document.getElementsByName("descripcion").length;i++){ 
					document.getElementsByName("descripcion")[i].value = ""; 
					document.getElementsByName("cantidad")[i].value = "";
					document.getElementsByName("preciouni")[i].value = "";
				}
	
 
				verRequisicion(folio);
				getSolicitudes();
			}
		
		

	}
	const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() { 
    setIsOpen(true); 
     }  
   

	function closeModal() {
		setIsOpen(false);
		}
		function closeModalFile() {
			setIsOpenFile(false);
			}


	async function CEU(){ 
		//alert("a");
		let contra = document.getElementById("artnoc").value; 
		let fd1 = new FormData() 
		fd1.append("id","C1") 
		fd1.append("CT",contra) 
		fd1.append("userid",props.userid) 
		const res1 = await axios.post(process.env.REACT_APP_API_URL, fd1);
		if(res1.data==1){
			//alert("autorizado");
		  ActualizarReq("1");
		  closeModal();
		}else{
		  closeModal();
		  return notify("CONTRASEÑA INCORRECTA"); 
		}
	} 

	 
	async function addCotizacion2(idcotizacion){
		setIdCotizacion(idcotizacion);
			document.getElementById("input-cotizacion").click(); 
	}
	 

	async function postFile2(){
		let fd = new FormData() 
		fd.append("id", "guardarCotizacion22")
		fd.append("idorden", id) 
		fd.append("idcotizacion", idcotizacion)
		fd.append("file", document.getElementById("input-cotizacion").files[0]) 
		
		const res = await axios.post(process.env.REACT_APP_API_URL,  fd, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}); 
		verRequisicion(document.getElementById("folio").value);
		notify(res.data.trim());
	}


	async function getSolicitudes(){    
		//tipo usuario si 1 solo las del dpto si 2 todas las requisiciones 
		setLista([]);
		//console.log("obtener solicitudes");
		var id = "getSolicitudesPago";
		var date = document.getElementById("input-fecha").value; 
		var termino = document.getElementById("input-fecha-termino").value;  
		openModalLoad();
		const res = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&date='+date+'&dptoid='+props.dptoid+'&tipo='+props.tipo+'&idu='+props.userid+'&termino='+termino+'&rzonsocial='+props.rzonsocial);
		closeModalLoad();
		 
		//var table = document.getElementById('productstable');
		
		if(res.data.length >= 1){
							 
			let res1;
			if(props.superus != 1){
				res1 = res.data.filter(item => 
				(item.dptoidelabora == null)
				|| (item.dptoid == props.userdptoid)
				|| (item.dptoidelabora == props.userdptoid)
				|| (item.encargadodptoorigen == "1")
				|| (item.realizadptodestino == "1") 
				);
			}else{
				res1 = res.data;
			}
			setLista(res1); 
		//	table.removeAttribute("hidden");
		} else {
			//table.setAttribute("hidden", true);
		}
	}

  	async function ActualizarStatus(id, serie){
			/*  GET ROW OF WHERE SELECT WAS CHANGED  */
		 
		let nuevoe = document.getElementById("selestado"+id).value;

		if(window.confirm('Quiere actualizar solicitud con folio: ' + serie)){
			let fd = new FormData() 
			fd.append("id", "updateSolicitudGenerado")
			fd.append("idsolicitud", id)
			fd.append("nvoestado", nuevoe)
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			
			notify(res.data.trim());
			getSolicitudes();
		}
	}


	async function ActualizarStatusAutoriza(id, serie){
		/*  GET ROW OF WHERE SELECT WAS CHANGED  */
	 
	let nuevoe = document.getElementById("selrecibe"+id).value;

	if(window.confirm('Quiere actualizar solicitud con folio: ' + serie)){
		let fd = new FormData() 
		fd.append("id", "updateSolicitudAutoriza")
		fd.append("idsolicitud", id)
		fd.append("autoriza", nuevoe)
		const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
		
		notify(res.data.trim());
		getSolicitudes();
	}
}


function formatRecibe(autoriza1){

	if(autoriza1 == "0"){
		return "Recibe";
	}else{
		return "Autoriza";
	}

}

	 
	function seeFile(url){
		setIsOpenFile(true);
		setCurrentFile(url);
	}

	async function verRequisicion(idd){   
		var firmasolicita = document.getElementById("firmasolicita");
                firmasolicita.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmasolicita.src;
				var firmaautoriza = document.getElementById("firmaautoriza");
                firmaautoriza.src = process.env.REACT_APP_API_URL_DEFAULT+'b.jpg';
                delete firmaautoriza.src;

		let total = 0;
		let req = document.getElementById("requisiciones");
		let nvareq = document.getElementById("nvarequisicion");
		if(nuevaR == true){
			setNuevaR(false);
			setBtnText("Nueva solicitud");
			 
			req.removeAttribute("hidden");
			nvareq.setAttribute("hidden", true);
		}else{
		 
		}


		var section = document.getElementById("requisiciones");
		setGenerada(0);
		setConOrden(0);
		setSurtida(0);
		setRecepcionConfirmada(0);
		setCancelada(0);
		

		try{
			document.getElementById("solicitarC").style.display = "none";
			document.getElementById("solicitarC-au").style.display = "none";
			document.getElementById("revisarC").style.display = "none";
			document.getElementById("revisarC-au").style.display = "none";
			document.getElementById("realizarC").style.display = "none";
			document.getElementById("realizarC-au").style.display = "none";
			document.getElementById("autorizarC").style.display = "none";
			document.getElementById("autorizarC-au").style.display = "none";
		} catch (Exception){}
		for(var i=0;i<document.getElementsByName("producto").length;i++){ 
			document.getElementsByName("descripcion")[i].value = ""; 
			document.getElementsByName("cantidad")[i].value = ""; 
			document.getElementsByName("idproducto")[i].value = "";
		}
 
		/*
		try{
			document.getElementById("solicitar").style.display = "block";
			document.getElementById("slc-solicita").disabled = false;
			document.getElementById("slc-solicita").selectedIndex=0;
			 
		} catch (Exception){}
		try{
			document.getElementById("solicitar-au").style.display = "block";
			document.getElementById("slc-solicita-au").disabled = false;
			document.getElementById("slc-solicita-au").selectedIndex=0;
		} catch (Exception){}
		*/ 
        var idsolicitud = idd; 
        var id = "GetDetallesSolicitud";
		openModalLoad();
        const res = await axios.get(process.env.REACT_APP_API_URL+'?id='+id+'&idsolicitud='+idsolicitud);
        closeModalLoad();

			//console.log(res.data);
			document.getElementById("fechacaptura").value = formatDate(res.data[0].fechacaptura);
			document.getElementById("folio").value = res.data[0].idsolicitud;
			document.getElementById("idrequisicion").value = res.data[0].serie;
			document.getElementById("identificador").value = res.data[0].descripcion;
			document.getElementById("proveedor").value = res.data[0].proveedor;
			document.getElementById("slc-solicita-solicitud").value = res.data[0].solicita;
			document.getElementById("slc-recibe-solicitud").value = res.data[0].recibe;

			if(res.data[0].autoriza == "1"){
				//label autoriza
				if(props.dptoid == 9){
					document.getElementById("lblautoriza").innerHTML = "REVISA";
				}else{
					document.getElementById("lblautoriza").innerHTML = "AUTORIZAA";
				}
				

			}else{
				//label revisa
				document.getElementById("lblautoriza").innerHTML = "RECIBE";

			}
			
/*
		
		document.getElementById("departamento").value = res.data[0].departamento;
		document.getElementById("dptoid").value = res.data[0].dptoid;
 
      
        document.getElementById("solicita").value = res.data[0].solicita;
        
        //document.getElementById("fecha").value = res.data[0].fecha;
        document.getElementById("observaciones").value = res.data[0].observaciones;
		
	 
		//console.log(res.data[1].length);

		if (props.tipo == "2") { 
			setbtn_Generar(idd);
		 
		}

	
		 */

		try{
			var tableHeaderRowCount = 2;
			var table = document.getElementById('requisicionprods');
			var rowCount = table.rows.length;

			for ( i = tableHeaderRowCount; i < rowCount; i++) {
				table.deleteRow(tableHeaderRowCount);
			}

			if(res.data[1].indexOf(1)){ 
				for( i=0;i<res.data[1].length-1;i++){
					var newrow = table.lastChild.cloneNode(true);
			//		newrow.firstChild.innerHTML = parseFloat(newrow.firstChild.innerHTML) + 1;
					table.appendChild(newrow);
				}

				for( i=0;i<res.data[1].length;i++){ 
					document.getElementsByName("descripcion")[i].value = res.data[1][i].descripcion;  
					document.getElementsByName("preciouni")[i].value = formatNumber(res.data[1][i].costouni);
					document.getElementsByName("idproducto")[i].value = res.data[1][i].idproducto;
					
					if(res.data[1][i].cantidad > 0){
						document.getElementsByName("cantidad")[i].value = res.data[1][i].cantidad;
					}
					total = total + parseFloat(res.data[1][i].costouni);
					
				}
			}  
			total = total - parseFloat(res.data[0].descuentoSolicitud);
			//alert(formatNumber(res.data[0].descuentoSolicitud));
			document.getElementById("descuentoSolicitud").value = res.data[0].descuentoSolicitud;
			document.getElementById("totalsolicitud").innerHTML = formatNumber(total);
		} catch (Exception){}	

		//autorizacion
		var unob = document.getElementById("bsolicita"); 
        var dosb = document.getElementById("bautoriza"); 
		var revocarb1 = document.getElementById("revocarb1");
        var revocarb2 = document.getElementById("revocarb2");
		
		unob.setAttribute("hidden", true);
		dosb.setAttribute("hidden", true);
		revocarb1.setAttribute("hidden", true);
		revocarb2.setAttribute("hidden", true);
		if(res.data[2]!= null){
		for( i=0;i<res.data[2].length;i++){ 
		
			if(res.data[2][i].tipo == "10000" ){
				   
				if((props.userid == res.data[2][i].userid && (res.data[2][i].autorizado == "0"))){
					unob.setAttribute("name", "10000"); 
					unob.removeAttribute("hidden");
				}else{ 
					if(res.data[2][i].autorizado == "1"  ){ 
						firmasolicita.src = "data:image/png;base64,"+res.data[2][i].imagen;  
							if(props.userid == res.data[2][i].userid){
								revocarb1.removeAttribute("hidden");
								revocarb1.setAttribute("name", "10000");
							}
						unob.setAttribute("hidden", true);
					} 
				}
				
			} 

			if(res.data[2][i].tipo == "20000" ){
				 
				if((props.userid == res.data[2][i].userid && (res.data[2][i].autorizado == "0"))){
					dosb.setAttribute("name", "20000"); 
					dosb.removeAttribute("hidden");
					
				}else{ 
					if(res.data[2][i].autorizado == "1"  ){ 
						firmaautoriza.src = "data:image/png;base64,"+res.data[2][i].imagen;  
							if(props.userid == res.data[2][i].userid){
								revocarb2.removeAttribute("hidden");
								revocarb2.setAttribute("name", "20000");
							}
						dosb.setAttribute("hidden", true);
					} 
				}
				
			} 
		}
	}
		
	

	   //CREATES EMPTY ROW (UNFORMATTED)
	   for( i=0; i<(16 - res.data[1].length-1); i++){ 
		var a = table.insertRow(-1);
		let  b = a.insertCell();
		
		let c = a.insertCell();
		let d = a.insertCell();
		let e = a.insertCell();
		let f = a.insertCell();
		
		b.innerHTML="&nbsp;"; 
		var input0 = document.createElement("input");
		input0.setAttribute('type', 'text');
		var input = document.createElement("input");
		input.setAttribute('type', 'text');
		var input1 = document.createElement("input");
		input1.setAttribute('type', 'text');
		var input2 = document.createElement("input");
		input2.setAttribute('type', 'text');
		input0.style.width = "101.31px";
		input.style.width = "100%";
		input1.style.width = "101.31px";
		input0.name = "cantidad";
		input.name = "descripcion";
		input1.name = "preciouni";
		input2.name = "idproducto";
		input2.style.display = "none";
		c.append(input0); 
		d.append(input); 
		e.append(input1);
		f.append(input2);
		

		//c.classList.add("width-10");

		e.classList.add("width-10");
		//a.style.border = "1px solid black"
		
		}

		section.scrollIntoView({ behavior: "smooth", block: "start" });
	}

	async function eliminarProducto(e){
		var folio = document.getElementById("folio").value;
		let idproducto = e.target.id;
		if(window.confirm('¿Eliminar producto?')){
			let fd = new FormData() 
			fd.append("id", "eliminarProductoRequisicion")
			fd.append("idproducto", idproducto)
			fd.append("idrequisicion", folio)
			const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			
			notify(res.data.trim());
			//getSolicitudes();
			verRequisicion(folio);
		}
	}

	 

	function desactivarRequisicion(idreq){
		if(window.confirm('Eliminar solicitud: ' + idreq)){
		let toSend = new FormData();
		toSend.append("id", "deleteSolicitud"); 
		toSend.append("idreq", idreq);

		fetch(process.env.REACT_APP_API_URL, {
			method: "POST",
			mode: "cors",
			body: toSend
		})
		.then(response => response.text())
		.catch(error => alert(error))
		.then((data)=> {
			if(data){
				notify("Requisicion eliminada");
				getSolicitudes();
			//	cleanForm();
			}
		})
	}
	}


	function replicarRequisicion(idreq){
		if(window.confirm('Replicar requisicion: ' + idreq)){
		let toSend = new FormData();
		toSend.append("id", "replicarRequisicion"); 
		toSend.append("folio", idreq);

		fetch(process.env.REACT_APP_API_URL, {
			method: "POST",
			mode: "cors",
			body: toSend
		})
		.then(response => response.text())
		.catch(error => alert(error))
		.then((data)=> {
			if(data){
				notify("Requisicion replicada");
				getSolicitudes();
				cleanForm();
			}
		})
	}
	}

	function seleccionarTodo(){
		var checkbox = document.getElementsByName('checkbox'); 
		let checkb = document.getElementById("todos");
		if(checkb.checked == true){

			for(var i = 0; i<checkbox.length; i++){
				checkbox[i].checked = true;
			}
		}else{

			for(var i = 0; i<checkbox.length; i++){
				checkbox[i].checked = false;
			}
		}

	}

	function Agregar(){  
		var table = document.getElementById('requisicionprods');
	 
		var newrow = table.lastChild.cloneNode(true);
		newrow.firstChild.innerHTML = parseFloat(newrow.firstChild.innerHTML) + 1; 

		for (var i = 0; i <  newrow.getElementsByTagName("input").length; i++){
			var a = newrow.getElementsByTagName("input")[i];  
			a.setAttribute("id", "check"+i);
			a.value = "";
		//	a.setAttribute("hidden", true);
		}
		
		
		table.appendChild(newrow);  
		for (var i = 0; i <  document.getElementsByName("preciouni").length; i++){
			var a = document.getElementsByName("preciouni")[i]; 
			a.setAttribute("id", "input"+i);
		}
		
		for (var i = 0; i <  document.getElementsByName("checkbox").length; i++){
			var a = document.getElementsByName("checkbox")[i];  
		 	a.setAttribute("id", "check"+i);
			
			if(i + 1 == document.getElementsByName("checkbox").length){
				a.checked = false;
			}
			
		}
		

	}

	function nuevaRequisicion(){
		 

		let req = document.getElementById("requisiciones");
		let nvareq = document.getElementById("nvarequisicion");
		if(nuevaR == true){
			setNuevaR(false);
			setBtnText("Nueva requisición");
		 
			req.removeAttribute("hidden");
			nvareq.setAttribute("hidden", true);
		}else{
			setNuevaR(true);
		    setBtnText("Cancelar");
			//btn btn-outline-success btn-sm
			//btn btn-outline-danger btn-sm
			
			nvareq.removeAttribute("hidden");
			req.setAttribute("hidden", true);
		}
	}


	  


	 

	function cleanForm(){
		let input1 = document.getElementById("folio");
		let input2 = document.getElementById("departamento");
		let input3 = document.getElementById("fechacaptura");
		let input4 = document.getElementById("solicita");
		let input5 = document.querySelectorAll("[name='producto']");
		let input6 = document.querySelectorAll("[name='descripcion']");
		let input7 = document.querySelectorAll("[name='unidad']");
		let input8 = document.querySelectorAll("[name='cantidad']");
		let input9 = document.querySelectorAll("[name='preciouni']");
		let input10 = document.querySelectorAll("[name='proveedor']");
		let input11 = document.querySelectorAll("[name='precio']");
		let input12 = document.getElementById("observaciones");

		input1.value = null;
		input2.value = null;
		input3.value = null;
		input4.value = null;
		
		for (let item of input5){
			item.value = null;
		}
		for (let item of input6){
			item.value = null;
		}
		for (let item of input7){
			item.value = null;
		}
		for (let item of input8){
			item.value = null;
		}
		for (let item of input9){
			item.value = null;
		}
		for (let item of input10){
			item.value = null;
		}
		for (let item of input11){
			item.value = null;
		}

		input12.value = null;
	}

	function Imprimir(){
		var unob = document.getElementById("btnimprimir"); 
		var unoa = document.getElementById("btnactualizar"); 
		//botones autorizar
		var btn1 = document.getElementById("bsolicita"); 
		var btn2 = document.getElementById("bautoriza"); 

		//botones revocar
		var btn11 = document.getElementById("revocarb1"); 
		var btn22 = document.getElementById("revocarb2"); 
		try{
			var folio = document.getElementById("folio").value; 
			if(folio != ""){
			unob.setAttribute("hidden", true);
			unoa.setAttribute("hidden", true);
			btn1.setAttribute("hidden", true);
			btn2.setAttribute("hidden", true);
			btn11.setAttribute("hidden", true);
			btn22.setAttribute("hidden", true);
		   
			var fecha = document.getElementById("fechacaptura").value;
			const input = document.getElementById('div-solicitud');
			html2canvas(input).then((canvas) => {
				let imgWidth = 190;
				let imgHeight = canvas.height * imgWidth / canvas.width;
				const imgData = canvas.toDataURL('img/png');
				const pdf = new jsPDF('p', 'mm', 'a4');
				pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
				
				// pdf.output('dataurlnewwindow');
				pdf.save("Solicitud de Pago F"+ folio +" "+ fecha +".pdf");
				 
			})
			;

			unob.removeAttribute("hidden");
			unoa.removeAttribute("hidden");
			btn1.removeAttribute("hidden");
			btn2.removeAttribute("hidden");
			btn11.removeAttribute("hidden");
			btn22.removeAttribute("hidden");

		}else{
			notify("Seleccione una orden de pago");
		}
		 

		} catch(Exception){}
	}

	function openModal21() {
        setIsOpenGenerar1(true);
      }


	  
	  async function Autorizar(tipo){
        
      
            openModalLoad();
             
            let fd = new FormData() 
            fd.append("id", "UpdateAutorizarSolicitud")
            fd.append("idsolicitud", document.getElementById("folio").value)
            fd.append("userid", props.userid)
            fd.append("tipo", tipo) 
            const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
            closeModalLoad();
            getSolicitudes();
            
            notify(res.data.trim());
            let foliof = document.getElementById("folio").value;
			verRequisicion(foliof);
           // verOrden(document.getElementById("idrequisicion").value,foliof); 
            
        
    }

	
    async function Revocar(e){
		// console.log(e.target.innerHTML);
		 //alert(e.target.name + " " + props.userid + " " + document.getElementById("idrequisicion").value);
 
 
			 openModalLoad();
			  
			 let fd = new FormData() 
			 fd.append("id", "revocarFirmaSolicitud")
			 fd.append("idsolicitud", document.getElementById("folio").value)
			 fd.append("userid", props.userid)
			 fd.append("tipo", e.target.name) 
			 const res = await axios.post(process.env.REACT_APP_API_URL, fd); 
			 closeModalLoad();
			 getSolicitudes();
			 
			 notify(res.data.trim());
			 let foliof = document.getElementById("folio").value;
			 verRequisicion(foliof);
			// verOrden(document.getElementById("idrequisicion").value,foliof); 
			  
	 }
 

	async function actualizarFecha(idsolicitud, serie){
		//alert(idsolicitud);
		var date = document.getElementById(idsolicitud).value; 
        if(window.confirm('Actualizar fecha de la orden de compra con folio: ' + serie + ' al día: ' + date)){  
         let fd = new FormData()  
         fd.append("id", "actualizarFechaSolicitudPago") 
         fd.append("idsolicitud", idsolicitud) 
         fd.append("fechaorden", date) 
         const res = await axios.post(process.env.REACT_APP_API_URL, fd);  
          
         notify(res.data.trim()); 
         getSolicitudes(); 
        }
	}
 
    function checked(){
        
        var checkBoxElements = document.getElementsByName("checkboxop");
        var proveedordef = document.getElementsByName("proveedordef");
        var conceptodef = document.getElementsByName("conceptodef");
        let proo = "";
        let conc = "";
        let totalAcumulado = 0;
        setImporteAcumulado(totalAcumulado);
        for(var i = 0; i<checkBoxElements.length; i++){ 
            if(checkBoxElements[i].checked == true){
                totalAcumulado += parseFloat(checkBoxElements[i].value);
                proo = proveedordef[i].innerHTML;
                conc = conceptodef[i].innerHTML;
            }
        }
        totalAcumulado = totalAcumulado.toFixed(2);
        setImporteAcumulado(totalAcumulado);
	//	alert(proo);
        setProveedorSugerido(proo);
        setConceptoSugerido(conc); 
    }
    
	/* 
Columnas tabla
*/
const columns = [
	{
        name: '',  
		width: '30px',
		cell: (row) =>{
			return(
				(row.ordenPagoMultiple == null) ?
				<td class="width-2"> <input type='checkbox' name="checkboxop" value={row.importeTotal} id={row.serie} onChange={() => checked()}></input> </td>
				:
				<td><input type='checkbox' name="checkboxop" value={row.importeTotal} id={row.serie} onChange={() => checked()} hidden></input></td>
				
			)
		} 
        
    },{
        name: 'Folio', 
        sortable: true, 
        width: "90px",
        selector: row => row.serie,
        
    }, 
    {
        name: 'Fecha', 
        width: "120px", 
        cell: (row) => {
            return (
                <input class="input-date" type="date" id={row.idsolicitud} onChange={() => actualizarFecha(row.idsolicitud, row.serie)} value={(row.fechacaptura).substring(0,10)}></input>
            )
        }
    },
    {
        name: 'Solicita',
        sortable: true, 
		width: '250px', 
        selector: row => row.name,
    },
    {
        name: 'Descripción', 
        width: "200px",
        sortable: true, 
        cell: (row) => {
            return (
                <><td name="conceptodef">{row.descripcion}</td>
				<td hidden name="proveedordef">{row.proveedor}</td>	
				</>
            )
        }
    },
    {
        name: 'Estado',   
        cell: (row) => {
            return (
				((row.estado == "Generada") && (props.tipo == "2")) ? 
				<select id={"selestado"+row.idsolicitud} name={row.estado} onChange={() => ActualizarStatus(row.idsolicitud, row.serie)}>
				<option>{row.estado}</option>
				<option>Cancelada</option>
				<option>Surtida</option>
				</select> :  <label>{row.estado}</label>
            )
        }
    },
    {
        name: 'Recibe',
        cell: (row) => {
            return (
				<select id={"selrecibe"+row.idsolicitud}  onChange={() => ActualizarStatusAutoriza(row.idsolicitud, row.serie)}>
				<option>{formatRecibe(row.autoriza)}</option>
				<option value="0">Recibe</option>
				<option value="1">Autoriza</option>
				</select>	
            )
        }
         
    },
    {
        name: 'Detalles',
        cell: (row) => {
            return (
                <button   type="button" className='btn btn-outline-success btn-sm bttn-requisicion' onClick={() => verRequisicion(row.idsolicitud)} >Ver</button>
            )
        } 
        
         
    },
    {
        name: 'Eliminar',
        cell: (row) => {
            return (
                <button  className='btn btn-outline-danger btn-sm' onClick={ () => desactivarRequisicion(row.idsolicitud) }>Eliminar</button>
            )
        }
    },
    {
        name: 'Orden de Pago',
		width: '180px',
        cell: (row) => {
            return (
                (row.ordenpago == 0)? 
                                <td align='left'> 
                                   <button value={row.ordencompra} className='btn btn-outline-success btn-sm' onClick={()=>openModalGenerar(row.idsolicitud, row.serie, row.importeTotal, row.proveedor, row.descripcion)}>Generar</button>
                                   {(row.ordenPagoMultiple != null) ?
                                        <label>{row.ordenPagoMultiple} <FaTrash class="color-red"  onClick={() => quitarOrdenPago(row.idsolicitud, row.serie, row.ordenPagoMultiple)} /> <FaExternalLinkSquareAlt  onClick={() => props.verOrdenPago(row.ordenPagoMultiple, "")} style={{color:props.defaultColor}}/></label>  
                                     :
                                        <></> 
                                    }
                                </td>   :
                                <td align='left' id={"celdaOP"+row.foliof}>
                                 <button value={row.ordencompra} className='btn btn-outline-success btn-sm' onClick={()=>openModalGenerar(row.idsolicitud, row.serie, row.importeTotal, row.proveedor, row.descripcion)}>Generar</button>
                                    {getArray(row.arrayID, row.foliof)} 
                                    {(row.ordenPagoMultiple != null) ?
                                        <label>{row.ordenPagoMultiple}<FaTrash class="color-red"  onClick={() => quitarOrdenPago(row.idsolicitud, row.serie, row.ordenPagoMultiple)} /> <FaExternalLinkSquareAlt  onClick={() => props.verOrdenPago(row.ordenPagoMultiple, "")} style={{color:props.defaultColor}}/></label>  
                                     :
                                        <></>
                                    }
                                 </td> 
            )
        }
    } 
];

 

	return (
		<div className="container ">
			<input id='input-cotizacion' type='file' class="input-hidden" onChange={()=> postFile2()}></input>

			<Nabvar defaultColor={props.defaultColor}  idd="navrequisiciones" titulo="Solicitudes de Pago" departamento={props.rzonsocial} dptoid={props.dptoid}/>    

			<div className="row p-3">
				<div>
					<span>Filtrar por fecha &nbsp; </span>
					<input id='input-fecha' type='date' class="filter-date"  onChange={() => getSolicitudes()}></input>
					<span>&nbsp; </span>
					<input id='input-fecha-termino' type='date' class="filter-date"  onChange={() => getSolicitudes()}></input>
					<span>&nbsp; &nbsp; </span>
					<button class="btn btn-outline-success btn-sm" onClick={() => getSolicitudes()}>Filtrar <FaSyncAlt/></button>
				</div> 
				

				  <br></br> 
				<div id='div-requisiones' class="lista-registros">
				<DataTableExtensions
          columns={columns}
          data={lista}
          print={true}
          export={false}
		  filterPlaceholder={"Filtrar"}
        >
          <DataTable
					columns={columns}
					data={lista}
					fixedHeader={true}
					fixedHeaderScrollHeight={'100%'}
					pagination
					customStyles={tableCustomStyles}
					highlightOnHover={true}
					noDataComponent={"No hay registros para mostrar"}
				/>
        </DataTableExtensions>

					<table id="productstable-requisiones" hidden class="width-100">
						<tr class="sticky-header">
							<th hidden></th>
							<th></th>
							<th>Folio</th> 
							<th>Fecha</th>
							<th>Solicita</th>
							<th>Descripción</th>
							<th>Estado</th>
							<th>Recibe</th>
							<th >Detalles</th>
							
							{(props.tipo == "2") ? <th hidden>Orden</th> : <th hidden>Orden</th> }
 
							<th>Eliminar</th>
							<th hidden>Replicar</th>
							<th>Orden de Pago</th>
						</tr>
						 
					 
						{ lista.map(item => ( 
						<>
							  {((item.dptoidelabora == null)
							  ||(item.dptoid == props.userdptoid) 
							  || (item.dptoidelabora == props.userdptoid)
							  || (item.encargadodptoorigen == "1")
							  //|| (item.realizadptoorigen == "1")
							  || (item.encargadodptoorigen == "1")
							  || (item.realizadptodestino == "1")
							  || (props.superus == "1")
							  ) ?  
							  <tr  class="font-14 table-row tabletr"   >
 
                        <td hidden><label class="add-bttn" >+</label></td>
						{(item.ordenPagoMultiple == null) ?
                            <td class="width-2"> <input type='checkbox' name="checkboxop" value={item.importeTotal} id={item.serie} onChange={() => checked()}></input> </td>
                            :
                            <td><input type='checkbox' name="checkboxop" value={item.importeTotal} id={item.serie} onChange={() => checked()} hidden></input></td>
                            }
						    <td className='id-orden'>{item.serie}</td> 
							<td><input class="input-date" type="date" id={item.idsolicitud} onChange={() => actualizarFecha(item.idsolicitud, item.serie)} value={(item.fechacaptura).substring(0,10)}></input></td>
							<td>{item.name} </td> 
						    <td name="conceptodef">{item.descripcion}</td>	   
						    <td hidden name="proveedordef">{item.proveedor}</td>	   
							<td>
								{((item.estado == "Generada") && (props.tipo == "2")) ? 
								<select id={"selestado"+item.idsolicitud} name={item.estado} onChange={() => ActualizarStatus(item.idsolicitud, item.serie)}>
								<option>{item.estado}</option>
								<option>Cancelada</option>
								<option>Surtida</option>
								</select> :  <label>{item.estado}</label>}
							</td>
							<td>
							<select id={"selrecibe"+item.idsolicitud}  onChange={() => ActualizarStatusAutoriza(item.idsolicitud, item.serie)}>
								<option>{formatRecibe(item.autoriza)}</option>
								<option value="0">Recibe</option>
								<option value="1">Autoriza</option>
								</select>	
							</td>
							<td ><button   type="button" className='btn btn-outline-success btn-sm bttn-requisicion' onClick={() => verRequisicion(item.idsolicitud)} >Ver</button></td>

							{ (item.ordencompra == null) ? 
							<td hidden>
								{(props.tipo != "2") ? <label></label> :
								<button  className='Bttns' hidden  type="button" onClick={() => generarOrden(item.idrequisicion)}>Generar</button>
								}
							</td> : <td onClick={() => props.verOrden(item.idrequisicion, item.ordencompra)}>Generada ({item.ordencompra}) <FaExternalLinkSquareAlt style={{color:props.defaultColor}}/></td>
							} 
							 
							<td>
								<button  className='btn btn-outline-danger btn-sm' onClick={ () => desactivarRequisicion(item.idsolicitud) }>Eliminar</button>
							</td>
							<td align="center"hidden>
								<button hidden className='btn btn-outline-success btn-sm' onClick={ () => replicarRequisicion(item.idrequisicion) }><FaShare /></button>
							</td>
							{(item.ordenpago == 0)? 
                                <td align='left'> 
                                   <button value={item.ordencompra} className='btn btn-outline-success btn-sm' onClick={()=>openModalGenerar(item.idsolicitud, item.serie, item.importeTotal, item.proveedor, item.descripcion)}>Generar</button>
                                   {(item.ordenPagoMultiple != null) ?
                                        <label>{item.ordenPagoMultiple} <FaTrash class="color-red"  onClick={() => quitarOrdenPago(item.idsolicitud, item.serie, item.ordenPagoMultiple)} /> <FaExternalLinkSquareAlt  onClick={() => props.verOrdenPago(item.ordenPagoMultiple, "")} style={{color:props.defaultColor}}/></label>  
                                     :
                                        <></> 
                                    }
                                </td>   :
                                <td align='left' id={"celdaOP"+item.foliof}>
                                 <button value={item.ordencompra} className='btn btn-outline-success btn-sm' onClick={()=>openModalGenerar(item.idsolicitud, item.serie, item.importeTotal, item.proveedor, item.descripcion)}>Generar</button>
                                    {getArray(item.arrayID, item.foliof)} 
                                    {(item.ordenPagoMultiple != null) ?
                                        <label>{item.ordenPagoMultiple}<FaTrash class="color-red"  onClick={() => quitarOrdenPago(item.idsolicitud, item.serie, item.ordenPagoMultiple)} /> <FaExternalLinkSquareAlt  onClick={() => props.verOrdenPago(item.ordenPagoMultiple, "")} style={{color:props.defaultColor}}/></label>  
                                     :
                                        <></>
                                    }
                                 </td> 
                                }
						</tr> 
                        : 
                        <></>
                         }
						 </>
							
						))}		 
					</table> 
					
				</div>
				{(importeAcumulado > 0) ? 
                    <button onClick={openModal21} value={importeAcumulado} className='btn btn-outline-success btn-sm' style={{width:'200px'}}>Generar orden ({formatNumber(importeAcumulado)})</button>
                :
                    <></>
                }
				

                <div class="margin-auto" > 
					
				<br></br>
				<br></br>
				<div clas="width-100"  align="left">
					<button class="btn btn-outline-success btn-sm" onClick={() => nuevaRequisicion()}>{btnText}</button>
				</div> 
				
					{/* Empieza requisición*/}

					
					{/* Termina requisición*/}

					<div id="requisiciones">
					<br></br> 
					<ProgressBar>
						<ProgressBar striped animated variant="success" label={'Generada'} now={generada} key={1} />
						<ProgressBar striped animated variant="success" label={'Orden de compra generada'} now={conorden} key={2} />
						<ProgressBar striped animated variant="success" label={'Surtida'} now={surtida} key={3} />
						<ProgressBar striped animated variant="success" label={'Recepción confirmada'} now={recepcionconfirmada} key={4} />
						<ProgressBar striped animated variant="danger" label={'Cancelada'} now={cancelada} key={5} />
    				</ProgressBar>

					<br></br> 
					<div id="div-solicitud" style={{backgroundColor:'white', border:'2px solid black', borderRadius:'5px', width:'70%', margin:'auto', padding:'12px'}}>
					{/*Empieza formato encabezado orden de compra */}
					<br></br>  
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
				 
                            <div class="div-section2">
                                <img id="logoOrden" src={"data:image/png;base64,"+props.datose.logoblob}  alt="LOGO" style={{width: '160px', height: 'auto', padding:'25px'}}></img>
                                <span><b class="font-25">SOLICITUD DE PAGO&nbsp;&nbsp;&nbsp;&nbsp;</b></span>
                            </div>
            
                            <div class="div-section2">
                                <span><b class="font-30" id="rzonsocial">{props.datose.name}</b></span>
                                <span><b id="direccion">{props.datose.direccion}</b></span> 
                                <span><b>Tel. </b><b id="telempresa">{props.datose.telefono}</b></span>
                            </div>

                            <div class="div-section2">
                                <table class="border-line" >
                                    <tr align="center">
                                        <th class="cell-1">Folio</th> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <input id="folio" readOnly="readonly" class="input-1" hidden></input>
                                            <input id="idrequisicion" readOnly="readonly" class="input-1"></input>
                                        </td> 
                                          
                                    
                                    </tr>
                                </table>
                                <br></br>
                                <table class="border-line">
                                    <tr align="center">
                                        <th class="cell-1">Fecha</th> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <input id="fechacaptura" readOnly='readonly' class="input-1"></input> 
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
						{/*
						Termina encabezado de diseño orden de compra
						*/}
						<table class="width-100"> 
							 

						 
							 
							<tr>
								<td>
									<label>Proveedor:</label>
									<br/>
									<input  class="inpt-nva-req" id="proveedor"/>
								</td>  
								
							</tr>  
							<tr>
								<td>
									<label style={{fontFamily:'Roboto, sans-serif'}}>Identificador:</label><br/>
									<input id="identificador"   class="inpt-nva-req" />
								</td>  
								
							</tr>  
						</table>	
						
						<br/>

						<table id='requisicionprods' class="width-100"> 
							<tr>
								<th></th>  
								<td class="cell-productos" hidden="hidden"><label>Producto</label></td>
								<td class="cell-productos"><label>Cantidad</label></td>
								<td class="cell-productos"><label>Descripción</label></td> 
								
								<td class="cell-productos"><label>Precio</label></td>
								<td hidden class="cell-productos"><input type="checkbox" onChange={() => seleccionarTodo()} id="todos"/></td>
							</tr>

							<tr class="width-100">
								<td  class="width-2"></td>
								<td hidden="hidden"><input name="producto"  style={{width:'162px',height:'auto'}}/></td> 
								<td class="width-10">  <input name="cantidad"  class="width-100" style={{height:'auto'}} /></td>
								<td  style={{width:'80%'}}> <input name="descripcion"  class="inpt-nva-req"/></td>
								 
								
								<td class="width-10"> <input name="preciouni"  class="inpt-nva-req" /></td>
								<td hidden class="width-2"> <input type='checkbox' name="checkbox"></input> </td>
								<td hidden class="width-2"><button name="btn-eliminar-prod" class="btn btn-outline-danger btn-sm "><FaTrash class="color-red" /></button></td>
								<td hidden="hidden" class="width-10"> <input name="idproducto"></input> </td>
								
							</tr> 
						</table>	
						<table>
							{/*finanzas y dalia */}
						    <tr>
								<td style={{width:'751px', fontSize:'20px'}} align="right">Descuento:</td>
								<td>
									{(props.userid == "161")?
										<input type="number" style={{width:'101px', marginLeft:'18px'}} id="descuentoSolicitud"></input>
									:
										<input type="number" style={{width:'101px', marginLeft:'18px'}} id="descuentoSolicitud" disabled></input>
									}
									
								</td>
							</tr>
							<tr>
								<td style={{width:'751px', fontSize:'20px'}} align="right">Total:</td>
								<td id="totalsolicitud" style={{width:'101px', fontSize:'20px'}}></td>
							</tr>
						</table>
						<div class="addButton" hidden>
							<img onClick={() => Agregar()} alt="Agregar" src={add} className='btn btn-outline btn-sm padding2 bggreen'  ></img>
					 		</div>  
						 
					 
						<div id="footer-op">
							<div id="footer-solicito">
							<span><img id='firmasolicita'  src="https://devapi.tech/apirest/default/b.jpg" alt='Firma' width="250" height='180' ></img></span><br></br>
							<select id="slc-solicita-solicitud" class="select-orden-pago" style={{width:'100%', textAlign:'center'}}>
							<option> Seleccione</option> 
														{ props.usuarios.map(item => ( 
														<option value={item.name}> {item.name}</option>    
														))}  
													</select> 
								<label>SOLICITA</label>
								<button   className='btn btn-outline-success btn-sm' onClick={() => Autorizar("10000")} id="bsolicita" class="orden-button btn btn-outline-success btn-sm" hidden>SOLICITO</button> 
								<button class="btn btn-outline-danger btn-sm" id="revocarb1" hidden  onClick={(e) => Revocar(e)}>Remover firma</button>
							</div>
							<div id="footer-autorizo">
							<span><img id='firmaautoriza' src="https://devapi.tech/apirest/default/b.jpg"  alt='Firma' width="250" height='180' ></img></span><br></br>
							<select id="slc-recibe-solicitud" class="select-orden-pago" style={{width:'100%', textAlign:'center'}}>
							<option> Seleccione</option> 
														{ props.usuarios.map(item => ( 
														<option value={item.name}> {item.name}</option>    
														))}   
													</select>  
								 
									<label id="lblautoriza">AUTORIZA</label> 
								 
								
								<button   className='btn btn-outline-success btn-sm' onClick={() => Autorizar("20000")} id="bautoriza" class="orden-button btn btn-outline-success btn-sm" hidden>AUTORIZO</button> 
								<button class="btn btn-outline-danger btn-sm" id="revocarb2" hidden  onClick={(e) => Revocar(e)}>Remover firma</button>
							</div>
           			 </div>

						{(props.tipo != "2") ? 
						<label></label>:
						
						<div  id="div-firmas">
							<table  class="width-100">

			    				<tr hidden>
										<th></th>
										<td>Solicita:</td> 
										<td>Autoriza:</td>
								</tr>

								<tr hidden>
										<td></td>
										<td> 
										<select id="slc-solicita" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 	
										<button id="solicitar" className="btn btn-outline-success btn-sm" onClick={(e) => addPersona("1000")} >Agregar <FaCheckCircle /></button> 
										<button id="solicitarC" class="btncancelar" onClick={(e) => CancelarAutoriza('1000')} style={{display:"none",color:"black", fontSize: '.875rem', height:"30px", borderColor:"red", borderRadius:"5px",borderWidth:"1px"}}>Cancelar <FaTimesCircle/></button> 
										</td>	
										 
										<td> 
										<select id="slc-recibe" class="select-req-1">
										<option> Seleccione</option> 
											{ props.usuarios.map(item => ( 
											<option> {item.name}</option>    
											))}  
										</select> 

										<button id="autorizar" className="btn btn-outline-success btn-sm" onClick={(e) => addPersona("2000")} >Agregar <FaCheckCircle /></button> 
										<button id="autorizarC" class="btncancelar" onClick={(e) => CancelarAutoriza('2000')} style={{display:"none",color:"black", fontSize: '.875rem', height:"30px", borderColor:"red", borderRadius:"5px",borderWidth:"1px"}}>Cancelar <FaTimesCircle /></button> 
									
										</td>	
										 
								</tr>
								<br></br>
								 
			

								  <br></br>
								<tr>
									<td></td>
									<td>
									<button id="btnactualizar" className={'btn btn-outline-success btn-sm'} onClick={(e) => ActualizarReq()}>Actualizar <FaCheckCircle /></button><br></br><br></br>
					 
										<button id="btnimprimir" className={'btn btn-outline-success btn-sm'} onClick={(e) => Imprimir()}>Imprimir <FaCheckCircle /></button>
											
									</td>
									<td>

					 
										
											
									</td>
								</tr>
							</table>

						</div>
						}
					</div>
					</div>
					
					<Nvasolicitudpago usuarios={props.usuarios} proveedores={props.proveedores} proveedores1={props.proveedores1} datose={props.datose} unidades={props.unidades} nuevaRequisicionGuardada={nuevaRequisicionGuardada}  dptos1={props.dptos1} defaultColor={props.defaultColor} departamentoid={props.departamentoid} tipo={props.tipo} departamento={props.departamento} dptoid={props.dptoid} userid={props.userid} usuario={props.usuario} name={props.name} rzonsocial={props.rzonsocial} />

					 


				</div>
			</div>
		
		<ModalCarga modalIsOpenLoad={modalIsOpenLoad} defaultColor={props.defaultColor} closeModalLoad={closeModalLoad}/>    
 		
        <Toast/>    

<Modal
        isOpen={modalIsOpen} 
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
         <h3>Editar requisición</h3>
          <label  class="label-1">Ingrese Contraseña</label>
        <br></br>
        <br></br>
        <input class="width-100" id="artnoc" type="password"></input>
        <br></br>
        <br></br>
  
<br></br>
		<button onClick={closeModal} class="btn btn-outline-danger btn-sm ">Cancelar</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <button onClick={() => CEU()} class="btn btn-outline-success btn-sm ">Aceptar</button>
       </Modal>

	   
       <Modal
        isOpen={modalIsOpenGenerar}
        onRequestClose={closeModalGenerar}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label   class="label-1">Seleccionar Opción</label>
        <br></br> 
        <label>Tipo de orden de pago:</label><br></br>
        <select style={{width: "300px"}} onChange={selTipoOrden1} id="slc-orden"> 
        <option value='0'>Seleccione</option>
        <option value='1'>ORDEN DE PAGO</option>
        <option value='2'>ORDEN DE PAGO CON TDC EMPRESARIAL</option>
        </select>
        <br></br>
        <label hidden>Importe:</label> 
        <input hidden style={{width: "300px"}}  defaultValue={importeTotal} id="importeTotalNvaOC"></input><br></br>
       
        <div id='nvaorden11' hidden>
			<NvaOrdenpago proveedor={proveedor} proveedorSelected={proveedorSelected1} filtrarPorProveedor={filtrarPorProveedor1} rzonsocialEmpresa={props.rzonsocial} bancos={props.bancos} tarjetas={props.tarjetas} departamento={props.departamento}  usuarios={props.usuarios} logoOP={logoOP}/>            
        </div>
                    
        <div id='nvaorden22' hidden>
                    <NvaOrdenpago1 proveedor={proveedor} proveedorSelected={proveedorSelected1} filtrarPorProveedor={filtrarPorProveedor1} rzonsocialEmpresa={props.rzonsocial} bancos={props.bancos} tarjetas={props.tarjetas} departamento={props.departamento}  usuarios={props.usuarios} logoOP={logoOP}/>
                   
        </div>
        <br></br>
         
        <button id="btnS" style={{display:"block"}}onClick={() => generarOrdenPago(folioempresa, ordenn,document.getElementById("slc-orden").value)} class="btn btn-outline-success btn-sm ">Aceptar</button>
            
    
       </Modal>

	   
       <Modal
        isOpen={modalIsOpenGenerar1} 
        onRequestClose={closeModalGenerar1}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
          <label ref={(_subtitle) => (subtitle = _subtitle)} class="label-1">Seleccionar Opción</label>
        <br></br> 
        <label>Tipo de orden de pago:</label><br></br>
        <select style={{width: "300px"}} id="slc-orden1" onChange={selTipoOrden}> 
        <option value='0'>Seleccione</option>
        <option value='1'>ORDEN DE PAGO</option>
        <option value='2'>ORDEN DE PAGO CON TDC EMPRESARIAL</option>
        </select>
        <br></br>
         
        <div id='nvaorden1' hidden>
                    <NvaOrdenpago proveedor={proveedor} proveedorSelected={proveedorSelected1} filtrarPorProveedor={filtrarPorProveedor1} rzonsocialEmpresa={props.rzonsocial} bancos={props.bancos} tarjetas={props.tarjetas} departamento={props.departamento}  usuarios={props.usuarios} logoOP={logoOP}/>            
                   
        </div>
                    
        <div id='nvaorden2' hidden>
                    <NvaOrdenpago1 proveedor={proveedor} proveedorSelected={proveedorSelected1} filtrarPorProveedor={filtrarPorProveedor1} rzonsocialEmpresa={props.rzonsocial} bancos={props.bancos} tarjetas={props.tarjetas} departamento={props.departamento}  usuarios={props.usuarios} logoOP={logoOP}/>
                   
        </div>
        <br></br>
         
           
        <button id="btnS" style={{display:"block"}}onClick={() => generarOrdenPago1(document.getElementById("slc-orden1").value)} class="btn btn-outline-success btn-sm ">Aceptar</button>
            
    
       </Modal>


	   
<Modal
        isOpen={modalIsOpenFile} 
        onRequestClose={closeModalFile}
        style={customStyles} 
       
      >
         <FileViewer
        fileType={"pdf"}
        filePath={currentFile}  />
		   </Modal>
		  
		</div>

		
	);   
}

export default SolicitudesOrdenPago;
